import { DOCUMENT } from '@angular/common';
import { ɵangular_packages_common_http_http_e } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';
export class Contact{
  public name: string;
  public lname:string;
  public number:number;
  public email: string;
  public company:string;
  public delivery:string;
  public euro:string;
  public destination:string;
  public recalled:string;
}
@Component({
  selector: 'app-tarifs',
  templateUrl: './tarifs.component.html',
  styleUrls: ['./tarifs.component.scss']
})
export class TarifsComponent implements OnInit {
  model = new Contact();
  
  onSubmit(form){
    console.log(form.value);
  }

  title = 'Voyey | Tarifs achat détaxé, réexpédition et livraison DOM';
  jsonLD: SafeHtml;

  schema = {
    "url": "https://voyey.com/tarifs/",
    "name": "Voyey | Tarifs achat détaxé, réexpédition et livraison DOM",
    "description": "Achats détaxés, réexpédition, livraison – Voyey vous propose des tarifs imbattables et rend l’e-commerce enfin accessible à tous les ultramarins !"
  };
  
  constructor(
    private titleService: Title, private el: ElementRef, private metaService: Meta, @Inject(DOCUMENT) private dom
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([      
      { name: "description", content: "Achats détaxés, réexpédition, livraison – Voyey vous propose des tarifs imbattables et rend l’e-commerce enfin accessible à tous les ultramarins !" },
      { name: "og:url", content: "https://voyey.com/tarifs/" },
      { name: "og:title", content: "Voyey | Tarifs achat détaxé, réexpédition et livraison DOM" },
      { name: "og:description", content: "Achats détaxés, réexpédition, livraison – Voyey vous propose des tarifs imbattables et rend l’e-commerce enfin accessible à tous les ultramarins !" },
      { name: "twitter:title", content: "Voyey | Tarifs achat détaxé, réexpédition et livraison DOM" },
      { name: "twitter:description", content: "Achats détaxés, réexpédition, livraison – Voyey vous propose des tarifs imbattables et rend l’e-commerce enfin accessible à tous les ultramarins !" }
    ]);
    this.setCanonicalURL('https://voyey.com/tarifs/');
  }

  tariftr1tdtwo = {"content":["5 %"]};
  // stringFormatted() {
  //   return this.tariftr1tdtwo.content[0].replace(/5 %/g, "<span class='orange'>5 %</span>");
  // }
  
  tariftr2tdtwo = {"content":["10€ par kilo"]}  
  // stringFormatted1() {
  //   var str = this.tariftr2tdtwo.content[0].replace(/5 %/g, "<span class='orange'>5 %</span>");
  //   str  = str.replace(/20 €/g, "<span class='orange'>20 €</span>");
  //   return str;
  // }
  
  tariftr1tdthree = {"content":["1 %"]};
  // stringFormatted2() {
  //   return this.tariftr1tdthree.content[0].replace(/1 %/g, "<span class='blue f-big'>1 %</span>");
  // }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

  //tarif content
  titlename1 = {"title":["Sans abonnement"],"link":"JE COMMANDE","url":"https://app.voyey.com/"}
  titlename2 = {"title":["9,90 € par mois"],"link":"JE M’ABONNE","url":"https://app.voyey.com/akaz-subscription?plan=monthly"}

  tarifone = {"title":"Achat détaxé","content":["Voyey fait vos achats en tant qu’entreprise et vous fait profiter de son exonération de TVA. ","Vous ne payez que les taxes d’outre-mer."]}
  
  tariftr1tdone = {"title":"Frais de service","content":["Sur le montant total HT du ou des produit(s) acheté(s)"]}
 
  tariftr2tdone = {"title":"Expédition","content":["Nous pouvons faire les achats partout en France."]}
  
  tariftr2tdthree = {"content":["5€ par kilo"]}
  stringFormattedtwo() {
    return this.tariftr1tdthree.content[0].replace(/1 %/g, "<span class='blue f-big'>1 %</span>");
  }

  tariftwo = {"title":"Options","content":["Voyey réceptionne vos produits achetés en métropole et les réexpédie outre-mer.","Service gratuit (et sans frais cachés !)."]}

  tariftr3tdone = {"title":"Délais"}
  // tariftr3tdtwo = {"content":["Gratuit"]}
  tariftr3tdtwo = {"content":["Maximum 10 jours"]};
  // stringFormatted16() {
  //   return this.tariftr3tdtwo.content[0].replace(/Gratuit/g, "<span class='orange f-big'>Gratuit</span>");
  // }
  // tariftr3tdthree = {"content":["Gratuit"]}
  tariftr3tdthree = {"content":["Moins de 5 jours"]};
  // stringFormatted17() {
  //   return this.tariftr3tdthree.content[0].replace(/Gratuit/g, "<span class='blue f-big'>Gratuit</span>");
  // }

  tariftr4tdone = {"title":"Livraison à domicile","content":["Ou sur le lieu de votre choix"]}
  // tariftr4tdtwo = {"content":["Gratuit"]}
  tariftr4tdtwo = {"content":["5€"]};
  // stringFormatted18() {
  //   return this.tariftr4tdtwo.content[0].replace(/Gratuit/g, "<span class='orange f-big'>Gratuit</span>");
  // }
  // tariftr4tdthree = {"content":["Gratuit"]}
  tariftr4tdthree = {"content":["Gratuit"]};
  // stringFormatted19() {
  //   return this.tariftr4tdthree.content[0].replace(/Gratuit/g, "<span class='blue f-big'>Gratuit</span>");
  // }

  tariftr5tdone = {"title":"Produits hors format","content":["Tous les produits qui ne tiennent pas dans une valise"]}
  //tariftr5tdtwo = {"content":["Gratuit"]}
  tariftr5tdtwo = {"content":["20€"]};
  // stringFormatted20() {
  //   return this.tariftr5tdtwo.content[0].replace(/Gratuit/g, "<span class='orange f-big'>Gratuit</span>");
  // }
  //tariftr5tdthree = {"content":["Gratuit"]}
  tariftr5tdthree = {"content":["Gratuit"]};
  // stringFormatted21() {
  //   return this.tariftr5tdthree.content[0].replace(/Gratuit/g, "<span class='blue f-big'>Gratuit</span>");
  // }

  tariftr6tdone = {"title":"Click & Collect","content":["Nous récuperons en boutiques vos achats"]}
  //tariftr6tdtwo = {"content":["Gratuit"]}
  tariftr6tdtwo = {"content":["20€"]};
  // stringFormatted22() {
  //   return this.tariftr6tdtwo.content[0].replace(/Gratuit/g, "<span class='orange f-big'>Gratuit</span>");
  // }
  // tariftr6tdthree = {"content":["Gratuit"]}
  tariftr6tdthree = {"content":["Gratuit"]};
  // stringFormatted23() {
  //   return this.tariftr6tdthree.content[0].replace(/Gratuit/g, "<span class='blue f-big'>Gratuit</span>");
  // }

  tariftr7tdone = {"title":"Préparation des colis (y compris sécurisation du contenu)"}
  // tariftr7tdtwo = {"content":["Gratuit"]}
  tariftr7tdtwo = {"content":["Gratuit"]};
  stringFormatted24() {
    return this.tariftr7tdtwo.content[0].replace(/Gratuit/g, "<span class='orange f-big'>Gratuit</span>");
  }
  // tariftr7tdthree = {"content":["Gratuit"]}
  tariftr7tdthree = {"content":["Gratuit"]};
  stringFormatted25() {
    return this.tariftr7tdthree.content[0].replace(/Gratuit/g, "<span class='blue f-big'>Gratuit</span>");
  }

  tarifthree = {"title":"Les frais de services ne s’appliquent que sur les achats détaxés","content":["les frais de réexpéditions sont vraiment gratuits"]}

  tariftr8tdtwo = {"content":["19,50 € par kilo"]};
  stringFormatted3() {
    return this.tariftr8tdtwo.content[0].replace(/19,50 €/g, "<span class='orange f-big'>19,50 €</span>");
  }

  tariftr8tdthree = {"content":["9 € par kilo"]};
  stringFormatted4() {
    return this.tariftr8tdthree.content[0].replace(/9 €/g, "<span class='blue f-big'>9 €</span>");
  }
  
  tariftr8tdone = {"title":"Entre 0 et 1 kilo"}
  
  tariftr9tdone = {"title":"Entre 1 et 5 kilos"}

  tariftr9tdtwo = {"content":["13,50 € par kilo"]};
  stringFormatted5() {
    return this.tariftr9tdtwo.content[0].replace(/13,50 €/g, "<span class='orange f-big'>13,50 €</span>");
  }

  tariftr9tdthree = {"content":["9 € par kilo"]};
  stringFormatted6() {
    return this.tariftr9tdthree.content[0].replace(/9 €/g, "<span class='blue f-big'>9 €</span>");
  }

  tariftr10tdtwo = {"content":["12 € par kilo"]};
  stringFormatted7() {
    return this.tariftr10tdtwo.content[0].replace(/12 €/g, "<span class='orange f-big'>12 €</span>");
  }

  tariftr10tdthree = {"content":["9 € par kilo"]};
  stringFormatted8() {
    return this.tariftr10tdthree.content[0].replace(/9 €/g, "<span class='blue f-big'>9 €</span>");
  }

  tariftr10tdone = {"title":"Entre 5 et 10 kilos"}
  // tariftr10tdtwo = {"content":["12 € par kilo"]}
  // tariftr10tdthree = {"content":["9 € par kilo"]}

  tariftr11tdone = {"title":"Entre 10 et 23 kilos"}

  tariftr11tdtwo = {"content":["10 € par kilo"]};
  stringFormatted9() {
    return this.tariftr11tdtwo.content[0].replace(/10 €/g, "<span class='orange f-big'>10 €</span>");
  }

  tariftr11tdthree = {"content":["9 € par kilo"]};
  stringFormatted10() {
    return this.tariftr11tdthree.content[0].replace(/9 €/g, "<span class='blue f-big'>9 €</span>");
  }

  // tariftr11tdtwo = {"content":["10 € par kilo"]}
  // tariftr11tdthree = {"content":["9,99 € par kilo"]}

  tariftr12tdone = {"title":"Plus de 23 kilos"}

  tariftr12tdtwo = {"content":["9,30 € par kilo"]};
  stringFormatted11() {
    return this.tariftr12tdtwo.content[0].replace(/9,30 €/g, "<span class='orange f-big'>9,30 €</span>");
  }

  tariftr12tdthree = {"content":["9 € par kilo"]};
  stringFormatted12() {
    return this.tariftr12tdthree.content[0].replace(/9 €/g, "<span class='blue f-big'>9 €</span>");
  }

  // tariftr12tdtwo = {"content":["9,30 € par kilo"]}
  // tariftr12tdthree = {"content":["9 € par kilo"]}

  tariffour = {"title":"Options de livraison :"}

  tariftr13tdone = {"title":"Produits hors-format","content":["Il s’agit de tous les produits qui ne tiennent pas dans une valise."]}
  // tariftr13tdtwo = {"content":["20 €"]}
  tariftr13tdtwo = {"content":["20 €"]};
  stringFormatted13() {
    return this.tariftr13tdtwo.content[0].replace(/20 €/g, "<span class='orange f-big'>20 €</span>");
  }
  // tariftr13tdthree = {"content":["Gratuit"]}
  tariftr13tdthree = {"content":["Gratuit"]};
  stringFormatted26() {
    return this.tariftr13tdthree.content[0].replace(/Gratuit/g, "<span class='blue f-big'>Gratuit</span>");
  }

  tariftr14tdone = {"title":"Livraison sur-mesure","content":["Remise de votre colis en mains propres à l’adresse de votre choix."]}
  // tariftr14tdtwo = {"content":["5 €"]}
  tariftr14tdtwo = {"content":["5 €"]};
  stringFormatted14() {
    return this.tariftr14tdtwo.content[0].replace(/5 €/g, "<span class='orange f-big'>5 €</span>");
  }

  tariftr14tdthree = {"content":["Gratuit"]};
  stringFormatted15() {
    return this.tariftr14tdthree.content[0].replace(/Gratuit/g, "<span class='blue f-big'>Gratuit</span>");
  }
  // tariftr14tdthree = {"content":["Gratuit"]}

  footerbtn1 = {"link":"JE COMMANDE","url":"https://app.voyey.com/"}
  footerbtn2 = {"link":"JE M’ABONNE","url":"https://app.voyey.com/"}  

  voyey1 = {"content":["5 %"]};
  voyey2 = {"content":["1 %"]};
  voyey3 = {"content":["5 % + 20 €"]};
  voyey4 = {"content":["1 %"]};
  voyey5 = {"content":["du montant total du ou des produit(s) acheté(s)"]};
  voyey6 = {"content":["du montant total du ou des produit(s) acheté(s)"]};
  voyey7 = {"content":["Gratuit"]};
  voyey8 = {"content":["Gratuit"]};
  voyey9 = {"content":["Gratuit"]};
  voyey10 = {"content":["Gratuit"]};
  voyey11 = {"content":["Gratuit"]};
  voyey12 = {"content":["Gratuit"]};
  voyey13 = {"content":["Gratuit"]};
  voyey14 = {"content":["Gratuit"]};
  voyey15 = {"content":["Gratuit"]};
  voyey16 = {"content":["Gratuit"]};
  voyey17 = {"content":["19,50 €"]};
  voyey18 = {"content":["9 €"]};
  voyey19 = {"content":["13,50 €"]};
  voyey20 = {"content":["9 €"]};
  voyey21 = {"content":["12 €"]};
  voyey22 = {"content":["9 €"]};
  voyey23 = {"content":["10 €"]};
  voyey24 = {"content":["9 €"]};
  voyey25 = {"content":["9,30 €"]};
  voyey26 = {"content":["9 €"]};
  voyey27 = {"content":["20 €"]};
  voyey28 = {"content":["Gratuit"]};
  voyey29 = {"content":["5 €"]};
  voyey30 = {"content":["Gratuit"]};
  parunit = {"content":["par kilo"]};
  
}