<div class="contact-us-raw main-wrapper section">
    <div class="contact-us-col section">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-xs-12 contact-col-top">
                    <h1>Contactez-nous</h1>
                    <p>Dites-nous ce que nous pouvons faire pour vous.</p>
                </div>
                <div class="col-sm-4 col-xs-12 contact-col">
                    <div class="contact-box">
                        <div class="contact-top-box">
                            <i><img src="assets/images/ic-ventes.svg" alt="Ventes"></i>
                            <h4>Ventes</h4>
                            <p>Contactez nos équipes commerciales pour en savoir plus sur Voyey.</p>
                        </div>
                        <a id="contact-link" href="/contact#contact-col">CONTACTEZ L’ÉQUIPE COMMERCIALE <i></i></a>
                    </div>
                </div>
                <div class="col-sm-4 col-xs-12 contact-col">
                    <div class="contact-box">
                        <div class="contact-top-box">
                            <i><img src="assets/images/ic-support-client.svg" alt="Ventes"></i>
                            <h4>Aide et support client</h4>
                            <p>Nous sommes là pour répondre à questions et vous aider à utiliser Voyey.</p>
                        </div>
                        <a href="https://voyey.com/faqs">OBTENIR DE L’AIDE <i></i></a>
                    </div>
                </div>
                <div class="col-sm-4 col-xs-12 contact-col">
                    <div class="contact-box">
                        <div class="contact-top-box">
                            <i><img src="assets/images/ic-media-press.svg" alt="Ventes"></i>
                            <h4>Média & Presse</h4>
                            <p>Consultez notre actualité et les articles de presse sur Voyey.</p>
                        </div>
                        <a href="https://voyey.com/faqs">CONSULTEZ LA SECTION PRESSE <i></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="left-separator">
            <img src="assets/images/bg-pattern-dots.png" alt="seperator" class="img-fluid">
        </div>
    </div>
    <div class="personalized-wrap ptb-10 bg-gry section" id="contact-col">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="text-wrap">
                        <h2>Contacter l’équipe commerciale</h2>
                        <p>Notre équipe est disponible pour vous et sera ravie d’échanger avec vous pour concevoir un devis qui correspond à vos besoins spécifiques.</p>
                        <img src="assets/images/personalized.png" alt="personalized" class="img-fluid personalized-img">
                        <h4>Pressé ? Contactez directement notre équipe.</h4>
                        <ul class="con-list">
                            <li>Concentrez-vous sur l’essentiel </li>
                            <li>Dites adieu aux coûts cachés</li>
                            <li>Gagnez en rapidité et agilité</li>
                        </ul>
                        <div class="btn-contact">
                            <a class="call" href="tel:0650614266"><i><img src="assets/images/ic-call-header.svg" class="img-fluid" alt="Phone"></i>06 50 61 42 66</a>
                            <a class="w-call" href="https://api.whatsapp.com/send?phone=330650614266" target="_blank" rel="noopener nofollow"><i class="fa fa-caret-left"></i><i class="fa fa-whatsapp"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 call-back">
                    <div class="form-box">
                        <i><img src="assets/images/form-vous.svg" class="img-fluid" alt="Call Back"></i>
                        <h3>Vous préférez être rappelé ? Transmettez-nous vos informations !</h3>
                        <form class="text-left" #contactForm="ngForm" (ngSubmit)="contactForm.form.valid && onSubmit(contactForm)">
                            <div class="form-group section">
                                <div class="form-filed">
                                    <label>Votre nom et prénom</label>
                                    <input type="text" name="name" class="form-control" [(ngModel)]="model.name" #name="ngModel" placeholder="Indiquez votre complet" [ngClass]="{ 'is-invalid': contactForm.submitted && name.invalid }" required>
                                    <div class="invalid-feedback" *ngIf="contactForm.submitted && name.invalid">
                                        <p *ngIf="name.errors.required">This field is required</p>
                                    </div>
                                </div>
                                <div class="form-filed">
                                    <label>Votre téléphone</label>
                                    <input type="number" class="form-control" placeholder="Indiquez votre numéro de téléphone" name="number" [(ngModel)]="model.number" #number="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && number.invalid }" required>
                                    <div *ngIf="contactForm.submitted && number.invalid" class="invalid-feedback">
                                        <p *ngIf="number.errors.required">This field is required</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group section">
                                <div class="form-filed">
                                    <label>Votre e-mail</label>
                                    <input type="email" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" placeholder="Indiquez votre adresse e-mail" [ngClass]="{ 'is-invalid': contactForm.submitted && email.invalid }" email required>
                                    <div *ngIf="contactForm.submitted && email.invalid" class="invalid-feedback">
                                        <p *ngIf="email.errors.required">This field is required</p>
                                        <p *ngIf="email.errors.email">Must be a valid email address</p>
                                    </div>
                                </div>
                                <div class="form-filed">
                                    <label>Votre entreprise</label>
                                    <input type="text" class="form-control" placeholder="Indiquez le nom de votre entreprise" name="company" [(ngModel)]="model.company" #company="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && company.invalid }" company required>
                                    <div class="invalid-feedback" *ngIf="contactForm.submitted && company.invalid">
                                        <p *ngIf="company.errors.required">This field is required</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group section">
                                <div class="form-filed">
                                    <label>Le poids de votre colis</label>
                                    <input type="text" class="form-control" placeholder="Saisir le poids de livraison.." name="delivery" [(ngModel)]="model.delivery" #delivery="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && delivery.invalid }" required>
                                    <div class="invalid-feedback" *ngIf="contactForm.submitted && delivery.invalid">
                                        <p *ngIf="delivery.errors.required">This field is required</p>
                                    </div>
                                </div>
                                <div class="form-filed">
                                    <label>La valeur de vos produits</label>
                                    <input type="number" class="form-control" placeholder="Entrez la valeur" name="euro" [(ngModel)]="model.euro" #euro="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && euro.invalid }" required>
                                    <div class="invalid-feedback" *ngIf="contactForm.submitted && euro.invalid">
                                        <p *ngIf="euro.errors.required">This field is required</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group section">
                                <div class="form-filed">
                                    <label>Destination</label>
                                    <input type="text" class="form-control" placeholder="Entrer la destination" name="destination" [(ngModel)]="model.destination" #destination="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && destination.invalid }" required>
                                    <div class="invalid-feedback" *ngIf="contactForm.submitted && destination.invalid">
                                        <p *ngIf="destination.errors.required">This field is required</p>
                                    </div>
                                </div>
                                <div class="form-filed">
                                    <label>Quelque chose à ajouter ?</label>
                                    <input type="text" class="form-control" placeholder="Indiquez votre message" name="recalled" [(ngModel)]="model.recalled" #recalled="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && recalled.invalid }" required>
                                    <div class="invalid-feedback" *ngIf="contactForm.submitted && recalled.invalid">
                                        <p *ngIf="recalled.errors.required">This field is required</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group section">
                                <button class="btn-main" type="submit">ENVOYER AU SERVICE COMMERCIAL</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>