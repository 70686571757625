<div class="main-wrapper section">
  <div class="overseas-wrap" section>
    <div class="container">
      <div class="img-wrap">
          <img src="assets/images/overseas.jpg" alt="overseas" class="img-fluid">
      </div>
      <div class="text-wrap">
          <h2>Nous voulons rendre l’e-commerce vraiment accessible aux ultramarins</h2>
          <p>Voyey est un service d’achat détaxé et de réexpédition outre-mer, destiné aux personnes vivant en Guadeloupe, en Martinique, en Guyane et à La Réunion.</p>
          <p>Aujourd’hui, à chaque fois qu’ils achètent un produit sur un site d’e-commerce, les Domiens doivent payer des frais de livraison prohibitifs et patienter des jours avant de recevoir leur colis. Et le pire dans tout ça, c’est qu’ils doivent s’acquitter de la TVA métropolitaine en plus des taxes locales à la consommation...</p>
          <p>Chez Voyey, on trouve que ça n’est pas normal et c’est pourquoi on a décidé de bouger les choses !</p>
          <p>Avec nous, l’acheminement vers les DOM se fait par l’intermédiaire de voyageurs individuels, qui mettent une partie de leur franchise bagage à la disposition de nos utilisateurs. Résultat : nous livrons tous les DOMs sous 1 et 5 jours et nos utilisateurs font des économies drastiques (au moins 50 % par envoi) !</p>
          <p>En parallèle, nous avons mis en place un service d’achat détaxé qui permet aux ultramarins d’être exonérés de la TVA métropolitaine en toute légalité. Nos utilisateurs paient les produits achetés sur Internet moins cher qu’un métropolitain et cela comprend la livraison et les taxes locales !</p>
      </div>
    </div>
  </div>
  <div class="about-boxes link-wrapper ptb-10 text-center">
    <div class="container">
      <div class="row">
          <div class="col-sm-12">
              <h2>Nous partageons la même vision</h2>
          </div>
      </div>
      <div class="row text-center">
        <div class="col-md-4">
          <div class="boxes-wrap">
            <i><img src="assets/images/Outre-mer.svg" class="img-fluid" alt="Outre-mer"></i>
            <h4>Outre-mer</h4>
            <p>Voyey a été créé par des ultramarins pour les ultramarins. L’avantage, c’est qu’on connaît parfaitement l’outre-mer, qu’on l’aime et qu’on sait les difficultés qui peuvent se poser au niveau local.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="boxes-wrap">
            <i><img src="assets/images/Innovation.svg" class="img-fluid" alt="Innovation"></i>
            <h4>Innovation</h4>
            <p>Nous développons des services novateurs, mais utiles et accessibles à tous les ultramarins. Leur point commun, c’est qu’ils contribuent à résoudre les inégalités liées à l’éloignement géographique.</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="boxes-wrap" >
            <i><img src="assets/images/communaute.svg" class="img-fluid" alt="Communauté"></i>
            <h4>Communauté</h4>
            <p>Voyey, c’est la magie de l’approche communautaire gagnant-gagnant. Les voyageurs aériens se font de l’argent et les Domiens récupèrent leurs colis dans des délais très courts, à des prix imbattables.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="budget-wrapper about-delivery ptb-10 bg-gry">
    <div class="container">
      <div class="row">
        <div class="col-md-7 align-self-center">
          <div class="text-wrap">
            <h2>Bien plus qu’un service de livraison et de réexpédition</h2>
            <p>Voyey, ça n’est pas seulement un service de livraison et de réexpédition ultra rapide et économique entre la métropole et l’outre-mer. Voyey, c’est aussi un service d’achat détaxé qui permet aux ultramarins d’acheter des produits en métropole sans être accablés de charges. Nous nous occupons des achats sur Internet ou en magasin et leur faisons profiter de l’exonération de la TVA dont nous bénéficions en tant qu’entreprise. Au final, nos utilisateurs ne paient que les taxes locales. C’est moins cher et cela contribue au développement de leur département !</p>
            <a href="/fonctionnement" class="links inline-link">COMMENT FONCTIONNE VOYEY <span></span></a>
            <a href="/achat-detaxe-dom" class="links inline-link">ACHAT DÉTAXÉ <span></span></a> 
            <a href="/reexpedition-livraison-dom" class="links inline-link">RÉEXPÉDITION & LIVRAISON <span></span></a> 
          </div>
        </div>
        <div class="col-md-5">
          <div class="img-wrap">
            <img src="assets/images/about-delivery.jpg" class="img-fluid" alt="We are listening to you">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="vat-wrapper security-wrapper ptb-10">
  <div class="container">
    <div class="row department-inner">
      <div class="col-sm-7 right-con align-self-center">
        <div class="text-wrap">
          <h2>Sécurité à tous les niveaux</h2>
          <p>Nous avons mis en place des process très stricts pour garantir la sécurité des voyageurs, des marchandises et des données. Tous les colis que nous acheminons outre-mer sont soumis à un double contrôle par nos équipes et les services de douanes. Chaque colis est également reconditionné par nos soins de manière à garantir leur intégrité au cours du voyage. Enfin, un agent Voyey est présent à chaque bout de la chaîne de livraison pour remettre et réceptionner les articles que l’on nous confie. Inutile de préciser que toutes vos données sont hébergées sur un environnement crypté et ultra-sécurisé.</p>
          <a href="/confiance-securite" class="links">EN SAVOIR PLUS</a>
        </div>
      </div>
      <div class="col-sm-5 col-xs-12 left-con">
        <div class="img-wrap">
          <img src="assets/images/about-security.jpg" class="img-fluid" alt="Voyey Process">
        </div>
      </div> 
    </div>
  </div>
</div>
  <div class="why-wrapper beginning-wrap bg-gry ptb-10 section">
    <div class="container">
      <div class="team-wrap section">
        <div class="team-head text-center">
          <h2>L’équipe</h2>
          <p>Voyey a été fondé par deux frères – deux Antillais passionnés par leur région, les nouvelles technologies et le transport et qui ont pour ambition de contribuer au développement de l’outre-mer.</p>
        </div>
        <div class="team-main section">
          <ul>
              <li>
                <div class="team-box">
                  <img src="assets/images/Manuel.png" alt="Manuel" class="img-fluid">
                  <div class="text-wrap">
                    <h2>Manuel</h2>
                    <p>Manuel, c’est le plus jeune, mais c’est LE spécialiste transport et logistique de l’équipe. Son idée fixe, c’est la réduction des coûts et des délais, alors, bien sûr, chez Voyey, c’est qui lui qui tient les rênes de l’opérationnel. Diplômé de l’Institut supérieur du transport et de la logistique internationale, il a aussi créé 10A Freight, une société de transport de marchandises reconnue.</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="team-box">
                  <img src="assets/images/Malik.png" alt="Malik" class="img-fluid">
                  <div class="text-wrap">
                    <h2>Malik</h2>
                    <p>Malik, lui, a deux passions dans la vie : l’informatique et l’entrepreneuriat. Son rêve, c’est de mettre son expertise et sa créativité au service des ultramarins en créant des produits innovants qui répondent à leurs besoins réels. Diplômé de l’Epitech et de la Neoma Business School, il a également fondé 10A Consulting et Abomicro, des entreprises de conseil et d’hébergement informatique.</p>
                  </div>
                </div>
              </li>
          </ul>
        </div>
      </div>
    </div> 
  </div>
</div>