<div class="main-wrapper section">
    <div class="price-wrapper ptb-10 section">
        <div class="container">
            <div class="price-title section text-center">
                <h2>Des tarifs simples et transparents</h2>
                <ul>
                    <li>Pas de frais cachés</li>
                    <li>Sans engagement</li>
                    <li>Dédouanement inclus</li>
                </ul>
                <p>Achats détaxés, réexpédition, livraison – Voyey vous propose des tarifs imbattables et<br> rend l’e-commerce enfin accessible à tous les ultramarins !</p>
            </div>
            <div class="desktop-tarif section">
                <div class="terif-raw section">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td class="td1" width="40%">&nbsp;</td>
                                <td width="30%" class="td2 price-head voyey relative">
                                    <img src="assets/images/logo.svg" alt="voyey" class="img-fluid">
                                    <div class="hequal-td">
                                        <h5 *ngFor="let c of titlename1.title">{{c}}</h5>
                                    </div>
                                    <a target="_blank" href="{{ titlename1.url }}" class="btn-main"><span>{{titlename1.link}}</span><i></i></a>
                                </td>
                                <td width="30%" class="td2 price-head voyey-akaz relative">
                                    <h6 class="abs-title">Abonnement</h6>
                                    <img src="assets/images/voyey-akaz.png" alt="voyey" class="img-fluid">
                                    <!-- <h5 *ngFor="let c of titlename2.title">{{c}}</h5> -->
                                    <div class="hequal-td n">
                                        <div class="monthly-col">
                                            <h6>9,99€/mois</h6>
                                            <p>Sans engagement</p>
                                        </div>
                                        <div class="toggle-button">
                                            <label class="switch">
                                              <input type="checkbox">
                                              <span class="slider round"></span>
                                            </label>
                                        </div>
                                        <div class="monthly-col">
                                            <h6>49,00€/année</h6>
                                            <p class="stripe">119,88€/année</p>
                                        </div>
                                    </div>
                                    <a target="_blank" href="{{ titlename2.url }}" class="btn-main"><span>{{titlename2.link}}</span><i></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="abs-raw">
                        <div class="abs-col" *ngIf="tarifone">
                            <h4>{{tarifone.title}}</h4>
                            <p *ngFor="let c of tarifone.content">{{c}}</p>
                        </div>
                    </div> -->
                    <table class="table">
                        <tbody>
                            <tr>
                                <td width="40%" class="td1 border-top">
                                    <h5>{{tariftr1tdone.title}}</h5>
                                    <p *ngFor="let c of tariftr1tdone.content"><i>{{c}}</i></p>
                                </td>
                                <td width="30%" class="td2 text-center">
                                    <h4 class="orange" *ngFor="let c of tariftr1tdtwo.content">{{c}}</h4>
                                    <!-- <h4 [innerHTML]="stringFormatted()"></h4> -->
                                </td>
                                <td width="30%" class="td2 text-center">
                                    <h4 class="blue" *ngFor="let c of tariftr1tdthree.content">{{c}}</h4>
                                    <!-- <h4 [innerHTML]="stringFormatted2()"></h4> -->
                                </td>
                            </tr>
                            <tr>
                                <td class="td1">
                                    <h5>{{tariftr2tdone.title}}</h5>
                                    <p *ngFor="let c of tariftr2tdone.content">&nbsp;</p>
                                </td>
                                <td class="text-center td2">
                                    <h4 class="orange" *ngFor="let c of tariftr2tdtwo.content">{{c}}</h4>
                                    <!-- <h6  [innerHTML]="stringFormatted1()"></h6> -->
                                </td>
                                <td class="text-center td2">
                                    <!-- <h6  [innerHTML]="stringFormattedtwo()"></h6> -->
                                    <h4 class="blue" *ngFor="let c of tariftr2tdthree.content">{{c}}</h4>
                                </td>
                            </tr>
                            <tr>
                                <td class="td1">
                                    <h5>{{tariftr3tdone.title}}</h5>
                                    <p>&nbsp;</p>
                                </td>
                                <td class="text-center td2">
                                    <h4 class="orange" *ngFor="let c of tariftr3tdtwo.content">{{c}}</h4>
                                </td>
                                <td class="text-center td2">
                                    <h4 class="blue" *ngFor="let c of tariftr3tdthree.content">{{c}}</h4>
                                    <!-- <h6 [innerHTML]="stringFormatted17()"></h6> -->
                                </td>
                            </tr>
                            <!-- </tbody>
                    </table>
                    <div class="abs-raw abs-table">
                        <div class="abs-col">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h4 class="text-center">{{tariftwo.title}}</h4>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <table class="table">
                        <tbody> -->
                            <tr>
                                <td class="td1" width="40%">
                                    <h5>{{tariftr4tdone.title}}</h5>
                                    <p *ngFor="let c of tariftr4tdone.content"><i>{{c}}</i></p>
                                </td>
                                <td width="30%" class="text-center td2">
                                    <h4 class="orange" *ngFor="let c of tariftr4tdtwo.content">{{c}}</h4>
                                    <!-- <h6 [innerHTML]="stringFormatted18()"></h6> -->
                                </td>
                                <td width="30%" class="text-center td2">
                                    <h4 class="blue" *ngFor="let c of tariftr4tdthree.content">{{c}}</h4>
                                    <!-- <h6 [innerHTML]="stringFormatted19()"></h6> -->
                                </td>
                            </tr>
                            <tr>
                                <td class="td1">
                                    <h5>{{tariftr5tdone.title}}</h5>
                                    <p *ngFor="let c of tariftr5tdone.content"><i>{{c}}</i></p>
                                </td>
                                <td class="td2 text-center">
                                    <h4 class="orange" *ngFor="let c of tariftr5tdtwo.content">{{c}}</h4>
                                    <!-- <h6 [innerHTML]="stringFormatted20()"></h6> -->
                                </td>
                                <td class="td2 text-center">
                                    <h4 class="blue" *ngFor="let c of tariftr5tdthree.content">{{c}}</h4>
                                    <!-- <h6 [innerHTML]="stringFormatted21()"></h6> -->
                                </td>
                            </tr>
                            <tr>
                                <td class="td1">
                                    <h5>{{tariftr6tdone.title}}</h5>
                                    <p *ngFor="let c of tariftr6tdone.content"><i>{{c}}</i></p>
                                </td>
                                <td class="td2 text-center">
                                    <h4 class="orange" *ngFor="let c of tariftr6tdtwo.content">{{c}}</h4>
                                    <!-- <h6 [innerHTML]="stringFormatted22()"></h6> -->
                                </td>
                                <td class="td2 text-center">
                                    <h4 class="blue" *ngFor="let c of tariftr6tdthree.content">{{c}}</h4>
                                    <!-- <h6 [innerHTML]="stringFormatted23()"></h6> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="abs-raw">
                        <div class="abs-col">
                            <h4>{{tarifthree.title}}</h4>
                            <p *ngFor="let c of tarifthree.content">{{c}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-tarif section">
                <!-- <ul class="nav nav-tabs" role="tablist"> -->
                <ul class="nav nav-tabs">
                    <li class="nav-item voyey-tab">
                        <!-- <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab" onclick="somefunction(); return false;"> -->
                        <a href="javascript:void(0)" class="nav-link">
                            <div class="tab-head voyey">
                                <img src="assets/images/logo.svg" alt="voyey" class="img-fluid" />
                                <div class="hequal-td">
                                    <h5 *ngFor="let c of titlename1.title">{{c}}</h5>
                                </div>
                                <a target="_blank" href="{{ titlename1.url }}" class="btn-main"><span>{{titlename1.link}}</span><i></i></a>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item voyeyakaz-tab">
                        <!-- <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab" onclick="somefunction(); return false;"> -->
                        <a href="javascript:void(0)" class="nav-link">
                            <div class="tab-head voyey-akaz">
                                <h6 class="abs-title">Abonnement</h6>
                                <img src="assets/images/voyey-akaz.png" alt="voyey" class="img-fluid" />
                                <!-- <h5 *ngFor="let c of titlename2.title">{{c}}</h5> -->
                                <div class="hequal-td n">
                                    <div class="monthly-col">
                                        <h6>9,99€/mois</h6>
                                        <p>Sans engagement</p>
                                    </div>
                                    <div class="toggle-button">
                                        <label class="switch">
                                            <input type="checkbox">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <div class="monthly-col">
                                        <h6>49,00€/année</h6>
                                        <p class="stripe">119,88€/année</p>
                                    </div>
                                </div>
                                <a target="_blank" href="{{ titlename2.url }}" class="btn-main"><span>{{titlename2.link}}</span><i></i></a>
                            </div>
                        </a>
                    </li>
                </ul>
                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane active" id="tabs-1" role="tabpanel">
                        <div class="money-wrapper section">
                            <div class="accordion" id="accordionExample1">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-controls="collapseOne">
                                                <h4>{{tariftr1tdone.title}}</h4>
                                                <div class="flex-line">
                                                    <p *ngFor="let c of tariftr1tdone.content">{{c}} </p>
                                                </div>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample1">
                                        <div class="card-body">
                                            <!-- <h5>{{tariftr1tdone.title}}</h5>
                                            <div class="flex-line">
                                                <p *ngFor="let c of tariftr1tdone.content">{{c}} </p>
                                            </div> -->
                                            <!-- <h6 class="orange f-big" *ngFor="let c of tariftr1tdtwo.content">{{c}}</h6> -->
                                            <!-- <p [innerHTML]="stringFormatted()"></p> -->
                                            <div class="section vcol">
                                                <p class="orange f-big" *ngFor="let c of tariftr1tdtwo.content">{{c}}</p>
                                                <p class="blue f-big" *ngFor="let c of tariftr1tdthree.content">{{c}}</p>
                                            </div>
                                            <!-- <p *ngFor="let c of voyey5.content">{{c}} </p> -->
                                            <!-- <div class="seprator"></div>
                                            <h5>{{tariftr2tdone.title}}</h5>
                                            <div class="flex-line">
                                                <p *ngFor="let c of tariftr2tdone.content">{{c}} </p>
                                            </div>
                                            <div class="section vcol">
                                                <p class="orange f-big" *ngFor="let c of voyey3.content">{{c}}</p>
                                                <p class="blue f-big" *ngFor="let c of voyey4.content">{{c}}</p>
                                            </div>
                                            <p *ngFor="let c of voyey6.content">{{c}} </p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-controls="collapseTwo">
                                                <h4>{{tariftr2tdone.title}}</h4>
                                                <!-- <div class="flex-line">
                                                <p *ngFor="let c of tariftwo.content">{{c}} </p>
                                                </div> -->
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" aria-labelledby="headingTwo" data-parent="#accordionExample1">
                                        <div class="card-body">
                                            <!-- <h5>{{tariftr3tdone.title}}</h5> -->
                                            <div class="section vcol">
                                                <p class="orange f-big" *ngFor="let c of tariftr2tdtwo.content">{{c}}</p>
                                                <p class="blue f-big" *ngFor="let c of tariftr2tdthree.content">{{c}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-controls="collapseThree">
                                                <h4>{{tariftr3tdone.title}}</h4>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" aria-labelledby="headingThree" data-parent="#accordionExample1">
                                        <div class="card-body">
                                            <div class="section vcol">
                                                <p class="orange f-big" *ngFor="let c of tariftr3tdtwo.content">{{c}}</p>
                                                <p class="blue f-big" *ngFor="let c of tariftr3tdthree.content">{{c}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTFour">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-controls="collapseFour">
                                                <h4>{{tariftr4tdone.title}}</h4>
                                                <p>En mains propres a l’adresse de votre choix</p>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" aria-labelledby="headingTFour" data-parent="#accordionExample1">
                                        <div class="card-body">
                                            <div class="section vcol">
                                                <p class="orange f-big" *ngFor="let c of tariftr4tdtwo.content">{{c}}</p>
                                                <p class="blue f-big" *ngFor="let c of tariftr4tdthree.content">{{c}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTFive">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-controls="collapseFive">
                                                <h4>{{tariftr5tdone.title}}</h4>
                                                <p *ngFor="let c of tariftr5tdone.content">{{c}}</p>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" aria-labelledby="headingTFive" data-parent="#accordionExample1">
                                        <div class="card-body">
                                            <div class="section vcol">
                                                <p class="orange f-big" *ngFor="let c of tariftr5tdtwo.content">{{c}}</p>
                                                <p class="blue f-big" *ngFor="let c of tariftr5tdthree.content">{{c}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTSix">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseSix" aria-controls="collapseSix">
                                                <h4>{{tariftr6tdone.title}}</h4>
                                                <p *ngFor="let c of tariftr6tdone.content">{{c}}</p>
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSix" aria-labelledby="headingTSix" data-parent="#accordionExample1">
                                        <div class="card-body">
                                            <div class="section vcol">
                                                <p class="orange f-big" *ngFor="let c of tariftr6tdtwo.content">{{c}}</p>
                                                <p class="blue f-big" *ngFor="let c of tariftr6tdthree.content">{{c}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="price-title section text-center">
                <h3>Inclus par défaut dans tous les services Voyey !</h3>
                <!-- <ul class="btm">
                    <li>Dédouanement</li>
                    <li>Assurances perte, vol et casse</li>
                    <li>Suivi en temps réel</li>
                </ul> -->
                <div class="section les-voyey-serices-col">
                    <div class="row justi justify-content-center">
                        <div class="col-sm-6 col-12 left-conn">
                            <ul>
                                <li>
                                    <img class="img-fluid" src="assets/images/support.svg" width="100px" alt="" />
                                </li>
                                <li>
                                    <h4><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i></h4>
                                    <p>Service client 5 étoiles par <br>email, Whatsapp ou <br>téléphone</p>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-12 right-conn">
                            <ul class="con-list">
                                <li>Réception de colis</li>
                                <li>Regroupement de colis</li>
                                <li>Stockage illimité</li>
                                <li>Photos/Protection marchandises</li>
                                <li>Frais de douanes dès l'achat</li>
                                <li>Frais de transit</li>
                                <li>Assurance perte, vol et casse</li>
                                <li>Suivi en temps réel</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="personalized-wrap ptb-10 bg-gry section">
        <div class="container">
            <div class="personalized-quote-col section">
                <h2>Besoin d’un devis personnalisé ?</h2>
                <p>Notre équipe est disponible pour vous et sera ravie d’échanger avec vous pour concevoir un devis qui correspond à vos besoins spécifiques.</p>
            </div>
            <div class="section">
                <div class="row">
                    <div class="align-self-center col-md-6">
                        <div class="text-wrap text-center">
                            <div class="w-contact section">
                                <a href="https://api.whatsapp.com/send?phone=330650614266" class="wa-btn" rel="noopener nofollow"><i class="fa fa-whatsapp"></i></a>
                                <h4>Contactez-nous par Whatsapp !</h4>
                                <div class="section">
                                    <a href="https://api.whatsapp.com/send?phone=330650614266" class="w-btn btn-main" rel="noopener nofollow">06 50 61 42 66</a>
                                </div>
                            </div>
                            <img src="assets/images/service-commercial-Voyey.png" alt="équipe commerciale de Voyey derrière leurs ordinateurs" class="img-fluid">
                        </div>
                    </div>
                    <div class="align-self-center col-md-6 call-back">
                        <div class="form-box text-center">
                            <a href="tel:0650614266"><img src="assets/images/ic-call.svg" alt="Call"></a>
                            <h3>Faites-vous rappeler !</h3>
                            <form class="text-left" #contactForm="ngForm" (ngSubmit)="contactForm.form.valid && onSubmit(contactForm)">
                                <div class="form-group section">
                                    <div class="form-filed">
                                        <label>Votre nom et prénom</label>
                                        <input type="text" name="name" class="form-control" [(ngModel)]="model.name" #name="ngModel" placeholder="Indiquez votre complet" [ngClass]="{ 'is-invalid': contactForm.submitted && name.invalid }" required>
                                        <div class="invalid-feedback" *ngIf="contactForm.submitted && name.invalid">
                                            <p *ngIf="name.errors.required">This field is required</p>
                                        </div>
                                    </div>
                                    <div class="form-filed">
                                        <label>Votre téléphone</label>
                                        <input type="number" class="form-control" placeholder="Indiquez votre numéro de téléphone" name="number" [(ngModel)]="model.number" #number="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && number.invalid }" required>
                                        <div *ngIf="contactForm.submitted && number.invalid" class="invalid-feedback">
                                            <p *ngIf="number.errors.required">This field is required</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group section">
                                    <div class="form-filed">
                                        <label>Votre e-mail</label>
                                        <input type="email" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" placeholder="Indiquez votre adresse e-mail" [ngClass]="{ 'is-invalid': contactForm.submitted && email.invalid }" email required>
                                        <div *ngIf="contactForm.submitted && email.invalid" class="invalid-feedback">
                                            <p *ngIf="email.errors.required">This field is required</p>
                                            <p *ngIf="email.errors.email">Must be a valid email address</p>
                                        </div>
                                    </div>
                                    <div class="form-filed">
                                        <label>Votre entreprise</label>
                                        <input type="text" class="form-control" placeholder="Indiquez le nom de votre entreprise" name="company" [(ngModel)]="model.company" #company="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && company.invalid }" company required>
                                        <div class="invalid-feedback" *ngIf="contactForm.submitted && company.invalid">
                                            <p *ngIf="company.errors.required">This field is required</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group section">
                                    <div class="form-filed">
                                        <label>Le poids de votre colis</label>
                                        <input type="text" class="form-control" placeholder="Saisir le poids de livraison.." name="delivery" [(ngModel)]="model.delivery" #delivery="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && delivery.invalid }" required>
                                        <div class="invalid-feedback" *ngIf="contactForm.submitted && delivery.invalid">
                                            <p *ngIf="delivery.errors.required">This field is required</p>
                                        </div>
                                    </div>
                                    <div class="form-filed">
                                        <label>La valeur de vos produits</label>
                                        <input type="number" class="form-control" placeholder="Entrez la valeur" name="euro" [(ngModel)]="model.euro" #euro="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && euro.invalid }" required>
                                        <div class="invalid-feedback" *ngIf="contactForm.submitted && euro.invalid">
                                            <p *ngIf="euro.errors.required">This field is required</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group section">
                                    <div class="form-filed">
                                        <label>Destination</label>
                                        <input type="text" class="form-control" placeholder="Entrer la destination" name="destination" [(ngModel)]="model.destination" #destination="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && destination.invalid }" required>
                                        <div class="invalid-feedback" *ngIf="contactForm.submitted && destination.invalid">
                                            <p *ngIf="destination.errors.required">This field is required</p>
                                        </div>
                                    </div>
                                    <div class="form-filed">
                                        <label>Quelque chose à ajouter ?</label>
                                        <input type="text" class="form-control" placeholder="Indiquez votre message" name="recalled" [(ngModel)]="model.recalled" #recalled="ngModel" [ngClass]="{ 'is-invalid': contactForm.submitted && recalled.invalid }" required>
                                        <div class="invalid-feedback" *ngIf="contactForm.submitted && recalled.invalid">
                                            <p *ngIf="recalled.errors.required">This field is required</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group section">
                                    <button class="btn-main" type="submit">Rappelez-moi</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>