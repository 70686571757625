import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-trust',
  templateUrl: './trust.component.html',
  styleUrls: ['./trust.component.scss']
})
export class TrustComponent implements OnInit {

  title = 'Voyey | Livraison vers les DOM sécurisée via voyageurs aériens';
  jsonLD: SafeHtml;

  schema = {
    "url": "https://voyey.com/reexpedition-livraison-dom/",
    "name": "Voyey | Livraison vers les DOM sécurisée via voyageurs aériens",
    "description": "Chaque jour, des centaines d’articles sont acheminés par vol commercial entre la métropole et les départements d’outre-mer grâce à Voyey."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([      
      { name: "description", content: "Chaque jour, des centaines d’articles sont acheminés par vol commercial entre la métropole et les départements d’outre-mer grâce à Voyey." },
      { name: "og:url", content: "https://voyey.com/reexpedition-livraison-dom/" },
      { name: "og:title", content: "Voyey | Livraison vers les DOM sécurisée via voyageurs aériens" },
      { name: "og:description", content: "Chaque jour, des centaines d’articles sont acheminés par vol commercial entre la métropole et les départements d’outre-mer grâce à Voyey." },
      { name: "twitter:title", content: "Voyey | Livraison vers les DOM sécurisée via voyageurs aériens" },
      { name: "twitter:description", content: "Chaque jour, des centaines d’articles sont acheminés par vol commercial entre la métropole et les départements d’outre-mer grâce à Voyey." }
    ]);
    this.setCanonicalURL('https://voyey.com/reexpedition-livraison-dom/');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

}
