<header id="header">
    <div class="header-main section">
        <div class="header-left">
            <div class="logo">
                <a href="/"><img src="assets/images/logo-voyey.svg" class="img-fluid" alt="Logo Voyey gris foncé et jaune" /></a>
                <a href="/"><img src="assets/images/logo-symbole-voyey.svg" class="img-fluid" alt="Symbole Voyey O jaune" /></a>
            </div>
            <div class="nav-main">
                <ul>
                    <li class="menu-item">
                        <a href="/">Pourquoi choisir Voyey ?</a>
                        <span></span>
                        <ul class="menu-wrapper sub-why-choose">
                            <li>
                                <i>
                              <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22.002px" height="22.002px" viewBox="0 0 22.002 22.002" enable-background="new 0 0 22.002 22.002" xml:space="preserve">
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="" d="M11.001,13.001c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2
                                  S12.101,13.001,11.001,13.001z M11.001,15.001c2.21,0,4-1.79,4-4s-1.79-4-4-4s-4,1.79-4,4S8.791,15.001,11.001,15.001z M0.111,7.211
                                  c0.15-0.42,0.32-0.83,0.51-1.23c0.43-0.88,1.42-1.32,2.36-1.05c0.55,0.16,1.14,0.01,1.55-0.4c0.41-0.41,0.56-1,0.4-1.55
                                  c-0.27-0.94,0.17-1.93,1.05-2.36c0.4-0.19,0.81-0.36,1.23-0.51c0.92-0.32,1.94,0.07,2.41,0.93c0.28,0.5,0.8,0.81,1.38,0.81
                                  s1.11-0.31,1.38-0.81c0.47-0.86,1.49-1.25,2.41-0.93c0.42,0.15,0.83,0.32,1.23,0.51c0.88,0.43,1.32,1.42,1.05,2.36
                                  c-0.16,0.55-0.01,1.14,0.4,1.55c0.41,0.41,1,0.56,1.55,0.4c0.94-0.27,1.93,0.17,2.36,1.05c0.19,0.4,0.36,0.81,0.51,1.23
                                  c0.32,0.92-0.07,1.94-0.93,2.41c-0.5,0.28-0.81,0.8-0.81,1.38s0.31,1.11,0.81,1.38c0.86,0.47,1.25,1.49,0.93,2.41
                                  c-0.15,0.42-0.32,0.83-0.51,1.23c-0.43,0.88-1.42,1.32-2.36,1.05c-0.55-0.16-1.14-0.01-1.55,0.4c-0.41,0.41-0.56,1-0.4,1.55
                                  c0.27,0.94-0.17,1.93-1.05,2.36c-0.4,0.19-0.81,0.36-1.23,0.51c-0.92,0.32-1.94-0.07-2.41-0.93c-0.27-0.5-0.8-0.81-1.38-0.81
                                  s-1.1,0.31-1.38,0.81c-0.47,0.86-1.49,1.25-2.41,0.93c-0.42-0.15-0.83-0.32-1.23-0.51c-0.88-0.43-1.32-1.42-1.05-2.36
                                  c0.16-0.55,0.01-1.14-0.4-1.55c-0.41-0.41-1-0.56-1.55-0.4c-0.94,0.27-1.93-0.17-2.36-1.05c-0.19-0.4-0.36-0.81-0.51-1.23
                                  c-0.32-0.92,0.07-1.94,0.93-2.41c0.5-0.27,0.81-0.8,0.81-1.38s-0.31-1.1-0.81-1.38C0.181,9.151-0.209,8.131,0.111,7.211z
                                  M2.001,7.871c1.11,0.6,1.85,1.78,1.85,3.13s-0.74,2.53-1.85,3.13c0.12,0.35,0.26,0.69,0.42,1.02c1.21-0.35,2.57-0.05,3.53,0.9
                                  c0.95,0.96,1.25,2.32,0.9,3.53c0.33,0.16,0.67,0.3,1.02,0.42c0.6-1.11,1.78-1.85,3.13-1.85s2.53,0.74,3.13,1.85
                                  c0.35-0.12,0.69-0.26,1.02-0.42c-0.35-1.21-0.05-2.57,0.9-3.53c0.96-0.95,2.32-1.25,3.53-0.9c0.16-0.33,0.3-0.67,0.42-1.02
                                  c-1.11-0.6-1.85-1.78-1.85-3.13s0.74-2.53,1.85-3.13c-0.12-0.35-0.26-0.69-0.42-1.02c-1.21,0.35-2.57,0.05-3.53-0.9
                                  c-0.95-0.96-1.25-2.32-0.9-3.53c-0.33-0.16-0.67-0.3-1.02-0.42c-0.6,1.11-1.78,1.85-3.13,1.85s-2.53-0.74-3.13-1.85
                                  c-0.35,0.12-0.69,0.26-1.02,0.42c0.35,1.21,0.05,2.57-0.9,3.53c-0.96,0.95-2.32,1.25-3.53,0.9C2.261,7.181,2.121,7.521,2.001,7.871z
                                  "/>
                              </svg>
                            </i>
                                <a href="/fonctionnement">Fonctionnement</a>
                            </li>
                            <li>
                                <i>
                              <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="20px" height="25px" viewBox="0 0 20 25" enable-background="new 0 0 20 25" xml:space="preserve">
                                <g>
                                  <path fill="" d="M10,0C9.76,0,9.53,0.09,9.34,0.27c-2.36,2.28-5.19,3.96-8.27,4.9C0.44,5.36,0,5.97,0,6.67v2.7
                                    C0.17,16.19,4.07,22.28,10,25c5.93-2.72,9.83-8.81,10-15.63v-2.7c0-0.7-0.44-1.31-1.07-1.5c-3.07-0.94-5.9-2.62-8.27-4.9
                                    C10.48,0.09,10.24,0,10,0z M10,2.77c2.29,2,4.95,3.53,7.78,4.48v2.09C17.63,14.86,14.61,19.88,10,22.4
                                    c-4.61-2.52-7.63-7.54-7.78-13.06V7.25C5.05,6.3,7.71,4.77,10,2.77z"/>
                                  <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="" stroke-width="2" stroke-miterlimit="10" points="
                                    7,13 9,15 14,10 	"/>
                                </g>
                              </svg>
                            </i>
                                <a href="/confiance-securite">Sécurité</a>
                            </li>
                            <li>
                                <i>
                              <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="22px" height="20px" viewBox="0 0 22 20" enable-background="new 0 0 22 20" xml:space="preserve">
                              <g>
                                <g>
                                  <path fill-rule="evenodd" clip-rule="evenodd" fill="" d="M5,11h7c0.55,0,1-0.45,1-1s-0.45-1-1-1H5c-0.55,0-1,0.45-1,1
                                    S4.45,11,5,11z M5,7h12c0.55,0,1-0.45,1-1s-0.45-1-1-1H5C4.45,5,4,5.45,4,6S4.45,7,5,7z M19,16v2c0,1.78-2.15,2.67-3.41,1.41
                                    L12.17,16H3c-1.66,0-3-1.34-3-3V3c0-1.66,1.34-3,3-3h16c1.66,0,3,1.34,3,3v10C22,14.66,20.66,16,19,16z M3,2C2.45,2,2,2.45,2,3v10
                                    c0,0.55,0.45,1,1,1h10l4,4v-4h2c0.55,0,1-0.45,1-1V3c0-0.55-0.45-1-1-1H3z"/>
                                </g>
                              </g>
                              </svg>
                            </i>
                                <a href="https://www.facebook.com/voyey97/reviews" target="_blank" rel="noopener nofollow">Témoignages</a>
                            </li>
                        </ul>
                    </li>
                    <li class="menu-item"><a>Services</a>
                        <span></span>
                        <ul class="menu-wrapper sub-services">
                            <li>
                                <i>
                              <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                  width="52.001px" height="52px" viewBox="0 0 52.001 52" enable-background="new 0 0 52.001 52" xml:space="preserve">
                                <g>
                                <path fill="#FEA100" d="M41.001,10.13c0,0.48,0.39,0.87,0.87,0.87h9.53l-10.4-10.4V10.13z"/>
                                <path fill="#FEA100" d="M13.872,15.6c6.22,0,11.26,5.04,11.26,11.27c0,6.22-5.04,11.26-11.26,11.26c-6.23,0-11.27-5.04-11.27-11.26
                                  C2.602,20.64,7.642,15.6,13.872,15.6z"/>
                                <g>
                                  <path fill-rule="evenodd" clip-rule="evenodd" fill="#505050" d="M42.001,2.16l7.44,7.84h-7.44V2.16z M51.751,9.65l-9.41-9.4
                                    C42.182,0.09,41.961,0,41.731,0h-26.86c-0.48,0-0.87,0.39-0.87,0.87v5.2c0,0.48,0.79,0.86,1.27,0.86s0.73-0.38,0.73-0.86V2h24
                                    v8.53c0,0.96,0.78,1.47,1.73,1.47h8.27v38h-34v-2.6c0-0.48-1.25-0.6-1.73-0.6s-0.27,0.39-0.27,0.87v3.46
                                    c0,0.48,0.39,0.87,0.87,0.87h36.26c0.48,0,0.87-0.39,0.87-0.87V10.27C52.001,10.04,51.912,9.82,51.751,9.65z"/>
                                  <path fill="#505050" d="M27.131,11.27c-0.47,0-1.13,0.38-1.13,0.86v1.2c-7.46-6.71-18.94-6.11-25.65,1.35l-0.13,0.15
                                    c-0.32,0.36-0.29,0.9,0.07,1.22c0.36,0.32,0.91,0.28,1.23-0.07c5.99-6.82,16.37-7.21,23.19-1.22l0.26,0.24h-1.57
                                    c-0.48,0-0.87-0.08-0.87,0.4s0.39,0.6,0.87,0.6h3.73c0.48,0,0.87-0.39,0.87-0.87v-3C28.001,11.65,27.611,11.27,27.131,11.27z"/>
                                  <path fill="#505050" d="M45.932,21h-15.6c-0.48,0-0.87,0.65-0.87,1.13s0.39,0.87,0.87,0.87h15.6c0.48,0,0.87-0.05,0.87-0.53
                                    S46.412,21,45.932,21z"/>
                                  <path fill="#505050" d="M30.332,18h15.6c0.48,0,0.87-0.65,0.87-1.13S46.412,16,45.932,16h-15.6c-0.48,0-0.87,0.19-0.87,0.67
                                    S29.852,18,30.332,18z"/>
                                  <path fill="#505050" d="M45.932,27h-15.6c-0.48,0-0.87,0.66-0.87,1.13c0,0.48,0.39,0.87,0.87,0.87h15.6
                                    c0.48,0,0.87-0.25,0.87-0.73S46.412,27,45.932,27z"/>
                                  <path fill="#505050" d="M45.932,32h-15.6c-0.48,0-0.87,0.66-0.87,1.13c0,0.48,0.39,0.87,0.87,0.87h15.6
                                    c0.48,0,0.87-0.45,0.87-0.93S46.412,32,45.932,32z"/>
                                  <path fill="#505050" d="M45.932,37h-15.6c-0.48,0-0.87,0.65-0.87,1.13s0.39,0.87,0.87,0.87h15.6c0.48,0,0.87-0.65,0.87-1.13
                                    S46.412,37,45.932,37z"/>
                                  <path fill="#505050" d="M45.932,42h-15.6c-0.48,0-0.87,0.65-0.87,1.13s0.39,0.87,0.87,0.87h15.6c0.48,0,0.87-0.86,0.87-1.33
                                    C46.801,42.19,46.412,42,45.932,42z"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" fill="#505050" d="M13.872,16.47c5.74,0,10.4,4.65,10.4,10.4
                                    c0,5.74-4.66,10.4-10.4,10.4c-5.75,0-10.4-4.66-10.4-10.4C3.472,21.13,8.122,16.47,13.872,16.47z M13.872,39
                                    c6.7,0,12.13-5.43,12.13-12.13c0-6.7-5.43-12.14-12.13-12.14c-6.71,0-12.14,5.44-12.14,12.14C1.742,33.56,7.171,38.99,13.872,39z"
                                    />
                                  <path fill="#FFFFFF" d="M20.932,28h0.87c0.48,0,0.87-0.65,0.87-1.13S22.281,26,21.801,26h-0.87c-0.48,0-0.86,0.65-0.86,1.13
                                    S20.452,28,20.932,28z"/>
                                  <path fill="#FFFFFF" d="M6.801,28c0.48,0,0.87-0.39,0.87-0.87S7.281,26,6.801,26h-0.87c-0.48,0-0.86,0.65-0.86,1.13
                                    S5.452,28,5.932,28H6.801z"/>
                                  <path fill="#505050" d="M26.222,37.76c-6,6.81-16.38,7.47-23.19,1.48L2.761,39h1.57c0.48,0,0.87-0.65,0.87-1.13S4.812,37,4.332,37
                                    h-3.46c-0.48,0-0.87,0.39-0.87,0.87v3.73c0,0.48,0.66,0.87,1.13,0.87c0.48,0,0.87-0.39,0.87-0.87v-1.19
                                    c7.46,6.71,18.94,6.1,25.65-1.36c0.05-0.05-0.18-0.1-0.13-0.14c0.31-0.36,0.28-0.91-0.08-1.23
                                    C27.082,37.37,26.531,37.4,26.222,37.76z"/>
                                </g>
                                </g>
                                <path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M14.881,32c2.04,0,2.72-1.13,3.06-2.21l-0.88-0.69
                                c-0.39,0.63-1.23,1.14-2.18,1.14c-1.18,0-2.12-0.51-2.59-1.3h3.43v-0.88h-3.77c-0.01-0.16-0.01-0.35-0.01-0.53
                                c0-0.21,0-0.39,0.03-0.59h3.75v-0.88h-3.42c0.49-1.4,1.43-2.3,2.58-2.3c0.95,0,1.79,0.92,2.18,2.07l0.88-1.28
                                c-0.34-1.44-1.02-2.55-3.06-2.55c-2.28,0-4.03,1.27-4.61,4.06h-0.71v0.88h0.52c-0.02,0.2-0.02,0.38-0.02,0.59
                                c0,0.18,0,0.37,0.02,0.53h-0.52v0.88h0.7C10.821,30.71,12.582,32,14.881,32z"/>
                              </svg>                                  
                            </i>
                                <a href="/achat-detaxe-dom">
                                    <p><span>L’achat détaxé en métropole </span>L’optimisation des tarifs de vos achats en métropole</p>
                                </a>
                            </li>
                            <li>
                                <i>
                              <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="52.437px" height="48.143px" viewBox="0 0 52.437 48.143" enable-background="new 0 0 52.437 48.143" xml:space="preserve">
                                <g>
                                  <g>
                                    <rect x="12.437" y="1" fill="#FFFFFF" stroke="#505050" stroke-width="2" stroke-miterlimit="10" width="31" height="26"/>
                                    <rect x="23.437" y="1" fill="#FEA100" stroke="#505050" stroke-width="2" stroke-miterlimit="10" width="9" height="5"/>
                                    <g>
                                      <path fill="#FFFFFF" d="M46.867,20.57c-2.05,0.46-3.84,1.66-5.23,4.27l-4.57,2.51c-0.51-0.67-1.37-1.15-2.58-1.15h-6.63
                                        c-0.97,0-2.98-2.47-4.51-2.47h-5.44c-1.57,0-3.58,1.33-4.71,2.34c-1.74,1.69-6.17,5.75-6.66,6.14c2.77,2.32,8.53,7.65,9.8,8.74
                                        c1.72-1.67,2.39-3.24,3.82-3.24h15.49c1.29,0,8.34-6.24,9.24-7.22c0.87-0.95,5.48-6.73,6.55-9.16
                                        C50.767,20.45,49.017,20.09,46.867,20.57z"/>
                                      <path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                                        M25.106,32.26h9.34c4.25,0,4.25-5.58,0-5.58h-6.64c-0.97,0-2.99-2.4-4.52-2.4h-5.46c-1.57,0-3.58,1.29-4.71,2.28
                                        c-1.75,1.64-6.18,5.58-6.68,5.96c2.77,2.25,8.55,7.42,9.82,8.48c1.73-1.62,2.4-3.15,3.83-3.15h15.52c1.3,0,8.36-6.05,9.27-7.01
                                        c0.87-0.91,5.49-6.53,6.56-8.89c-0.67-0.86-2.42-1.21-4.58-0.74c-2.06,0.45-3.85,1.61-5.24,4.15l-4.62,2.11"/>
                                    </g>
                                    <polygon fill="#FEA100" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" points="14.216,46.75 
                                      19.966,41.17 7.187,28.78 1.437,34.36 		"/>
                                    
                                      <line fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="6.316" y1="33.58" x2="8.546" y2="35.74"/>
                                    
                                      <line fill="none" stroke="#515151" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="15.986" y1="19" x2="17.687" y2="19"/>
                                    
                                      <line fill="none" stroke="#515151" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="20.376" y1="19" x2="22.077" y2="19"/>
                                    
                                      <line fill="none" stroke="#515151" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="25.617" y1="19" x2="27.316" y2="19"/>
                                  </g>
                                </g>
                              </svg>
                            </i>
                                <a [routerLink]="'/reexpedition-livraison-dom'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">
                                    <p><span>La réexpédition et la livraison </span>Délais et tarifs imbattables chez vous dans les DOM</p>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li><a href="/tarifs">Tarifs</a></li>
                    <li><a href="/voyageurs-dom">Je voyage</a></li>
                </ul>
            </div>
        </div>
        <div class="header-right">
            <ul>
                <li>
                    <div class="btn-contact">
                        <a class="call" href="tel:0650614266"><i><img src="assets/images/ic-call-header.svg" class="img-fluid" alt="Phone"></i>06 50 61 42 66</a>
                        <a class="w-call" href="https://api.whatsapp.com/send?phone=330650614266" target="_blank" rel="noopener nofollow"><i class="fa fa-caret-left"></i><i class="fa fa-whatsapp"></i></a>
                    </div>
                </li>
                <li>
                    <a href="https://app.voyey.com/login" class="btn-main">SE CONNECTER
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="40px" viewBox="40 -17.5 40 40" enable-background="new 40 -17.5 40 40" xml:space="preserve">
                      <g>
                        <g>
                          <g>
                            <path d="M50.273,19.375h-5.532c-0.668,0-1.07-0.375-1.25-0.594c-0.313-0.383-0.434-0.883-0.332-1.367
                              c1.625-7.797,8.5-13.488,16.442-13.672C59.734,3.746,59.867,3.75,60,3.75s0.27-0.004,0.402-0.008
                              c1.941,0.043,3.832,0.41,5.645,1.086c0.805,0.305,1.703-0.105,2.016-0.914c0.297-0.805-0.109-1.707-0.922-2.012
                              c-0.25-0.098-0.516-0.188-0.781-0.273c2.594-1.941,4.266-5.031,4.266-8.504C70.625-12.734,65.859-17.5,60-17.5
                              S49.375-12.734,49.375-6.875c0,3.477,1.68,6.57,4.27,8.508c-2.375,0.781-4.602,1.996-6.547,3.602
                              c-3.575,2.938-6.059,7.031-6.997,11.539c-0.293,1.414,0.059,2.867,0.965,3.977c0.902,1.109,2.242,1.75,3.676,1.75h5.532
                              c0.863,0,1.563-0.703,1.563-1.563S51.137,19.375,50.273,19.375z M52.5-6.875c0-4.137,3.363-7.5,7.5-7.5
                              c4.141,0,7.5,3.363,7.5,7.5c0,4.012-3.172,7.301-7.133,7.492C60.242,0.613,60.121,0.613,60,0.613s-0.246,0-0.367,0.004
                              C55.668,0.426,52.5-2.863,52.5-6.875z"/>
                            <path d="M78.805,9.422c-0.664-1.5-2.18-2.469-3.844-2.469h-4.867c-0.008,0-0.016,0-0.016,0c-1.758,0-3.289,1.031-3.906,2.641
                              c-0.078,0.219-0.188,0.484-0.289,0.797h-10.23c-0.422,0-0.828,0.172-1.121,0.469l-2.684,2.766
                              c-0.59,0.609-0.59,1.578,0.008,2.188l2.734,2.781c0.293,0.297,0.695,0.469,1.113,0.469h5.078c0.863,0,1.563-0.703,1.563-1.563
                              s-0.699-1.563-1.563-1.563h-4.422l-1.207-1.227l1.16-1.195h10.742c0.719,0,1.352-0.492,1.523-1.188
                              c0.125-0.516,0.281-1.016,0.508-1.609c0.148-0.398,0.531-0.641,0.992-0.641h0.008h4.867c0.445,0,0.828,0.234,1,0.625
                              c0.422,0.938,0.922,2.391,0.922,3.977c0,1.602-0.5,3.086-0.922,4.055c-0.164,0.391-0.547,0.641-0.977,0.641c0,0,0,0-0.008,0
                              h-4.922c-0.438,0-0.844-0.281-1.016-0.688c-0.18-0.453-0.336-0.953-0.484-1.547c-0.195-0.844-1.039-1.359-1.875-1.156
                              c-0.844,0.195-1.359,1.039-1.156,1.875c0.172,0.766,0.383,1.422,0.625,2.016c0.656,1.594,2.188,2.625,3.898,2.625h4.93
                              c0,0,0.008,0,0.016,0c1.664,0,3.172-0.984,3.844-2.516C79.359,18.75,80,16.82,80,14.672C80,12.531,79.344,10.641,78.805,9.422z"
                              />
                            <path d="M73.672,13.125c0.859,0,1.563,0.703,1.563,1.563s-0.703,1.563-1.563,1.563s-1.563-0.703-1.563-1.563
                              S72.813,13.125,73.672,13.125z"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </li>
                <li>
                    <a href="https://app.voyey.com/signup" class="btn-main">S’INSCRIRE<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="39.998px" height="44.626px" viewBox="40 -19.813 39.998 44.626" enable-background="new 40 -19.813 39.998 44.626" xml:space="preserve">
                      <g>
                        <g>
                          <g>
                            <path d="M68.401-0.638c0-0.963-0.775-1.743-1.743-1.743H48.708c-0.963,0-1.743,0.78-1.743,1.743c0,0.963,0.78,1.743,1.743,1.743
                              h17.951C67.626,1.105,68.401,0.325,68.401-0.638z"/>
                            <path d="M48.708,4.592c-0.963,0-1.743,0.78-1.743,1.743c0,0.959,0.78,1.743,1.743,1.743h10.904c0.963,0,1.743-0.784,1.743-1.743
                              c0-0.963-0.78-1.743-1.743-1.743H48.708z"/>
                            <path d="M52.804,21.326h-5.831c-1.922,0-3.487-1.568-3.487-3.486v-30.68c0-1.922,1.565-3.486,3.487-3.486h21.428
                              c1.926,0,3.486,1.564,3.486,3.486V-2.12c0,0.963,0.784,1.743,1.743,1.743c0.968,0,1.743-0.78,1.743-1.743V-12.84
                              c0-3.844-3.12-6.973-6.973-6.973H46.973c-3.844,0-6.973,3.129-6.973,6.973v30.68c0,3.844,3.129,6.974,6.973,6.974h5.831
                              c0.963,0,1.743-0.785,1.743-1.744C54.547,22.111,53.767,21.326,52.804,21.326z"/>
                            <path d="M78.469,5.424c-2.04-2.035-5.36-2.035-7.4,0l-9.565,9.549c-0.205,0.2-0.354,0.453-0.437,0.724l-2.087,6.868
                              c-0.183,0.61-0.022,1.272,0.422,1.726c0.332,0.34,0.785,0.523,1.247,0.523c0.157,0,0.313-0.018,0.466-0.07l7.034-1.943
                              c0.288-0.079,0.549-0.235,0.767-0.444l9.554-9.536C80.508,10.78,80.508,7.468,78.469,5.424z M66.78,19.566l-3.543,0.976
                              l1.037-3.408l6.463-6.44l2.458,2.458L66.78,19.566z M76.002,10.362l-0.331,0.331l-2.476-2.467l0.34-0.34
                              c0.68-0.68,1.787-0.68,2.467,0S76.682,9.682,76.002,10.362z"/>
                            <path d="M66.658-9.354H48.708c-0.963,0-1.743,0.78-1.743,1.744c0,0.963,0.78,1.743,1.743,1.743h17.951
                              c0.968,0,1.743-0.78,1.743-1.743C68.401-8.574,67.626-9.354,66.658-9.354z"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </li>
            </ul>
        </div>
        <div class="toggle-menu">
            <a href="javascript:void(0)" class="nav-toggle" id="trigger-overlay">
                <span class="top"></span>
                <span class="middle"></span>
                <span class="bottom"></span>
            </a>
        </div>
    </div>
</header>