import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-kit-media',
  templateUrl: './kit-media.component.html',
  styleUrls: ['./kit-media.component.scss']
})


export class KitMediaComponent implements OnInit {

  title = 'Voyey | Livraison dans les DOM de vos achats au prix métropole';
  jsonLD: SafeHtml;
  
  schema = {
    "url": "https://voyey.com/kit-media/",
    "name": "Voyey | Livraison dans les DOM de vos achats au prix métropole",
    "description": "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom,
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: "description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." },
      { name: "og:url", content: "https://voyey.com/kit-media/" },
      { name: "og:title", content: "Voyey | Livraison dans les DOM de vos achats au prix métropole" },
      { name: "og:description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." },
      { name: "twitter:title", content: "Voyey | Livraison dans les DOM de vos achats au prix métropole" },
      { name: "twitter:description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." }
    ]);
    this.setCanonicalURL('https://voyey.com/kit-media/');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

}
