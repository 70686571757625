import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  title = 'Voyey | Acheter et se faire livrer dans les DOM simple et pas cher';
  jsonLD: SafeHtml;

  schema = {
    "url":"https://voyey.com/fonctionnement",
    "name": "Voyey | Acheter et se faire livrer dans les DOM simple et pas cher",
    "description": "Voyey s'appuie sur un réseau de voyageurs aériens en partance pour les DOM pour baisser les frais de livraison outre-mer de manière drastique."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([      
      { name: "description", content: "Voyey s'appuie sur un réseau de voyageurs aériens en partance pour les DOM pour baisser les frais de livraison outre-mer de manière drastique." },
      { name: "og:url", content: "https://voyey.com/fonctionnement" },
      { name: "og:title", content: "Voyey | Acheter et se faire livrer dans les DOM simple et pas cher" },
      { name: "og:description", content: "Voyey s'appuie sur un réseau de voyageurs aériens en partance pour les DOM pour baisser les frais de livraison outre-mer de manière drastique." },
      { name: "twitter:title", content: "Voyey | Acheter et se faire livrer dans les DOM simple et pas cher" },
      { name: "twitter:description", content: "Voyey s'appuie sur un réseau de voyageurs aériens en partance pour les DOM pour baisser les frais de livraison outre-mer de manière drastique." }
    ]);
    this.setCanonicalURL('https://voyey.com/fonctionnement');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }
  
  //budgets-carousel
  budgets = [
    {
    webp:"./assets/images/Romain-voyageur-dom.webp",
    img:"./assets/images/Romain-voyageur-dom.jpg",
    alt:"portrait de Romain un voyageur vers les DOM satisfait de Voyey",
    testimonialcon:"J’ai transporté plusieurs fois des marchandises pour Voyey, à chaque fois ils ont été super arrangeants et m’ont retrouvé soit à la gare soit à l’aéroport pour me remettre les paquets. Ça permet de bien réduire le prix des billets. je recommande très sincèrement le service, toujours au top !",
    clientname:"- Romain, voyageur Voyey"},
    {
    webp:"./assets/images/damien.webp",
    img:"./assets/images/damien.jpg",
    alt:"portrait de Damien un voyageur vers les DOM satisfait de Voyey",
    testimonialcon:"Un moyen simple et efficace pour se faire un billet si vous avez de la place dans vos valises. L’équipe est très sympathique et disponible! Je recommande vivement !",
    clientname:"- Damien, voyageur Voyey"},
  ];
  budgetConfig = {
    "fade": true,
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "dots": true, 
    "nextArrow":"",
    "prevArrow":"", 
    "infinite": true  };
}