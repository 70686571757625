import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  @ViewChild('slickModal') slickModal;
  title = 'Voyey | Livraison dans les DOM de vos achats au prix métropole';
  jsonLD: SafeHtml;
  
  schema = {
    "url": "https://voyey.com/",
    "name": "Voyey | Livraison dans les DOM de vos achats au prix métropole",
    "description": "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom,
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: "description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." },
      { name: "og:url", content: "https://voyey.com/" },
      { name: "og:title", content: "Voyey | Livraison dans les DOM de vos achats au prix métropole" },
      { name: "og:description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." },
      { name: "twitter:title", content: "Voyey | Livraison dans les DOM de vos achats au prix métropole" },
      { name: "twitter:description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." }
    ]);
    this.setCanonicalURL('https://voyey.com/');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

  //slider-carousel
  slides = [
    { link: "https://www.leparisien.fr/seine-et-marne-77/champs-sur-marne-avec-voyey-les-voyageurs-livrent-vos-colis-vers-les-antilles-05-06-2019-8086716.php", img: "./assets/images/Logo-Le-Parisien.png", alt: "Article Le Parisien sur le service Voyey" },
    { link: "https://www.rci.fm/guadeloupe/infos/Informations-pratiques/Voyeycom-ou-comment-faire-venir-des-colis", img: "./assets/images/Logo-RCI.png", alt: "Article RCI sur le service Voyey" },
    { link: "https://www.guadeloupe.franceantilles.fr/actualite/economie/gagner-de-l-argent-en-voyageant-avec-voyey-com-406472.php", img: "./assets/images/Logo-France-Antilles.png", alt: "Article France-Antilles sur le service Voyey" },
    { link: "https://www.frenchweb.fr/fw-radar-voyey-veut-mettre-la-livraison-de-produits-locaux-en-mode-collaboratif/279371", img: "./assets/images/Logo-Frenchweb.png", alt: "Article Frenchweb sur le service Voyey" },
    { link: "", img: "./assets/images/Logo-Guadeloupe-premiere.png", alt: "Article Guadeloupe Première sur le service Voyey" }
  ];
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "infinite": true,
    responsive: [
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      }
    ]
  };
  //testimonials-carousel
  testimonials = [
    {
      webp: "./assets/images/Ludmila-cliente-Voyey.webp",
      img: "./assets/images/Ludmila-cliente-Voyey.png",
      alt: "portrait de Ludmila une client satisfaite du service Voyey",
      content: "Quand le cadeau que tu veux offrir à ton fils est en rupture de stock dans toute la Guadeloupe et qu’à la toute dernière minute tu penses à Voyey , ça se passe ainsi :  ",
      lists: ["mercredi matin tu les contactes ",
        "mercredi soir ton cadeau a déjà été acheté",
        "vendredi un passager ramène ton cadeau en Guadeloupe",
        "samedi le livreur de Voyey vient te le livrer à domicile ... Ah mais zut tu n es pas chez toi de la journée. Et bien aucun problème, le livreur vient te retrouver à ta réception à Anse Bertrand pour que tu ais le cadeau de ton fils dans les plus brefs délais."
      ],
      greeting: "Merci Voyey",
      clientname: "- Ludmila, cliente Voyey"
    },
    {
      webp: "./assets/images/Mikael-client-Voyey.webp",
      img: "./assets/images/Mikael-client-Voyey.png",
      alt: "portrait de Mikaël un client satisfait du service Voyey",
      content: "Voyey, c’est avant tout un prix très compétitif, on ne paie que la commande de transfert de colis sur le site plus les taxes. Cela fait une nette différence par rapport à la concurrence et représente un véritable gain en pouvoir d’achat. C’est aussi des délais de réexpédition courts allant de deux jours à une semaine dans la majorité des cas. Un autre atout majeur est la proximité et la réactivité des employés qui répondent rapidement quand vous avez une interrogation sur votre commande. Après plus de dix commandes réalisées, j’évalue mon taux de satisfaction à 95 %. Le service est fiable et je suis en attente de nouvelles améliorations qui sont déjà annoncées telles que le suivi en temps réel des colis. Cette société répond bien à une problématique importante dans les outre-mer car d’expérience certains produits sont vendus parfois du double au triple par rapport à l’hexagone. Tant que cette situation perdure , VOYE’Y an ké la pou trapé’y. Pliss foss.",
      clientname: "- Mikaël, client Voyey"
    },
  ];
  testimonialConfig = {
    "fade": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "arrows": false,
    "cssEase": 'ease-in-out',
    "infinite": true
  };
  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
  //budgets-carousel
  budgets = [
    {
      webp: "./assets/images/Romain-voyageur-dom.webp",
      img: "./assets/images/Romain-voyageur-dom.jpg",
      alt: "portrait de Romain un voyageur vers les DOM satisfait de Voyey",
      testimonialcon: "J’ai transporté plusieurs fois des marchandises pour Voyey, à chaque fois ils ont été super arrangeants et m’ont retrouvé soit à la gare soit à l’aéroport pour me remettre les paquets. Ça permet de bien réduire le prix des billets. je recommande très sincèrement le service, toujours au top !",
      clientname: "- Romain, voyageur Voyey"
    },
    {
      webp: "./assets/images/damien.webp",
      img: "./assets/images/damien.jpg",
      alt: "portrait de Damien un voyageur vers les DOM satisfait de Voyey",
      testimonialcon: "Un moyen simple et efficace pour se faire un billet si vous avez de la place dans vos valises. L’équipe est très sympathique et disponible! Je recommande vivement !",
      clientname: "- Damien, voyageur Voyey"
    },
  ];
  budgetConfig = {
    "fade": true,
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": true,
    "nextArrow": "",
    "prevArrow": "",
    "infinite": true
  };

  
}