<ngx-json-ld [json]="schema"></ngx-json-ld>
<div class="department-wrapper work-main">
    <div class="department-con section">
        <div class="container">
            <div class="row department-inner">
                <div class="col-md-7">
                    <div class="text-wrap">
                        <h1>Acheter en ligne et se faire livrer dans les DOM n’a jamais été aussi simple (et peu cher !)</h1>
                        <p>Voyey a développé une plateforme communautaire pour réduire efficacement les inégalités liées à la distance géographique. Nous nous appuyons sur un réseau de voyageurs aériens en partance pour les DOM, qui nous permet de faire
                            baisser les frais de livraison outre-mer de manière drastique. En parallèle, nous avons mis en place un dispositif ingénieux pour vous faire profiter de l’exonération de la TVA métropolitaine, exonération qui vous est due mais
                            qui n’est que rarement appliquée dans les faits. Voici comment on fonctionne.</p>
                        <a href="https://app.voyey.com" class="btn-main"><span>COMMENCER</span><i></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="img-wrap">
        <div class="right-sep section">
            <img src="assets/images/Application-Voyey-responsive.png" class="img-fluid" alt="Application voyey sur tablette et mobile">
        </div>
    </div>
</div>
<div class="work-wrapper section text-center">
    <div class="container">
        <h2>Comment fonctionne Voyey ?</h2>
        <div class="work-wrapper-raw section">
            <div class="legal-head section">
                <div class="legal-tab section">
                    <ul class="nav" role="tablist">
                        <li>
                            <a class="active" id="fonctionnement-tab1" data-toggle="tab" href="#fonctionnement1" role="tab" aria-controls="fonctionnement11" aria-selected="true" onclick="somefunction(); return false;">
                                <i>
                                    <img src="assets/images/Voyey-store-mobile.svg" class="img-fluid" alt="icône symbole boutique en ligne sur mobile">
                                </i>
                                <span>Le meilleur du shopping <br>en ligne depuis les DOM</span>
                            </a>
                        </li>
                        <li>
                            <a id="fonctionnement-tab2" data-toggle="tab" href="#fonctionnement2" role="tab" aria-controls="fonctionnement22" aria-selected="false" onclick="somefunction(); return false;">
                                <i><img src="assets/images/Voyey-transport-rapide.svg" class="img-fluid" alt="icône symbole avion qui décolle et pièce Euro jaune"></i>
                                <span>Un transport pas cher <br>et ultra rapide</span>
                            </a>
                        </li>
                        <li>
                            <a id="fonctionnement-tab3" data-toggle="tab" href="#fonctionnement3" role="tab" aria-controls="fonctionnement33" aria-selected="false" onclick="somefunction(); return false;">
                                <i><img src="assets/images/emplacement-dom.svg" class="img-fluid" alt="icône symbole monde avec localisation dom"></i>
                                <span>Un service bénéfique au <br>développement outre-mer</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="work-wrapper-col legal-body shop-wrap section">
    <div class="container">
        <div class="tab-content">
            <div class="tab-pane fade active show" id="fonctionnement1" role="tabpanel" aria-labelledby="fonctionnement-tab1">
                <div class="text-wrap">
                    <ul>
                        <li>Faites votre shopping en ligne depuis chez vous (ça marche aussi pour les boutiques !). Une fois la perle rare dénichée au meilleur prix, vous précisez, sur l’application Voyey, le lien et la description du produit, le site ou
                            la boutique où nous devons l’acheter et le prix auquel vous souhaitez l’acheter. </li>
                        <li>De notre côté, nous suivons scrupuleusement toutes vos instructions. On peut même utiliser votre code promotionnel si vous en avez un ! La grande différence avec vos services de shopping habituels, c’est qu’en tant qu’entreprise
                            exportatrice et agréé par les services douaniers, nous pouvons vous faire profiter de l’exonération de la TVA métropolitaine.</li>
                        <li>On vous fait uniquement payer les taxes d’outre-mer qui sont moins élevées que la TVA et qui contribuent au développement de votre département !</li>
                    </ul>
                </div>
            </div>
            <div class="tab-pane fade" id="fonctionnement2" role="tabpanel" aria-labelledby="fonctionnement-tab2">
                <div class="text-wrap">
                    <ul>
                        <li>Vos articles sont acheminés jusqu’en Guadeloupe, en Guyane, à La Réunion ou en Martinique par des voyageurs aériens qui s’y rendent sur vol commercial. Ils mettent à notre disposition (moyennant rémunération) l’espace non utilisé
                            de leurs bagages. De notre côté, pour garantir la sécurité des biens et des personnes, nous réalisons toutes les opérations de contrôle requises, en collaboration avec les services de sécurité et des douanes.</li>
                        <li>Dès que les contrôles sont réalisés, nous envoyons un agent Voyey à l’aéroport de départ pour confier vos colis au voyageur qui les convoie. Un autre agent Voyey l’attend à son arrivée pour les récupérer, s’occuper du dédouanement
                            et vous les remettre là où cela vous arrange, à l’heure qui vous convient !</li>
                    </ul>
                </div>
            </div>
            <div class="tab-pane fade" id="fonctionnement3" role="tabpanel" aria-labelledby="fonctionnement-tab3">
                <div class="text-wrap">
                    <ul>
                        <li>C’est Voyey qui calcule et collecte les taxes applicables à vos achats, réexpéditions et livraisons, et cela, dès votre paiement sur notre application. Nous reversons le montant des taxes collectées aux services douaniers, qui
                            les allouent ensuite au budget du Conseil régional du département où les produits sont importés.</li>
                        <li>Or, aujourd’hui, quand vous achetez en ligne, vous payez la TVA métropolitaine, laquelle est reversée aux services de l’État central, et non pas directement à nos régions ultramarines.</li>
                        <li>Avec Voyey, acheter sur Internet rime désormais avec développement de l’outre-mer !</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="why-wrapper services-wrap ptb-10 bg-gry">
    <div class="container">
        <div class="row services-main">
            <div class="col-sm-5 right-con">
                <div class="img-wrap">
                    <img src="assets/images/on-fait.svg" class="img-fluid" alt="On fait de l’optimisation de coûts">
                </div>
            </div>
            <div class="col-sm-7 left-con">
                <div class="text-wrap">
                    <h2>On fait de l’optimisation de coûts</h2>
                    <p>Voyey achète vos produits à votre place et qu’on vous les cède à prix coûtant et détaxé. Autrement dit, non seulement nous ne prenons pas de marge commerciale sur vos achats, mais, surtout, nous assurons toutes les démarches douanières
                        et fiscales pour vous exonérer du paiement de la TVA métropolitaine. Au final, vous ne payez que les taxes d’outre-mer, qui servent à financer le développement de votre région !</p>
                </div>
            </div>
        </div>
        <div class="row services-main">
            <div class="col-sm-5 right-con">
                <div class="img-wrap text-center">
                    <img src="assets/images/on-est.svg" class="img-fluid" alt="On est transparent sur les prix">
                </div>
            </div>
            <div class="col-sm-7 left-con">
                <div class="text-wrap">
                    <h2>On est transparent sur les prix</h2>
                    <p>Chez Voyey, on est partisan de la transparence totale. Voilà pourquoi, quand vous achetez un produit via notre application, vous savez exactement combien vous paierez au final. En particulier, grâce à notre calculateur révolutionnaire,
                        nous pouvons intégrer à notre prix les taxes d’outre-mer applicables, et cela, dès votre commande. Chez Voyey, il n’y a pas de coût caché à payer à la réception de votre colis. </p>
                </div>
            </div>
        </div>
        <div class="row services-main">
            <div class="col-sm-5 right-con">
                <div class="img-wrap">
                    <img src="assets/images/on-vous.svg" class="img-fluid" alt="On vous remet vos produits en mains propres">
                </div>
            </div>
            <div class="col-sm-7 left-con">
                <div class="text-wrap">
                    <h2>On vous remet vos produits en mains propres</h2>
                    <p>Une fois que vos achats sont arrivés à destination, un agent Voyey vous contacte immédiatement pour organiser avec vous la livraison, selon les modalités qui vous conviennent. Lieu, horaires – nous vous remettons votre colis là ou
                        vous le souhaitez et quand cela vous arrange. Désormais, vous êtes sûr de ne pas louper la livraison !</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="why-list ready-list ptb-10 section text-center">
    <h2>Prêt à vous lancer ?</h2>
    <ul>
        <li>
            <i><img src="assets/images/Store-voyey-yellow.svg" alt="icône téléphone mobile avec une store jaune de boutique" class="img-fluid"></i>
            <h4>Passez commande en ligne</h4>
            <p>Rendez-vous sur votre compte personnel et démarrez un commande. Là, vous avez deux options : achat ou livraison de colis. Une fois que vous avez fait votre choix, on vous demandera juste quelques précisions sur les articles à acheter ou à
                transporter (description, poids, quantité, montant).</p>
        </li>
        <li>
            <i><img src="assets/images/parametres-livraison.svg" alt="icône feuille d’inventaire avec un colis" class="img-fluid"></i>
            <h4>Précisez vos préférences de livraison</h4>
            <p>Lieu et horaires de livraison, on s’adapte à toutes vos contraintes pour vous rendre la vie plus facile ! Le paiement se fait de manière 100% sécurisée, après que vous avez validé vos modalités de livraison. Pour rappel, le prix qu’on vous
                annonce est le prix que vous payez au final. Avec Voyey, il n’y a pas de coûts cachés !</p>
        </li>
        <li>
            <i><img src="assets/images/Voyey-verification.svg" alt="icône personnage et colis avec badge de validation jaune" class="img-fluid"></i>
            <h4>Voyey s’occupe de vos articles</h4>
            <p>Nous réceptionnons et contrôlons tous les articles que nous transportons. Nous le faisons pour des questions de sécurité évidente, mais également pour reconditionner les colis afin qu’ils tiennent dans les valises de nos voyageurs.</p>
        </li>
        <li>
            <i><img src="assets/images/remise-colis-arrivee.svg" alt="icône 2 personnages lors d’une livraison de colis près d’un aéroport" class="img-fluid"></i>
            <h4>Un voyageur achemine le colis</h4>
            <p>Un premier agent Voyey retrouve le voyageur à l’aéroport et l’aide à mettre les articles dans sa valise. Un second agent l’accueille à destination pour réceptionner le colis. De votre côté, vous pouvez suivre votre livraison en temps réel.
            </p>
        </li>
        <li>
            <i><img src="assets/images/livraison-sur-mesure.svg" alt="icône d'une livraison de colis dans une maison avec un toit jaune" class="img-fluid"></i>
            <h4>Vous récupérez vos articles</h4>
            <p>La magie Voyey opère. Vous récupérez vos articles là où vous le souhaitez et au moment qui vous arrange. En plus, vous n’avez pas à gérer le dédouanement. C’est Voyey qui s’en charge !</p>
        </li>
    </ul>
    <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
</div>
<div class="budget-wrapper ptb-10 bg-gry">
    <div class="container">
        <div class="row budget-raw">
            <div class="col-md-5 budget-main">
                <div class="text-wrap">
                    <h2>En partance pour les DOM ? Et si vous augmentiez votre budget vacances sans rien faire (ou presque) ?</h2>
                    <p>Louez-nous l’espace libre de vos bagages et découvrez combien vous gagneriez en convoyant des articles pour le compte de Voyey.</p>
                    <a href="/voyageurs-dom" class="btn-main"><span>JE VOYAGE</span><i></i></a>
                </div>
            </div>
            <div class="col-md-7 quotes-main">
                <ngx-slick-carousel class="carousel budget-carousel" #slickModal="slick-carousel" [config]="budgetConfig">
                    <div ngxSlickItem *ngFor="let budget of budgets">
                        <div class="quotes-wrap">
                            <div class="quotes-avtar">
                                <picture>
                                    <source srcset="{{budget.webp}}" type="image/webp">
                                    <source srcset="{{budget.img}}" type="image/jpeg">
                                    <img src="{{budget.img}}" alt="{{budget.alt}}" class="img-fluid">
                                </picture>
                            </div>
                            <div class="quotes-text">
                                <p>{{budget.testimonialcon}}</p>
                                <h5>{{budget.clientname}}</h5>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>
</div>