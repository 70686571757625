import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';
export class Contact{
  public name: string;
  public lname:string;
  public number:number;
  public email: string;
  public company:string;
  public delivery:string;
  public euro:string;
  public destination:string;
  public recalled:string;
}
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  model = new Contact();
  
  onSubmit(form){
    console.log(form.value);
  }
  title = 'Voyey | Livraison dans les DOM de vos achats au prix métropole';
  jsonLD: SafeHtml;
  
  schema = {
    "url": "https://voyey.com/contact/",
    "name": "Voyey | Livraison dans les DOM de vos achats au prix métropole",
    "description": "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom,
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: "description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." },
      { name: "og:url", content: "https://voyey.com/contact/" },
      { name: "og:title", content: "Voyey | Livraison dans les DOM de vos achats au prix métropole" },
      { name: "og:description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." },
      { name: "twitter:title", content: "Voyey | Livraison dans les DOM de vos achats au prix métropole" },
      { name: "twitter:description", content: "Livraison de vos achats détaxés entre 1 et 5 jours en Guadeloupe, Martinique, Guyane et à La Réunion. Transport, assurance et dédouanement inclus." }
    ]);
    this.setCanonicalURL('https://voyey.com/contact/');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }
}
