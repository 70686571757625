<div class="main-wrapper section">
	<div class="trust-wrap services-wrap section">
		<div class="container">
			<div class="row services-main">
                <div class="col-sm-6 right-con">
                    <div class="img-wrap">
                        <img src="assets/images/Reexpedition-livraison-DOM.svg" class="img-fluid" alt="Des habitants des DOM qui font de la réexpédition depuis la métropole">
                    </div>
                </div>
                <div class="col-sm-6 left-con">
                    <div class="text-wrap">
                        <h2>Confiance et sécurité</h2>
                        <p>Chaque jour, des centaines d’articles sont acheminés par vol commercial entre la métropole et les départements d’outre-mer grâce à Voyey. Garantir la sécurité des voyageurs et des colis est une responsabilité que nous prenons très au sérieux.</p>
                    </div>
                </div>
            </div>
            <div class="row services-main">
                <div class="col-sm-6 right-con">
                    <div class="img-wrap">
                        <img src="assets/images/Reexpedition-DOM.svg" class="img-fluid" alt="Un utilisateur dans un DOM qui fait une demande de réexpédition via Voyey">
                    </div>
                </div>
                <div class="col-sm-6 left-con">
                    <div class="text-wrap">
                        <h2>La sécurité est notre priorité</h2>
                        <p>Voyey a le statut officiel de transitaire et de représentant en douane enregistré et, à ce titre, nous mettons tout en œuvre pour garantir la sécurité des biens et des personnes, aussi bien sur Internet que dans les airs.</p>
                    </div>
                </div>
            </div>
		</div>
		<div class="left-separator">
			<img src="assets/images/trust-sep.png" class="img-fluid" alt="seperator">
		</div>
    </div>
    <div class="bg-gry ptb-10 why-wrapper trust-main">
        <div class="container">
            <ul class="trust-inner">
                <li>
                    <div class="trust-box">
                        <div class="img-wrap">
                            <img src="assets/images/controle-produits.svg" class="img-fluid" alt="illustration colis contrôlé avec une loupe jaune">
                        </div>
                        <div class="text-wrap">
                            <h4>Contrôle des produits</h4>
                            <p>Chaque colis fait l’objet d’un double contrôle de sécurité. Les articles sont d’abord inspectés par les équipes de Voyey qui peuvent, si besoin, faire appel à un maître-chien. Puis, nous les mettons à la disposition des douanes qui en vérifient à leur tour le contenu. C’est une garantie pour tous les voyageurs !</p>
                        </div>
                    </div> 
                </li>
                <li>
                    <div class="trust-box">
                        <div class="img-wrap">
                            <img src="assets/images/prevention-fraude-colis.svg" class="img-fluid" alt="illustration colis avec une badge de validation gris">
                        </div>
                        <div class="text-wrap">
                            <h4>Prévention des fraudes</h4>
                            <p>Vous pouvez nous confier vos produits l’esprit tranquille. Avec nous, pas de risque de fraude ! Nous nous rendons en personne à l’aéroport de départ pour remettre les colis en mains propres aux voyageurs. Ces derniers sont accueillis à l’arrivée par un autre agent Voyey qui est chargé de récupérer le chargement.</p>
                        </div>
                    </div> 
                </li>
                <li>
                    <div class="trust-box">
                        <div class="img-wrap">
                            <img src="assets/images/mesure-anti-casse.svg" class="img-fluid" alt="illustration colis levé avec précautions">
                        </div>
                        <div class="text-wrap">
                            <h4>Mesures contre la casse</h4>
                            <p>Voyey n’est pas un transporteur au sens classique du terme. Nous reconditionnons chaque article que l’on nous confie non seulement pour qu’il puisse tenir dans les valises de nos voyageurs, mais également pour nous assurer qu’il arrivera intact à destination. Bien sûr, nos équipes y mettent le plus grand soin.</p>
                        </div>
                    </div> 
                </li>
                <li>
                    <div class="trust-box">
                        <div class="img-wrap">
                            <img src="assets/images/assurance-multirisques.svg" class="img-fluid" alt="illustration colis protégé par un parapluie gris">
                        </div>
                        <div class="text-wrap">
                            <h4>Assurance multirisques</h4>
                            <p>Le risque zéro n’existant pas, nous avons fait le choix de souscrire à l’assurance multirisques d’Allianz pour couvrir toutes les transactions réalisées sur notre plateforme. Un souci lors de l’achat, l’envoi ou la réexpédition ? Vous êtes remboursé intégralement jusqu'à 2000 euros sans coût supplémentaire !</p>
                        </div>
                    </div> 
                </li>
                <li>
                    <div class="trust-box">
                        <div class="img-wrap">
                            <img src="assets/images/paiement-securise.svg" class="img-fluid" alt="illustration carte bancaire et badge de vérification sur mobile">
                        </div>
                        <div class="text-wrap">
                            <h4>Paiements sécurisés</h4>
                            <p>Chez Voyey, nous sommes à la pointe de la technologie en matière de sécurité informatique et vous pouvez effectuer vos paiements sur notre plateforme avec sérénité. Nous utilisons en effet un système de cryptage et une technologie antifraude qui nous permettent de protéger en permanence vos données personnelles.</p>
                        </div>
                    </div> 
                </li>
                <li>
                    <div class="trust-box">
                        <div class="img-wrap">
                            <img src="assets/images/suivi-temps-reel.svg" class="img-fluid" alt="illustration carte gps sur mobile avec un camion et une horloge jaunes">
                        </div>
                        <div class="text-wrap">
                            <h4>Suivi en temps réel</h4>
                            <p>Impatient de recevoir vos articles ? Suivez vos colis en temps réel sur votre espace personnel ! De notre côté, nous vous informons à chaque étape de la transaction – depuis la confirmation de votre commande jusqu’à la livraison en passant par la réception de vos produits et leur remise au voyageur disponible.</p>
                        </div>
                    </div> 
                </li>
            </ul>
        </div>
	</div> 
	<div class="approval-wrap ptb-10 text-center">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<h2>Nos agréments et attestations</h2>
				</div>
			</div>
			<div class="row approval-row">
				<div class="col-sm-4 d-flex align-items-center approval-inner">
					<i>
                        <img src="assets/images/logo-douanes.svg" class="img-fluid" alt="logo des Douanes françaises personnalisé aux couleurs Voyey">
                    </i>
                    <p>Numéro d’identification douanier EORI FR85156833700014</p>
					<a class="links" href="https://www.douane.gouv.fr/demarche/enregistrer-votre-entreprise-aupres-de-la-douane-numero-eori" target="_blank" rel="noopener nofollow">EN SAVOIR PLUS</a>
				</div>
				<div class="col-sm-4 d-flex align-items-center approval-inner">
					<i>
                        <img src="assets/images/RDE-voyey.svg" class="img-fluid" alt="icône personnage avec une écharpe jaune">
                    </i>
                    <p>Agrément RDE Représentant en Douane Enregistré 5982</p>
					<a class="links" href="https://www.douane.gouv.fr/fiche/le-representant-en-douane-enregistre" target="_blank" rel="noopener nofollow">EN SAVOIR PLUS</a>
				</div>
				<div class="col-sm-4 d-flex align-items-center approval-inner">
					<i>
						<img src="assets/images/DELTAG-voyey.svg" class="img-fluid" alt="icône agrément  DELTA G avec un tampon de validation jaune">
					</i>
					<p>Agréments DELTA G ORLY FR/0337/DELTA G/2020/004</p>
					<a class="links" href="https://www.douane.gouv.fr/fiche/delta-g-un-service-en-ligne-unifie-pour-le-dedouanement" target="_blank" rel="noopener nofollow">EN SAVOIR PLUS</a>
				</div>
			</div>
			<div class="row approval-row">
				<div class="col-sm-4 d-flex align-items-center approval-inner">
					<i>
						<img src="assets/images/DELTAG-voyey.svg" class="img-fluid" alt="icône agrément  DELTA G avec un tampon de validation jaune">
					</i>
					<p>Agréments DELTA G RAIZET AERO 2020/DELTA G/FR6610/01</p>
					<a class="links" href="https://www.douane.gouv.fr/fiche/delta-g-un-service-en-ligne-unifie-pour-le-dedouanement" target="_blank" rel="noopener nofollow">EN SAVOIR PLUS</a>
				</div>
				<div class="col-sm-4 d-flex align-items-center approval-inner">
					<i>
						<img src="assets/images/ECS-voyey.svg" class="img-fluid" alt="icône écusson ECS avec un tampon de validation jaune">
					</i>
					<p>Certification ECS de sortie des marchandises de l’UE</p>
					<a class="links" href="https://www.douane.gouv.fr/demarche/sassurer-de-la-sortie-effective-des-marchandises-du-territoire-douanier-de-lunion-une" target="_blank" rel="noopener nofollow">EN SAVOIR PLUS</a>
				</div>
				<div class="col-sm-4 d-flex align-items-center approval-inner">
					<i>
						<img src="assets/images/ic-assurance.svg" class="img-fluid" alt="Nos agréments et attestations">
					</i>
					<p>Assurance marchandises transportées perte, vol, casse 61200870</p>
					<a class="links" href="https://www.allianz.fr/assurances-professionnels-entreprises/mon-activite/assurer-mes-biens/transport-des-marchandises.html" target="_blank" rel="noopener nofollow">EN SAVOIR PLUS</a>
				</div>
			</div>
		</div>
		<div class="right-sep">
			<img src="assets/images/approval-sep.png" class="img-fluid" alt="Voyey">
		</div>
	</div>
	<div class="budget-wrapper listen-wrap ptb-10 bg-gry">
		<div class="container">
			<div class="row">
				<div class="col-md-7 align-self-center">
					<div class="text-wrap">
						<h2>Nous sommes à votre écoute</h2>
						<p>Un doute, un problème, une question, un mot doux ? Nos équipes sont à votre disposition 24 heures sur 24, sept jours sur sept pour répondre à toutes vos interrogations concernant vos achats détaxés, vos livraisons, vos réexpéditions, vos voyages.</p>
						<p>N’hésitez pas à nous contacter si vous avez besoin d’aide !</p>
						<div class="btn-contact">
							<a class="call" href="tel:0650614266"><i><img src="assets/images/ic-call-header.svg" class="img-fluid" alt="Phone"></i>06 50 61 42 66</a>
							<a class="w-call" href="https://api.whatsapp.com/send?phone=06 50 61 42 66" target="_blank" rel="noopener nofollow"><i class="fa fa-caret-left"></i><i class="fa fa-whatsapp"></i></a>
						</div>
					</div>
				</div>
				<div class="col-md-5">
					<div class="img-wrap">
						<img src="assets/images/service-client-Voyey.png" class="img-fluid" alt="Le bureau Voyey avec les chargés de clientèle">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>