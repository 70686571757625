<footer>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 col-6 align-self-center text-center col1">
                    <div class="text-wrap text-center">
                        <a href="/"><img src="assets/images/logo-voyey.svg" class="img-fluid" alt="Logo Voyey gris foncé et jaune"></a>
                        <span class="footer-sm-title">RESTONS EN CONTACT!</span>
                        <p>Abonnez-vous à nos comptes</p>
                    </div>
                    <div class="socials">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/voyey97/" target="_blank" rel="noopener nofollow"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/voyey97/" target="_blank" rel="noopener nofollow"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/voyey97/" target="_blank" rel="noopener nofollow"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/voyey97" target="_blank" rel="noopener nofollow"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                    <a href="/contact" class="btn-main">CONTACTEZ-NOUS</a>
                </div>
                <div class="col-sm-3 col-6 col2">
                    <div class="footer-menu">
                        <span class="footer-sm-title">SERVICES</span>
                        <ul class="footer-nav">
                            <li><a href="/fonctionnement">Fonctionnement</a></li>
                            <li><a href="/achat-detaxe-dom">Achat détaxé</a></li>
                            <li><a href="/reexpedition-livraison-dom">Réexpédition et livraison</a></li>
                            <li><a href="/confiance-securite">Confiance et sécurité</a></li>
                            <li><a href="/tarifs">Tarifs</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-3 col-6 col3">
                    <div class="footer-menu">
                        <span class="footer-sm-title">CLIENTS</span>
                        <ul class="footer-nav">
                            <li><a href="https://voyey.com/faqs/" target="_blank">Questions fréquentes</a></li>
                            <li><a href="https://www.facebook.com/voyey97/reviews" target="_blank">Témoignages</a></li>
                            <li><a [routerLink]="'/voyageurs-dom'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                                true}">Voyageurs</a></li>
                            <li><a href="/juridique#cgvu">Conditions d’utilisation</a></li>
                            <li><a href="/juridique#rgpd">Protection des données</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-3 col-6 col4">
                    <div class="footer-menu">
                        <span class="footer-sm-title">VOYEY</span>
                        <ul class="footer-nav">
                            <li><a href="/a-propos">A propos de Voyey</a></li>
                            <li><a href="https://voyey.com/presse/" target="_blank">Presse</a></li>
                            <li><a href="https://voyey.com/blog/" target="_blank">Blog</a></li>
                            <li><a href="/kit-media">Kit média</a></li>
                            <li><a href="/juridique#mentions-legales">Mentions légales</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom text-center">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 align-self-center">
                    <div class="row">
                        <div class="col-sm-12 col5">
                            <a href="https://stripe.com/fr" target="_blank" class="stripe" rel="noopener nofollow"><img src="assets/images/logo-stripe.png" class="img-fluid" alt="logo Stripe personnalisé aux couleurs Voyey"></a>
                            <ul class="payment-icons">
                                <li><img src="assets/images/logo-mastercard.png" class="img-fluid" alt="logo Mastercard personnalisé aux couleurs Voyey"></li>
                                <li><img src="assets/images/logo-CB.png" class="img-fluid" alt="logo CB personnalisé aux couleurs Voyey"></li>
                                <li><img src="assets/images/logo-visa.png" class="img-fluid" alt="logo VISA personnalisé aux couleurs Voyey"></li>
                            </ul>
                            <span class="footer-sm-title">Paiement 100% sécurisé</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-9 align-self-center footerbtm-right">
                    <div class="row">
                        <div class="col w-50 ft-img1">
                            <i>
                                <img src="assets/images/logo-douanes.svg" class="img-fluid" alt="logo des Douanes françaises personnalisé aux couleurs Voyey">
                            </i>
                            <p>Numéro d’identification douanier EORI FR85156833700014</p>
                        </div>
                        <div class="col w-50 ft-img2">
                            <i>
                                <img src="assets/images/RDE-voyey.svg" class="img-fluid" alt="icône personnage avec une écharpe jaune">
                            </i>
                            <p>Agrément RDE Représentant en Douane Enregistré 5982</p>
                        </div>
                        <div class="col w-50 ft-img3">
                            <i>
                                <img src="assets/images/DELTAG-voyey.svg" class="img-fluid" alt="icône agrément  DELTA G avec un tampon de validation jaune">
                            </i>
                            <p>Agréments DELTA G export métropole et import DOM</p>
                        </div>
                        <div class="col w-50 ft-img4">
                            <i>
                                <img src="assets/images/ECS-voyey.svg" class="img-fluid" alt="icône écusson ECS avec un tampon de validation jaune">
                            </i>
                            <p>Certification ECS de sortie des marchandises de l’UE</p>
                        </div>
                        <div class="col w-50 ft-img5">
                            <i>
                                <img src="assets/images/logo-allianz.png" class="img-fluid" alt="logo de l’assurance Allianz personnalisé aux couleurs Voyey">
                            </i>
                            <p>Assurance marchandises transportées perte, vol, casse 61200870</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyrights text-center">
        <div class="container">
            <p>© Copyright <span id="year"></span> Voyey.com SAS. Tous droits réservés. Les différentes marques commerciales appartiennent à leurs propriétaires respectifs.</p>
        </div>
    </div>
</footer>