import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { TrustComponent } from './trust/trust.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { RedirectionComponent } from './redirection/redirection.component';
import { TravellerComponent } from './traveller/traveller.component';
import { AboutComponent } from './about/about.component';
import {FormsModule} from '@angular/forms';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component'
import { TarifsComponent } from './tarifs/tarifs.component';
import { KitMediaComponent } from './kit-media/kit-media.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    HowItWorksComponent,
    TrustComponent,
    PurchaseComponent,
    RedirectionComponent,
    TravellerComponent,
    AboutComponent,
    MentionsLegalesComponent,
    TarifsComponent,
    KitMediaComponent,
    ContactUsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    RouterModule,
    FormsModule,
    SlickCarouselModule,
    NgxJsonLdModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
