import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {

  title = 'Voyey | Achats détaxés livrés dans les DOM aux prix métropole';
  jsonLD: SafeHtml;

  schema = {
    "url": "https://voyey.com/achat-detaxe-dom",
    "name": "Voyey | Achats détaxés livrés dans les DOM aux prix métropole",
    "description": "Les ultramarins malins utilisent Voyey pour acheter en métropole sans payer la TVA ! Livraison en main propre de vos achats détaxés entre 1 et 5 jours."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([      
      { name: "description", content: "Les ultramarins malins utilisent Voyey pour acheter en métropole sans payer la TVA ! Livraison en main propre de vos achats détaxés entre 1 et 5 jours." },
      { name: "og:url", content: "https://voyey.com/achat-detaxe-dom" },
      { name: "og:title", content: "Voyey | Achats détaxés livrés dans les DOM aux prix métropole" },
      { name: "og:description", content: "Les ultramarins malins utilisent Voyey pour acheter en métropole sans payer la TVA ! Livraison en main propre de vos achats détaxés entre 1 et 5 jours." },
      { name: "twitter:title", content: "Voyey | Achats détaxés livrés dans les DOM aux prix métropole" },
      { name: "twitter:description", content: "Les ultramarins malins utilisent Voyey pour acheter en métropole sans payer la TVA ! Livraison en main propre de vos achats détaxés entre 1 et 5 jours." }
    ]);
    this.setCanonicalURL('https://voyey.com/achat-detaxe-dom');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }
}