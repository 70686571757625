<ngx-json-ld [json]="schema"></ngx-json-ld>
<div class="department-wrapper">
    <div class="department-con section">
        <div class="container">
            <div class="row department-inner">
                <div class="col-md-7">
                    <div class="text-wrap">
                        <h1>Livraison dans les DOM de vos achats au prix métropole</h1>
                        <ul class="con-list">
                            <li>Recevez en main propre vos achats détaxés entre 1 et 5 jours. </li>
                            <li>Transport, assurance et dédouanement inclus.</li>
                            <li>Valable en Guadeloupe, en Martinique, en Guyane et à la Réunion.</li>
                        </ul>
                        <a href="https://app.voyey.com/" class="btn-main"><span>JE COMMENCE</span><i></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="img-wrap">
        <div class="right-sep section">
            <picture>
                <source srcset="assets/images/Process-Voyey.webp" type="image/webp">
                <source srcset="assets/images/Process-Voyey.png" type="image/jpeg">
                <img src="assets/images/Process-Voyey.png" class="img-fluid" alt="Avion entre Paris et une île qui passe au travers du symbole Voyey">
            </picture>
        </div>
    </div>
</div>
<div class="vat-wrapper ptb-10">
    <div class="container">
        <div class="row department-inner">
            <div class="col-sm-7 padt0 right-con align-self-center">
                <div class="text-wrap">
                    <h2>Achetez vos produits en métropole sans payer la TVA</h2>
                    <p>Pour cela, il vous suffit de nous confier votre shopping. Nous achetons les produits en ligne ou en magasin et, en tant qu’entreprise exportatrice et agréé par les services douaniers, nous pouvons vous faire profiter (en toute légalité!)
                        de l’exonération de la TVA métropolitaine. Vous n’avez plus qu’à payer les taxes d’outre-mer. Voyey supprime le cumul de taxes, si bien que vous payez vos produits moins cher, tout en contribuant au développement du département
                        dans lequel vous vivez. C’est gagnant-gagnant pour tout le monde, et surtout pour vous !</p>
                    <a href="/achat-detaxe-dom" class="links inline-link">EN SAVOIR PLUS SUR LES ACHATS DÉTAXÉS <span></span></a>
                </div>
            </div>
            <div class="col-sm-5 left-con">
                <div class="img-wrap">
                    <picture>
                        <source srcset="assets/images/Achats-detaxes.webp" type="image/webp">
                        <source srcset="assets/images/Achats-detaxes.png" type="image/jpeg">
                        <img src="assets/images/Achats-detaxes.png" class="img-fluid" alt="Une montage de colis provenant de différents sites marchands au milieu du symbole O de Voyey">
                    </picture>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="parcel-wrapper section text-center">
    <div class="container">
        <div class="text-wrap section">
            <h2>Faites réexpédier et livrer vos colis dans les DOM</h2>
            <p>Voyey assure aussi la réexpédition ou la livraison de vos achats et effets personnels dans tous les départements d’outre-mer. Nos prix sont imbattables et sans coûts cachés ; notre livraison est ultra-rapide (entre 1 et 5 jours) ; et nos services
                de réexpédition, à savoir la mise à disposition d’une adresse postale en métropole, la réception de vos colis, leur reconditionnement et leur stockage, sont gratuits !</p>
            <ul>
                <li>
                    <a class="boxes-wrap guadeloupe-box">
                        <i>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="78.904px" height="67.05px" viewBox="0 0 78.904 67.05" enable-background="new 0 0 78.904 67.05" xml:space="preserve">
								<g>
									<g>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M64.754,29.17c2.37-4.13,6.061-7.11,9.49-8.08c-2.46-2.11-7.88-0.87-11.24,2.44"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M70.444,27.43c2.439,0.44,6.77,1.74,7.46,6.649c-1.82-1.64-6.12-2.86-9.15-1.87"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M61.374,34.259c-1.649,3.551-2.359,7.631-2.359,11.771c-2.44-2.25-3.95-4.871-3.631-8.041"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M74.814,66.05c1.52-14.92-3.22-29.36-13.061-35.3"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M63.714,36.97c8.42,8.57,4.03,29.08,4.03,29.08"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M57.684,28.73c-5.601-0.17-10.92,5.32-9.561,10.609c2.54-2.899,6.95-6.059,10.3-6.52"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M61.374,27.96c-0.99-6.89-8.27-7.55-12.37-4.05c3.03,0.63,5.36,1.65,6,2.77"/>
									</g>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M4.834,58.68c2.57,0,5.141-3.101,7.721-3.101c2.569,0,5.14,3.101,7.71,3.101c2.58,0,5.149-3.101,7.72-3.101s5.14,3.101,7.72,3.101
										c2.57,0,5.141-3.101,7.71-3.101c2.57,0,5.15,3.101,7.72,3.101"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M50.834,58.68c2.57,0,5.141-3.101,7.721-3.101"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M50.834,65.68c2.57,0,5.141-3.101,7.721-3.101"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M4.834,65.99c2.57,0.01,5.141-3.1,7.721-3.1c2.569,0,5.14,3.109,7.71,3.1c2.58,0.01,5.149-3.1,7.72-3.1s5.14,3.109,7.72,3.1
										c2.57,0.01,5.141-3.1,7.71-3.1c2.57,0,5.15,3.109,7.72,3.1"/>
									<path class="animate-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M57.814,1c-3.16,0-6,2.39-6,5c0-2.61-2.84-5-6-5"/>
									<path class="animate-fly2" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M65.824,8c-2.33,0-4,1.08-4,3c0-1.92-1.68-3-4.01-3"/>
									<g>
										<path fill="#FEA100" d="M42.064,14.85c-1.11-3.76-4.51-5.48-7.53-5.48c-2.55,0-5.32,2.22-6.47,4.21l7.25,6.93L42.064,14.85z"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M28.519,13.14c0.811-2.69,3.215-4.63,6.295-4.63c3.72,0,6.74,3.218,6.74,6.768"/>
									</g>
									<polyline fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="
									10.514,16.6 21.214,5.78 35.924,20.6     "/>
								
									<line fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="17.514" y1="16.6" x2="21.214" y2="12.78"/>
								
									<line fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="2.514" y1="23.6" x2="6.214" y2="19.78"/>
								
									<polyline fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="
									52.464,17.41 46.264,11.14 36.414,21.05  "/>
								
									<polyline fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="
									1.414,18.41 6.604,13.14 22.464,29.05    "/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M35.804,28.54"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M35.804,20.54"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M40.804,17.54"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M35.804,51.541"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M35.804,41.541"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M35.804,37.541"/>
									<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M35.804,32.54"/>
								</g>
								<g>
									<g>
										<line fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" x1="40.804" y1="17.54" x2="40.804" y2="20.54"/>
										<line class="dash-speed-to" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="5.6001,5.6001" x1="40.804" y1="26.14" x2="40.804" y2="45.741"/>
										<line class="dash-speed-to" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" x1="40.804" y1="48.541" x2="40.804" y2="51.541"/>
									</g>
								</g>
								<g>
									<g>
										<line fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" x1="30.804" y1="16.54" x2="30.804" y2="19.54"/>
										<line class="dash-speed-to" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="5.0001,5.0001" x1="30.804" y1="24.54" x2="30.804" y2="42.041"/>
										<line class="dash-speed-to" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" x1="30.804" y1="44.541" x2="30.804" y2="47.541"/>
									</g>
								</g>
								<g>
									<g>
										<line fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" x1="35.924" y1="20.6" x2="35.924" y2="23.6"/>
										<line class="dash-speed-to" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-dasharray="4.9479,4.9479" x1="35.924" y1="28.548" x2="35.924" y2="45.866"/>
										<line class="dash-speed-to" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" x1="35.924" y1="48.339" x2="35.924" y2="51.339"/>
									</g>
								</g>
							</svg>
						</i>
                        <h3>GUADELOUPE</h3>
                    </a>
                </li>
                <li>
                    <a class="boxes-wrap martinique-box">
                        <i>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="70.866px" height="58.66px" viewBox="0 0 70.866 58.66" enable-background="new 0 0 70.866 58.66" xml:space="preserve">
								<g>
									<path fill-rule="evenodd" clip-rule="evenodd" fill="#505050" d="M56.453,33.81l-6.69-3.02l1.42-0.89l7.7,1.4
										C58.153,32.26,57.343,33.09,56.453,33.81z M48.232,31.73l0.09-0.06l6.81,3.08c-0.81,0.51-1.68,0.94-2.6,1.29
										C51.553,34.48,50.153,32.82,48.232,31.73z M39.263,43.19c-0.4-1.18-0.94-2.27-1.62-3.23c0.26-0.28,0.49-0.59,0.69-0.93
										c1.49,0.34,3.82,1.06,4.65,2.13c0.14,0.17,0.34,0.26,0.54,0.26c0.15,0,0.31-0.05,0.43-0.15c0.3-0.24,0.35-0.69,0.11-0.99
										c-1.1-1.4-3.6-2.19-5.15-2.56c0.14-0.45,0.22-0.92,0.25-1.41c2.6-0.08,6.17,0.17,7.17,1.19c0.14,0.13,0.31,0.2,0.49,0.2
										c0.18,0,0.35-0.07,0.49-0.2c0.27-0.28,0.27-0.72,0-1c-1.19-1.21-4.07-1.54-6.33-1.6c0.95-0.55,2.19-1.37,3.46-2.48
										c5.1,0.61,7.47,6.13,8.06,7.75C47.143,46.88,40.703,43.97,39.263,43.19z M48.993,48.31l-6.59-0.75l-1.39-1.68
										c0.73,0.2,1.56,0.37,2.46,0.44c0.29,0.02,0.59,0.03,0.9,0.03c1.13,0,2.4-0.17,3.73-0.64C48.343,46.3,48.672,47.2,48.993,48.31z
											M44.223,51.83v0.02l0.54,3.92c-0.7-0.28-1.39-0.62-2.05-1.07l-0.04-4.65C43.172,50.63,43.692,51.23,44.223,51.83z M49.783,52.32
										l-4.27-1.13c-0.61-0.69-1.22-1.39-1.79-2.07l5.63,0.64C49.533,50.56,49.683,51.42,49.783,52.32z M47.263,53.1l2.62,0.69
										c0.01,0.61,0,1.23-0.06,1.84C49.053,54.95,48.172,54.07,47.263,53.1z M46.223,56.25l-0.36-2.6c1.09,1.17,2.17,2.26,3.1,3.08
										C48.223,56.66,47.263,56.53,46.223,56.25z M39.743,51c0.21-1.01,0.31-2.1,0.28-3.24c0-0.32-0.02-0.64-0.04-0.95l1.3,1.58l0.04,5.13
										C40.672,52.84,40.123,52.01,39.743,51z M36.583,53.87c-0.95,1.22-2.13,1.51-3.52,0.87c-5.32-2.47-4.25-9.19-4.2-9.48l0.02-0.14
										c0.88-1.84,1.58-3.37,1.76-3.76l0.04-0.12c0.36,0.2,0.75,0.35,1.16,0.46c-0.49,1.68-1.3,5.21-0.55,7.98
										c0.09,0.31,0.36,0.52,0.67,0.52c0.06,0,0.12-0.01,0.18-0.03c0.37-0.1,0.59-0.49,0.48-0.86c-0.68-2.53,0.17-5.96,0.6-7.41h0.12
										c0.19,0,0.38-0.01,0.56-0.03c0.39,0.74,0.98,2.27,0.9,4.68c-0.01,0.39,0.29,0.71,0.67,0.72h0.02c0.38,0,0.68-0.3,0.69-0.68
										c0.08-2.37-0.44-4.04-0.89-5.03c0.32-0.12,0.62-0.26,0.91-0.43C38.903,45.04,38.663,51.2,36.583,53.87z M23.133,51.97
										c-0.44,0.49-1.29,0.78-2.22,0.75c-0.98-0.03-2.92-0.42-4.84-2.79c-0.41-0.5-0.57-1.08-0.5-1.77c0.29-2.63,3.9-6.01,5.36-7.18
										c2.76-0.33,4.92-0.83,7.01-1.63c-1.04,1.41-3.11,3.91-5.15,4.67c-0.36,0.13-0.54,0.54-0.41,0.9c0.1,0.29,0.37,0.46,0.65,0.46
										c0.08,0,0.16-0.01,0.24-0.04c2.44-0.91,4.77-3.77,5.87-5.26l0.12,0.12c-0.12,0.1-0.23,0.22-0.29,0.37
										C27.203,44.43,24.063,50.94,23.133,51.97z M2.042,43.86c0.85-1.55,3.55-5.93,7.85-8.01c0.28,1.8,1.53,3.33,3.47,4.17
										c0.43,0.19,0.87,0.36,1.31,0.5c-1.2,0.11-2.59,0.28-4.09,0.54l-2.69-2.28c-0.29-0.25-0.73-0.21-0.97,0.09
										c-0.24,0.29-0.21,0.74,0.09,0.99l1.81,1.54c-1.68,0.36-3.46,0.83-5.25,1.46c-0.36,0.12-0.55,0.52-0.43,0.89
										c0.1,0.29,0.37,0.47,0.65,0.47c0.08,0,0.15-0.01,0.23-0.03c1.56-0.55,3.12-0.97,4.62-1.31l-1.67,2.09c-0.24,0.3-0.19,0.74,0.1,0.99
										c0.13,0.1,0.29,0.15,0.44,0.15c0.2,0,0.4-0.08,0.54-0.26l2.71-3.38c0.92-0.16,1.8-0.29,2.61-0.39l-2.08,3.66
										c-0.19,0.33-0.08,0.76,0.25,0.96c0.11,0.06,0.23,0.09,0.35,0.09c0.24,0,0.47-0.12,0.6-0.35l2.58-4.53
										c0.89-0.08,1.66-0.13,2.28-0.15c-1.3,1.36-2.73,3.16-3.34,4.99C12.123,46.98,5.623,47.45,2.042,43.86z M12.482,32.78
										c0.88-1.18,1.66-1.84,2.46-2.09c1.86-0.58,3.96,0.05,5.66,0.55c1.5,0.45,2.65,0.89,4.02,1.54c0.21,0.16,0.43,0.34,0.64,0.54
										c-1.58-0.27-3.23-0.39-3.99-0.43c-0.38-0.03-0.71,0.27-0.73,0.66c-0.02,0.38,0.27,0.72,0.65,0.74c1.99,0.12,4.23,0.42,5.56,0.78
										l0.04,0.06c0.11,0.18,0.26,0.31,0.44,0.39c-2.45,0.49-6.87,1.03-8.93-0.27c-0.32-0.21-0.75-0.11-0.95,0.22
										c-0.2,0.33-0.1,0.76,0.22,0.97c1.23,0.78,2.98,1.03,4.72,1.03c2.06,0,4.1-0.35,5.28-0.59c0.03,0.19,0.07,0.38,0.12,0.57
										c-2.36,0.95-4.7,1.46-8.1,1.79c-1.83,0.17-3.68-0.14-5.51-0.94c-0.73-0.32-2.43-1.27-2.41-3.26
										C11.672,34.27,11.953,33.48,12.482,32.78z M16.982,24.53c-0.45-0.64-0.61-1.75-0.41-2.77c0.16-0.79,0.53-1.42,1.02-1.72
										c1.8-1.11,4.83,0.04,7.06,1.49c1.73,1.11,3.23,2.87,4.34,5.09c0.68,1.36,1.1,2.54,1.28,3.62c0.04,0.21,0.13,0.38,0.27,0.52
										c-0.24,0.13-0.47,0.28-0.68,0.44c-1.93-3.53-5.8-6.02-5.98-6.13c-0.33-0.21-0.75-0.11-0.96,0.22c-0.2,0.33-0.1,0.76,0.22,0.97
										c0.05,0.03,4,2.58,5.68,5.95c-0.33,0.42-0.61,0.88-0.83,1.37c-0.66-0.93-1.47-1.67-2.19-2.25c-0.06-0.06-0.12-0.11-0.19-0.15
										l-0.13-0.1c-0.95-0.74-1.96-1.41-2.93-2.05c-1.21-0.81-2.36-1.57-3.41-2.46l-0.37-0.3C18.113,25.72,17.422,25.15,16.982,24.53z
											M33.343,40.03c-2.2,0-4-1.83-4-4.07c0-2.23,1.8-4.06,4-4.06c2.2,0,3.99,1.83,3.99,4.06C37.333,38.2,35.542,40.03,33.343,40.03z
											M30.223,14.95c1.25-0.51,2.83-0.55,4.03-0.11c2.75,1.01,3.13,4.4,3.27,7.81c-0.39,0.82-0.68,1.65-0.88,2.49
										c-0.13,0.5,0.18,1.01,0.67,1.13c0.49,0.12,0.99-0.18,1.12-0.69c0.34-1.42,1.01-2.86,2-4.27c0.15-0.22,0.31-0.46,0.46-0.69
										c0.55-0.87,1.13-1.76,1.92-2.08c1.61-0.65,4.2,0.65,4.96,2.11c0.68,1.29,0.62,1.81,0.6,1.9c-1.45,7.11-8.13,10.7-9.55,11.39
										c-0.1-0.27-0.22-0.54-0.35-0.79c2.55-1.18,4.74-3.67,4.85-3.79c0.25-0.29,0.23-0.73-0.06-0.99c-0.28-0.26-0.72-0.23-0.97,0.06
										c-0.03,0.03-2.26,2.56-4.64,3.55c-0.2-0.23-0.42-0.44-0.66-0.63c3.09-2.15,6.35-7.01,6.51-7.24c0.21-0.32,0.13-0.76-0.19-0.98
										c-0.31-0.21-0.74-0.13-0.96,0.19c-1.08,1.62-4.24,5.83-6.67,7.21c-0.51-0.23-1.06-0.38-1.63-0.45c0-3.23-0.72-8.47-0.75-8.72
										c-0.05-0.38-0.4-0.65-0.78-0.6c-0.38,0.06-0.64,0.41-0.59,0.8c0.01,0.05,0.74,5.38,0.74,8.51c-0.2,0.02-0.39,0.06-0.57,0.1
										c0-0.08,0-0.16-0.01-0.24c-0.21-1.27-0.69-2.63-1.46-4.16c-0.62-1.24-1.34-2.35-2.15-3.3C28.243,21.15,27.502,16.07,30.223,14.95z
											M61.823,20.49c-1.24,1.22-3.27,2.78-5.53,4.36l0.08-3.74C58.053,20.72,59.913,20.45,61.823,20.49z M51.413,22.76
										c0.91-0.39,2.14-0.87,3.57-1.28l-0.09,4.34c-1.01,0.68-2.05,1.36-3.06,2.01L51.413,22.76z M46.692,31.02
										c-0.21-0.07-0.41-0.13-0.62-0.19c1.71-1.88,3.27-4.33,4-7.43l0.45,5.27C49.113,29.56,47.792,30.37,46.692,31.02z M59.772,30.04
										l-6.85-1.25c0.85-0.55,1.74-1.14,2.64-1.75l5.52,0.57C60.683,28.48,60.243,29.29,59.772,30.04z M61.643,26.25l-4.3-0.44
										c2.24-1.58,4.33-3.19,5.62-4.5C62.763,22.53,62.373,24.32,61.643,26.25z M64.223,18.83c-5.85-0.93-11.7,1.34-14.17,2.48
										c-0.14-0.46-0.36-0.98-0.65-1.54c-1.16-2.22-4.66-4.03-7.28-2.96c-1.32,0.53-2.09,1.72-2.77,2.78l-0.18,0.28
										c-0.34-2.66-1.29-5.69-4.3-6.79c-1.62-0.6-3.66-0.55-5.33,0.14c-2.89,1.18-3.29,4.67-3.15,7.25c-0.24-0.19-0.49-0.36-0.75-0.53
										c-1.68-1.08-5.91-3.4-9-1.51c-0.94,0.58-1.61,1.63-1.87,2.96c-0.32,1.54-0.04,3.16,0.72,4.23c0.58,0.83,1.4,1.5,2.12,2.1l0.35,0.3
										c0.36,0.3,0.74,0.59,1.11,0.87c-1.46-0.33-3.07-0.49-4.66,0c-1.57,0.49-2.69,1.81-3.4,2.76c-0.49,0.65-0.83,1.37-1.02,2.11
										c-6.42,2.56-9.76,9.54-9.9,9.85c-0.16,0.33-0.11,0.73,0.13,1.01c2.96,3.49,7.79,4.17,11.09,4.17c0.96,0,1.79-0.06,2.41-0.12
										c0.03,0.92,0.35,1.74,0.93,2.46c2.39,2.93,4.92,3.43,6.22,3.46h0.16c1.42,0,2.67-0.49,3.46-1.35c0.55-0.62,1.53-2.39,2.55-4.39
										c0.03,0.2,0.06,0.41,0.1,0.62c0.61,3.28,2.4,5.69,5.16,6.97c0.72,0.34,1.44,0.5,2.12,0.5c1.37,0,2.62-0.65,3.6-1.91
										c0.35-0.45,0.66-0.97,0.92-1.53c1.35,2.07,3.5,3.59,6.33,4.44c2.21,0.66,4.19,0.72,4.9,0.72h0.28c0.41-0.02,0.76-0.31,0.86-0.71
										c1.27-5.29-0.68-10.94-1.52-13c1.5-0.83,3.03-2.11,4.47-4.03c0.18-0.24,0.24-0.54,0.15-0.83c-0.03-0.12-0.32-1.11-0.96-2.41
										c4.33-1.71,7.67-5.23,9.7-10.23c1.62-3.99,1.84-7.5,1.85-7.64C65.022,19.32,64.692,18.9,64.223,18.83z" />
										<path fill-rule="evenodd" clip-rule="evenodd" fill="#FEA100" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" d="
										M36.083,39.07c-3.57,2.36-11.87,0.55-3.17-9.4c7.67-8,9-11.34,9.75-23.67C42.663,6,42.792,34.62,36.083,39.07z" />
										<circle class="blinks" fill="#505050" cx="46.913" cy="6.5" r="1.5"/>
										<circle class="blinks1" fill="#505050" cx="44.913" cy="1.5" r="1.5"/>
										<circle class="blinks" fill="#505050" cx="39.913" cy="2.5" r="1.5"/>
										<circle class="blinks1" fill="#505050" cx="37.913" cy="7.5" r="1.5"/>
										<circle class="blinks" fill="#505050" cx="45.913" cy="11.5" r="1.5"/>
										<g>
											<line class="blinks" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="17.743" y1="12.98" x2="17.743" y2="7.98"/>
										
											<line class="blinks1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="14.172" y1="13.62" x2="11.792" y2="9.5"/>
										
											<line class="blinks" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="11.482" y1="16.31" x2="7.363" y2="13.93"/>
										
											<line class="blinks1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="10.732" y1="19.98" x2="5.732" y2="19.98"/>
										
											<line class="blinks" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="21.513" y1="13.62" x2="23.903" y2="9.5"/>
										</g>
										<path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M59.413,39c0,0,7.83-3,9.81-14.19"/>
										<path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="
										M69.723,22.19c0,0,0.5-1.82-0.37-2.94"/>
								</g>
							</svg>	
						</i>
                        <h3>MARTINIQUE</h3>
                    </a>
                </li>
                <li>
                    <a class="boxes-wrap guyane-box">
                        <i>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="74.5px" height="74.88px" viewBox="0 0 74.5 74.88" enable-background="new 0 0 74.5 74.88" xml:space="preserve">
								<g>
									<path fill-rule="evenodd" clip-rule="evenodd" fill="#FEA100" d="M21.5,27.38c0,0,3-10.25,9-14c0,0,7.75,6,9,14H21.5z"/>
									<g>
										<path class="cloud-fly2" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M26,16.36h-3.95c-0.66-1-2.03-2.05-3.67-1.85c-0.72-1.23-2.04-2.16-3.56-2.16c-2.27,0-4.11,2.01-4.13,4.01H1"/>
										<path class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M54.58,5.38h-1.97c-0.7-2-2.13-2.61-3.85-2.4C48.01,1.69,46.62,1,45.02,1c-2.38,0-4.31,1.38-4.33,4.38h-5.12"/>
										<line class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="58.58" y1="10.26" x2="50.58" y2="10.26"/>
										<line class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="46.58" y1="10.26" x2="44.58" y2="10.26"/>
										<line class="cloud-fly2" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="14.58" y1="21.26" x2="9.58" y2="21.26"/>
										<polyline fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="
											56.5,73.38 59.5,14.38 70.5,14.38 73.5,73.38         "/>
										<circle fill-rule="evenodd" clip-rule="evenodd" fill="#FEA100" cx="65" cy="5.88" r="3"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M61.5,13.63V4.88c0-1.38,2.12-2.5,3.5-2.5s3.5,1.12,3.5,2.5v8.75"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M26,59.38l1,2.75c0.474,1.119,2.12,2.5,3.5,2.5s2.349-0.79,3.5-2.5l1-2.75"/>
										<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="62" y1="7.88" x2="67" y2="7.88"/>
										<path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M21.5,59.38v-30c0-3.31,1.7-8.08,3.8-10.64l5.2-6.36l5.2,6.36c2.1,2.56,3.8,7.33,3.8,10.64v30H21.5z"/>
										<path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M43.5,67.88v-27v-6l3.08,3.7c1.06,1.27,1.92,3.64,1.92,5.3v23"/>
										<path fill="none" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M2.2,72.75c0.05-0.16,0.1-0.33,0.17-0.49c0.89-2.22,3.34-3.35,5.6-2.68c1.09-1.35,2.76-2.21,4.64-2.21c2.43,0,4.52,1.44,5.45,3.51
											c0.88-0.3,1.87-0.3,2.81,0.07c1.06,0.42,1.84,1.25,2.24,2.22"/>
										<path fill="none" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M23.2,72.75c0.05-0.16,0.1-0.33,0.17-0.49c0.89-2.22,3.34-3.35,5.6-2.68c1.09-1.35,2.76-2.21,4.64-2.21
											c2.43,0,4.52,1.44,5.45,3.51c0.88-0.3,1.87-0.3,2.81,0.07c1.06,0.42,1.84,1.25,2.24,2.22"/>
										<path fill="none" stroke="#515151" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
											M40.97,70.58c1.09-1.35,3.76-3.21,5.64-3.21c2.43,0,4.52,1.44,5.45,3.51c0.88-0.3,1.87-0.3,2.81,0.07
											c1.06,0.42,1.84,1.25,2.24,2.22"/>
										<path fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
										M17.5,69.88v-29v-6l-3.08,3.7c-1.06,1.27-1.92,3.64-1.92,5.3v23"/>
										<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#515151" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="1" y1="73.88" x2="73.5" y2="73.88"/>
										<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="22" y1="27.88" x2="38" y2="27.88"/>
										<circle fill-rule="evenodd" clip-rule="evenodd" fill="#FEA100" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="30.5" cy="38.38" r="4"/>
										<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="18" y1="39.88" x2="21" y2="36.88"/>
										<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="59" y1="46.88" x2="70" y2="35.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="59" y1="34.88" x2="70" y2="24.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="60" y1="23.88" x2="70" y2="14.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="58" y1="59.88" x2="71" y2="46.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="58" y1="72.88" x2="72" y2="59.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="71" y1="46.88" x2="59" y2="35.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="70" y1="34.88" x2="60" y2="24.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="70" y1="23.88" x2="60" y2="14.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="72" y1="59.88" x2="59" y2="48.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="71" y1="72.88" x2="59" y2="60.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="43" y1="39.88" x2="40" y2="36.88"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="18" y1="59.38" x2="43" y2="59.38"/>
									<line fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="40" y1="27.88" x2="58" y2="27.88"/>
								</g>
								</g>
							</svg>
						</i>
                        <h3>GUYANE</h3>
                    </a>
                </li>
                <li>
                    <a class="boxes-wrap lareunion-box">
                        <i>
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="79.1px" height="58.379px" viewBox="0 0 79.1 58.379" enable-background="new 0 0 79.1 58.379" xml:space="preserve">
								<g>
									<g>
										<path class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M49.8,30.26c0.89-2.22,3.34-3.35,5.6-2.68c1.091-1.35,2.761-2.21,4.641-2.21c2.43,0,4.52,1.44,5.45,3.51c0.88-0.3,1.869-0.3,2.81,0.07c1.06,0.42,1.84,1.25,2.24,2.22c0.51-0.101,1.05-0.07,1.569,0.14c1.12,0.44,1.601,2.659,3.261,2.659"/>
										<line class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" x1="76.71" y1="34.379" x2="54.23" y2="34.379"/>
										<path class="cloud-fly" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M20.01,13.38h-1.97c-0.7-2-2.13-2.61-3.851-2.4C13.44,9.69,12.05,9,10.45,9C8.07,9,6.14,10.38,6.12,13.38H1"/>
										<path class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M45,5.38h1.979c0.69-2,2.13-2.61,3.851-2.4C51.58,1.69,52.96,1,54.56,1c2.38,0,4.311,1.38,4.33,4.38h5.12"/>
										<line class="cloud-fly" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="21.01" y1="18.26" x2="13.01" y2="18.26"/>
										<line class="cloud-fly" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="9.01" y1="18.26" x2="7.01" y2="18.26"/>
										<polyline class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="57.78,9.38 64.03,9.38 64.03,9.25"/>
										<polyline class="cloud-fly1" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="66.97,9.38 68.44,9.38 68.44,9.25"/>
										<polyline class="cloud-fly" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="27.07,23.38 24.23,23.38 24.23,22.95"/>
										<polyline class="cloud-fly" fill="none" stroke="#D6D6D6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" points="21.29,23.38 19.82,23.38 19.82,22.95 "/>
										<path fill="#FEA100" d="M50.097,30.379H35.93c-1.62,3.25-8.43,13.451-8.43,13.451c1.85-2.84,7.109-3.74,7.819,0c0.631,3.33,6.681,3.239,7.46-1.261c0.7-4.03,6.7-4.12,8.091-1.26c1.84,3.8,6.71,1.12,6.71,1.12L50.097,30.379z"/>
										<polyline fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="8.1,57.379 78.1,57.379 78.1,56.879"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M10.65,57.379c9.98,0,19.181-16.989,22.681-23.91c0.96-1.899,2.92-3.09,5.06-3.09h5.09"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M75.62,57.379c-9.98,0-19.19-16.989-22.681-23.91c-0.96-1.899-2.92-3.09-5.069-3.09H42.78"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22.96,57.379c4.54,0,9.149-9.109,9.149-9.109"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M62.81,57.379c-4.53,0-9.149-9.109-9.149-9.109"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M50.87,57.379c-2.261,0-3.7-2.649-4.57-4.799"/>
										<path fill="none" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M27.5,43.831c1.85-2.84,7.109-3.74,7.819,0c0.631,3.33,6.681,3.239,7.46-1.261c0.7-4.03,6.7-4.12,8.091-1.26c1.84,3.8,7.81,2.75,5.71-0.88"/>
										<path fill="#FEA100" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="M49.08,23.62c2.66-15.18,13.35-6.43,4.33-2.97c-2.5,0.96-4.49,3.8-4.65,6.73C48.76,27.38,48.71,25.71,49.08,23.62z"/>
										<path fill="#FEA100" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" d="M37.89,23.1c-4.02-15.57-16.67-8.34-8.78-5.36c2.19,0.83,9.33,6.19,9.46,8.71C38.57,26.45,38.35,24.88,37.89,23.1z"/>
										<circle fill="#FEA100" stroke="#505050" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" cx="43.43" cy="14.88" r="2.5"/>
									</g>
								</g>
							</svg>
						</i>
                        <h3>LA RÉUNION</h3>
                    </a>
                </li>
            </ul>
            <a href="/reexpedition-livraison-dom" class="links inline-link">EN SAVOIR PLUS SUR LA RÉEXPÉDITION ET LA LIVRAISON <span></span></a>
        </div>
    </div>
</div>
<div class="commitment-wrapper section">
    <div class="commitment-con section">
        <div class="container">
            <div class="row commitment-inner">
                <div class="col-md-5 align-self-center">
                    <div class="text-wrap">
                        <h2>Notre engagement en matière de sécurité</h2>
                        <p>Contrôle des colis, prévention des fraudes, mesures contre la casse, assurance multirisques, sécurisation des paiements, suivi des livraisons en temps réel – nous avons mis en place des process très rigoureux pour garantir la sécurité
                            des personnes, des marchandises et des données personnelles.</p>
                        <a href="/confiance-securite" class="links inline-link">DÉCOUVREZ NOS PROCESS DE SÉCURITÉ <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="img-wrap">
        <div class="right-sep section">
            <picture>
                <source srcset="assets/images/Process-securite.webp" type="image/webp">
                <source srcset="assets/images/Process-securite.png" type="image/jpeg">
                <img src="assets/images/Process-securite.png" alt="Une succession de postes logistiques avec des agents qui sécurisent des colis" class="img-fluid">
            </picture>
        </div>
    </div>
</div>
<div class="why-wrapper  ptb-10 bg-gry text-center">
    <div class="container">
        <div class="simple-main section">
            <h2>Un tarif simple et transparent</h2>
            <p>Avec <span class="orange">Voyey</span>, pas de mauvaises surprises : vous payez le prix annoncé et c’est tout !</p>
            <a href="/tarifs" class="btn-main"><span>DÉCOUVREZ NOTRE GRILLE TARIFAIRE</span><i></i></a>
        </div>
    </div>
    <div class="why-list section">
        <h2>Pourquoi choisir Voyey ?</h2>
        <ul>
            <li class="wl1">
                <i><img src="assets/images/prix-imbattables.svg" alt="icône symbole Euro entouré d’une flèche qui monte" class="img-fluid"></i>
                <h3>Des prix vraiment<br> imbattables</h3>
                <p>Vous achetez vos produits à prix coûtant ; vous bénéficiez d’une adresse postale en métropole gratuite ; et vous payez la livraison deux fois moins chère qu’aujourd’hui. Dédouanement et assurance compris !</p>
            </li>
            <li class="wl2">
                <i><img src="assets/images/Livraison-rapide.svg" alt="icône d’un colis en mouvement avec une horloge qui affiche 5 jours" class="img-fluid"></i>
                <h3>Livraison entre<br> 1 et 5 jours</h3>
                <p>La livraison est ultra-rapide ! Nous nous engageons sur un délai de 5 jours maximum, mais on va souvent beaucoup plus vite. Et cerise sur le gâteau : on vous livre en mains propres là où ça vous arrange !</p>
            </li>
            <li class="wl3">
                <i><img src="assets/images/Communautaire.svg" alt="icône plusieurs personnage au milieu d’un cercle formé par un avion" class="img-fluid"></i>
                <h3>Une plateforme<br> communautaire</h3>
                <p>Vos produits sont acheminés par des voyageurs aériens qui nous louent de l’espace dans leurs bagages. On leur remet vos colis à l’aéroport de départ et on les récupère dès qu’ils arrivent à destination !</p>
            </li>
            <li class="wl4">
                <i><img src="assets/images/Formulaire-detaxe.svg" alt="icône d’un formulaire avec un tampon détaxé" class="img-fluid"></i>
                <h3>Achats détaxés<br> (légalement !)</h3>
                <p>Enfin ! Grâce à Voyey, vous n’avez plus à payer la TVA métropolitaine quand vous faites des achats en métropole. Vous payez uniquement les taxes d’outre-mer, qui financent le développement au niveau local.</p>
            </li>
            <li class="wl5">
                <i><img src="assets/images/etiquette-bagage.svg" alt="icône d’une étiquette de bagage avec un symbole Euro dans une étoile jaune" class="img-fluid"></i>
                <h3>De l’argent gagné<br> facilement </h3>
                <p>Les voyageurs nous vendent les kilos inutilisés de leur franchise bagage et c’est tout. On contrôle les articles à transporter, les conditionne et les amène à l’aéroport. On s’occupe aussi du dédouanement.</p>
            </li>
        </ul>
        <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
    </div>
</div>
<div class="slider-wrapper ptb-10">
    <div class="container">
        <div class="user-wrap section">
            <h2 class="text-center">Ce qu’en disent nos utilisateurs</h2>
            <h3 class="text-center">Et ce n’est que le début !</h3>
            <div class="user-slider slider-main section">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="testimonialConfig">
                    <div ngxSlickItem *ngFor="let testimonial of testimonials">
                        <div class="slide">
                            <div class="img-wrap">
                                <div class="img-main">
                                    <picture>
                                        <source srcset="{{ testimonial.webp }}" type="image/webp">
                                        <source srcset="{{ testimonial.img }}" type="image/jpeg">
                                        <img src="{{ testimonial.img }}" alt="{{ testimonial.alt }}" class="img-fluid">
                                    </picture>
                                </div>
                            </div>
                            <div class="text-wrap">
                                <p>{{ testimonial.content }}</p>
                                <ul *ngIf="testimonial.lists">
                                    <li *ngFor="let list of testimonial.lists">
                                        {{list}}
                                    </li>
                                </ul>
                                <span *ngIf="testimonial.greeting" class="greeting">{{testimonial.greeting}}</span>
                                <span class="client-name">{{testimonial.clientname}}</span>
                            </div>
                            <button class="btn-prev btn-nav" (click)="prev()">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20.98px" height="12.828px" viewBox="-0.414 -0.414 20.98 12.828" enable-background="new -0.414 -0.414 20.98 12.828" xml:space="preserve">
								<polyline fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" points="6,11 1,6 6,1 "/>
								<line fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="1.567" y1="6" x2="19.567" y2="6"/>
								</svg>
							</button>
                            <button class="btn-next btn-nav" (click)="next()">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20.98px" height="12.828px" viewBox="0 -0.414 20.98 12.828" enable-background="new 0 -0.414 20.98 12.828" xml:space="preserve">
								<polyline fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" points="14.567,1 
									19.567,6 14.567,11 "/>
								<line fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" x1="19" y1="6" x2="1" y2="6"/>
								</svg>
							</button>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <a href="https://book.com/voyey97/reviews" target="_blank" class="links inline-link">CONSULTER TOUS LES TÉMOIGNAGES DE CLIENTS <span></span></a>
            </div>
        </div>
    </div>
</div>
<div class="logo-slider ptb-10 text-center">
    <div class="container">
        <h2>On parle de nous dans la presse !</h2>
        <ngx-slick-carousel class="carousel slider-main" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides">
                <div class="img-wrap">
                    <a href="{{ slide.link }}" target="_blank"><img src="{{ slide.img }}" alt="{{ slide.alt }}" class="img-fluid"></a>
                </div>
            </div>
        </ngx-slick-carousel>
        <a href="https://in/voyey-blog/press-articles/" class="links inline-link">CONSULTER TOUS LES ARTICLES SUR VOYEY <span></span></a>
    </div>
</div>
<div class="budget-wrapper ptb-10 bg-gry">
    <div class="container">
        <div class="row budget-raw">
            <div class="col-md-5 budget-main">
                <div class="text-wrap">
                    <h2>En partance pour les DOM ? Et si vous augmentiez votre budget vacances sans rien faire (ou presque) ?</h2>
                    <p>Louez-nous l’espace libre de vos bagages et découvrez combien vous gagneriez en convoyant des articles pour le compte de Voyey.</p>
                    <a [routerLink]="'/voyageurs-dom'" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}" class="btn-main"><span>JE VOYAGE</span><i></i></a>
                </div>
            </div>
            <div class="col-md-7 quotes-main">
                <ngx-slick-carousel class="carousel budget-carousel" #slickModal="slick-carousel" [config]="budgetConfig">
                    <div ngxSlickItem *ngFor="let budget of budgets">
                        <div class="quotes-wrap">
                            <div class="quotes-avtar">
                                <picture>
                                    <source srcset="{{budget.webp}}" type="image/webp">
                                    <source srcset="{{budget.img}}" type="image/jpeg">
                                    <img src="{{budget.img}}" alt="cedric" class="img-fluid" alt="{{budget.alt}}">
                                </picture>
                            </div>
                            <div class="quotes-text">
                                <p>{{budget.testimonialcon}}</p>
                                <h3>{{budget.clientname}}</h3>
                            </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
    </div>
</div>
<div class="link-wrapper ptb-10">
    <div class="container">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="boxes-wrap">
                    <i><img src="assets/images/equipe-voyey.svg" class="img-fluid" alt="icône plusieurs personnage au milieu d’un cercle formé par un symbole Voyey"></i>
                    <h2>À propos de Voyey</h2>
                    <p>Découvrez nos valeurs, nos motivations et ce que nous faisons pour rendre l’e-commerce vraiment accessible aux ultramarins.</p>
                    <a href="/a-propos" class="links">EN SAVOIR PLUS</a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="boxes-wrap">
                    <i><img src="assets/images/fonctionnement-voyey.svg" class="img-fluid" alt="icône personnage au-dessus d’une route crantée"></i>
                    <h2>Fonctionnement</h2>
                    <p>On vous explique comment fonctionne Voyey, que ce soit pour faire livrer un colis outre-mer ou détaxer vos achats en métropole.</p>
                    <a href="/fonctionnement" class="links">EN SAVOIR PLUS</a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="boxes-wrap">
                    <i><img src="assets/images/confiance-voyey.svg" class="img-fluid" alt="icône d’une poignée de main avec un symbole vérifié jaune"></i>
                    <h2>Sécurité & confiance</h2>
                    <p>Découvrez ce que nous faisons pour assurer la sécurité des voyageurs, des marchandises et des données personnelles de nos utilisateurs.</p>
                    <a href="/confiance-securite" class="links">EN SAVOIR PLUS</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="register-wrapper bg-gry ptb-10 text-center">
    <div class="container">
        <h2>Inscrivez vous maintenant</h2>
        <p>Envie de tester par vous-même ? Passez commande sur notre plateforme<br> sécurisée ou devenez voyageur Voyey pour augmenter vos revenus.</p>
        <div class="register-btn section">
            <a href="https://app.voyey.com" class="btn-main">J'achète en ligne avec Voyey</a>
            <a href="https://app.voyey.com" class="btn-main">J'ENVOIE MES COLIS AVEC VOYEY</a>
            <a href="https://app.voyey.com/publish-trip" class="btn-main">JE CONVOIE DES COLIS POUR VOYEY</a>
        </div>
    </div>
</div>