import { DOCUMENT } from '@angular/common';
import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-traveller',
  templateUrl: './traveller.component.html',
  styleUrls: ['./traveller.component.scss']
})
export class TravellerComponent implements OnInit {
  @ViewChild('slickModal') slickModal;

  title = 'Voyey | Gagnez de l’argent en voyageant dans les DOM !';
  jsonLD: SafeHtml;

  schema = {
    "url": "https://voyey.com/voyageurs-dom/",
    "name": "Voyey | Gagnez de l’argent en voyageant dans les DOM !",
    "description": "Voyey propose aux voyageurs se rendant dans les DOM de transporter des colis. Valable vers la Guadeloupe, la Martinique, la Guyane et La Réunion."
  };

  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([      
      { name: "description", content: "Voyey propose aux voyageurs se rendant dans les DOM de transporter des colis. Valable vers la Guadeloupe, la Martinique, la Guyane et La Réunion." },
      { name: "og:url", content: "https://voyey.com/voyageurs-dom/" },
      { name: "og:title", content: "Voyey | Gagnez de l’argent en voyageant dans les DOM !" },
      { name: "og:description", content: "Voyey propose aux voyageurs se rendant dans les DOM de transporter des colis. Valable vers la Guadeloupe, la Martinique, la Guyane et La Réunion." },
      { name: "twitter:title", content: "Voyey | Gagnez de l’argent en voyageant dans les DOM !" },
      { name: "twitter:description", content: "Voyey propose aux voyageurs se rendant dans les DOM de transporter des colis. Valable vers la Guadeloupe, la Martinique, la Guyane et La Réunion." }
    ]);
    this.setCanonicalURL('https://voyey.com/voyageurs-dom/');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

  //testimonials-carousel
  testimonials = [
    {
    title: "Ils ont transporté des colis pour nous !",
    content:"Vous voyagez léger, vous n'utilisez pas toute votre franchise de bagages, vous pouvez les confier a Voyey. Ils sont précis, ponctuels à l'arrivée et au départ. 0 stress, 0 problème et un gain financier à chaque voyage.",
    clientname:"- Nicolas, voyageur Voyey"}
  ];

  testimonialConfig = {
    "fade": true,
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "dots": false, 
    "arrows":false,
    "cssEase": 'ease-in-out',
    "infinite": true  };
  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }

}