<ngx-json-ld [json]="schema"></ngx-json-ld>
<div class="department-wrapper redirection-raw">
    <div class="department-con section">
        <div class="container">
            <div class="row department-inner">
                <div class="col-md-7">
                    <div class="text-wrap">
                        <h1>Vos colis livrés outre-mer en 1 à 5 jours</h1>
                        <p>Vous préférez acheter vos produits vous-même sans passer par le service d’achat détaxé de Voyey ? Vous avez des effets personnels à envoyer en Guadeloupe, en Martinique, en Guyane ou à La Réunion ? Avec Voyey, vous bénéficiez d’une
                            adresse de livraison et d’un espace de stockage gratuits en métropole et nous vous livrons vos colis dans les délais les plus courts, pour un prix tout petit !</p>
                        <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="img-wrap">
        <div class="right-sep section">
            <img src="assets/images/Reexpedition-livraison-DOM.png" class="img-fluid" alt="Des habitants des DOM qui font de la réexpédition depuis la métropole">
        </div>
    </div>
</div>
<div class="services-wrap redirect-wrap ptb-10 bg-gry text-center">
    <h2>Voyey vous propose 2 services</h2>
    <div class="container">
        <div class="row services-main">
            <div class="col-sm-6 right-con text-right">
                <div class="img-wrap">
                    <img src="assets/images/Reexpedition-DOM.png" class="img-fluid" alt="Un utilisateur dans un DOM qui fait une demande de réexpédition via Voyey">
                </div>
            </div>
            <div class="col-sm-6 left-con">
                <div class="text-wrap">
                    <h2>La réexpédition</h2>
                    <p>Faites-nous livrer vos produits achetés en métropole. Nous les réexpédions outre-mer, quand ça vous arrange. Réception et stockage gratuits. </p>
                </div>
            </div>
        </div>
        <div class="row services-main">
            <div class="col-sm-6 right-con">
                <div class="img-wrap">
                    <img src="assets/images/Livraison-DOM.png" class="img-fluid" alt="Un utilisateur dans un DOM qui est livré par un agent Voyey">
                </div>
            </div>
            <div class="col-sm-6 left-con">
                <div class="text-wrap">
                    <h2>La livraison</h2>
                    <p>Envoyez-nous votre colis et, après vérification, nous le faisons acheminer outre-mer par un voyageur certifié. Délais : entre 1 et 5 jours.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="work-wrapper section text-center">
    <div class="container">
        <h2>Le meilleur de la réexpédition et de la livraison de colis outre-mer</h2>
        <div class="work-wrapper-raw section">
            <div class="legal-head section">
                <div class="legal-tab section">
                    <ul class="nav" role="tablist">
                        <li>
                            <a class="active" id="redirect-tab1" data-toggle="tab" href="#redirect1" role="tab" aria-controls="redirect11" aria-selected="true" onclick="somefunction(); return false;">
                                <i>
									<img src="assets/images/reexpedition-stockage.svg" class="img-fluid" alt="icône symbole monde avec localisation dom avec espace de stockage">
								</i>
                                <span>Adresse livraison et espace de stockage gratuits</span>
                            </a>
                        </li>
                        <li>
                            <a id="redirect-tab2" data-toggle="tab" href="#redirect2" role="tab" aria-controls="redirect22" aria-selected="false" onclick="somefunction(); return false;">
                                <i><img src="assets/images/services-premium.svg" class="img-fluid" alt="icône document avec un badge de qualité"></i>
                                <span>Des services gratuits, mais premium</span>
                            </a>
                        </li>
                        <li>
                            <a id="redirect-tab3" data-toggle="tab" href="#redirect3" role="tab" aria-controls="redirect33" aria-selected="false" onclick="somefunction(); return false;">
                                <i><img src="assets/images/Livraison-rapide-pas-chere.svg" class="img-fluid" alt="icône d’un colis en mouvement avec camion de livraison"></i>
                                <span>Une livraison ultra-express, la moins chère du marché</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="reexpedition-raw work-wrapper-col legal-body shop-wrap section">
    <div class="container">
        <div class="tab-content">
            <div class="tab-pane fade active show" id="redirect1" role="tabpanel" aria-labelledby="redirect-tab1">
                <div class="text-wrap">
                    <p>Si vous achetez régulièrement sur les sites d’e-commerce, vous savez certainement déjà redirect fonctionne la réexpédition. Voyey crée pour vous une adresse de livraison virtuelle en métropole. Il s’agit de l’adresse de notre entrepôt,
                        où nous recevons, reconditionnons et stockons vos colis, le temps de les envoyer outre-mer. La grande différence entre Voyey et les solutions existantes, c’est que nous avons décidé de rendre ce service entièrement gratuit afin
                        de permettre à tous les Domiens d’acheter outre-mer comme bon leur semble. Vous ne payez que la livraison – qui est ultra-rapide et deux fois moins cher qu’aujourd’hui !</p>
                </div>
            </div>
            <div class="tab-pane fade" id="redirect2" role="tabpanel" aria-labelledby="redirect-tab2">
                <div class="text-wrap">
                    <p>Chez Voyey, service gratuit ne signifie pas médiocre, bien au contraire ! Nous, ce qu’on veut, c’est réduire les inégalités liées à la distance géographique, pas faire dans le bas de gamme. Et comme nous avons par ailleurs le statut
                        de transitaire, nous avons pu développer un modèle qui nous permet de vous proposer des services gratuits et premium à la fois. En effet, vos colis sont réceptionnés, déballés, contrôlés, photographiés et reconditionnés avec le
                        plus grand soin par des professionnels. Ils sont ensuite stockés dans notre entrepôt, en attendant que vous déclenchiez la livraison. Vous pouvez commander plusieurs colis et nous nous occuperons de les regrouper dans un même envoi
                        – bien sûr, sans aucun frais additionnels pour vous !</p>
                </div>
            </div>
            <div class="tab-pane fade" id="redirect3" role="tabpanel" aria-labelledby="redirect-tab3">
                <div class="text-wrap">
                    <p>Ce sont les voyageurs aériens Voyey qui transportent vos colis dans leurs valises. Voyey leur achète les kilos non utilisés de leur franchise bagage, et cela, en toute légalité, avec l’accord des services de sécurité et de douanes.
                        Aujourd’hui, notre réseau de voyageurs achemine quotidiennement des colis jusque dans les DOM. L’avantage pour vous ? Vous n’avez plus à attendre trois semaines avant de recevoir vos produits, puisqu’on s’engage sur un délai de
                        livraison de 5 jours maximum. De plus, vous payez deux fois moins cher qu’actuellement. Et le prix comprend non seulement le transport et la livraison sur-mesure jusqu’à vous, mais également l’assurance contre la perte, le vol
                        et la casse et le dédouanement de vos colis. Tout cela n’est possible qu’avec Voyey !</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg-gry ptb-10 trust-main promise-wrap redirect-promise">
    <h2>Les promesses Voyey</h2>
    <ul class="trust-inner">
        <li>
            <div class="trust-box">
                <div class="img-wrap">
                    <img src="assets/images/livraison-colis-rapide.svg" class="img-fluid" alt="illustration colis avec planning et horloge">
                </div>
                <div class="text-wrap">
                    <h4>Vous recevez votre colis entre 1 et 5 jours</h4>
                    <p>Grâce à notre réseau de voyageurs aériens qui se rendent chaque jour dans les DOM, nous pouvons vous promettre une livraison ultra-rapide. Voyey s’engage ainsi à vous livrer en cinq jours maximum, mais, généralement, vous recevrez
                        votre colis en mains propres bien avant. Finis les délais interminables !</p>
                </div>
            </div>
        </li>
        <li>
            <div class="trust-box">
                <div class="img-wrap">
                    <img src="assets/images/economies-frais-livraison.svg" class="img-fluid" alt="illustration ticket de caisse avec mention Euros et pourcentage">
                </div>
                <div class="text-wrap">
                    <h4>Vous faites au moins 50 % d’économies</h4>
                    <p>Là encore, vive la logique communautaire ! Voyey envoie vos colis jusque dans les DOM grâce à son réseau de voyageurs aériens. Ceux-ci nous louent l’espace disponible de leurs valises et, bien sûr, cela nous permet de pratiquer les
                        tarifs les moins chers du marché. Tout en rémunérant les voyageurs Voyey !</p>
                </div>
            </div>
        </li>
        <li>
            <div class="trust-box">
                <div class="img-wrap">
                    <img src="assets/images/dedouanement-Voyey.svg" class="img-fluid" alt="illustration documents administratif avec validation et tampon">
                </div>
                <div class="text-wrap">
                    <h4>Vous n’avez pas à gérer le dédouanement</h4>
                    <p>Chez Voyey, on connaît (trop) bien les questions de dédouanement et on sait le temps que ça prend. Voilà pourquoi on a décidé de vous épargner cette étape. C’est donc l’agent Voyey qui s’occupe de toutes les démarches liées au dédouanement.
                        Et la bonne nouvelle ? Vous n’avez pas à débourser un centime !</p>
                </div>
            </div>
        </li>
    </ul>
</div>
<div class="parcel-wrapper deliver-wrapper ptb-10 text-center">
    <div class="container">
        <div class="text-wrap section ">
            <h2>Faites-vous livrer en mains propres, là où vous le souhaitez !</h2>
            <ul>
                <li>
                    <a class="boxes-wrap">
                        <i>
							<img src="assets/images/lieu-livraison.svg" class="img-fluid" alt="illustration documents administratif avec validation et tampon" />
						</i>
                        <h5>Au lieu de votre choix</h5>
                    </a>
                </li>
                <li>
                    <a class="boxes-wrap">
                        <i><img src="assets/images/creneau-livraison.svg" class="img-fluid" alt="icône horloge avec roue mécanique jaune" /></i>
                        <h5>Au créneau qui vous arrange</h5>
                    </a>
                </li>
                <li>
                    <a class="boxes-wrap">
                        <i><img src="assets/images/Livraison-en-main-propre.svg" class="img-fluid" alt="icône d'un colis dans une mainjours" /></i>
                        <h5>En main propre</h5>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="left-separator">
        <img src="assets/images/deliver-sep.png" alt="seperator" class="img-fluid">
    </div>
</div>
<div class="why-wrapper ptb-10 bg-gry text-center">
    <div class="container">
        <div class="simple-main subscribe-wrap section">
            <p>Vous êtes un adepte de l’e-commerce ?</p>
            <h2>Optez pour l’abonnement Voyey !</h2>
            <ul>
                <li>sans engagement</li>
                <li>livraison sur-mesure gratuite</li>
                <li>prix au kilo réduit</li>
                <li>frais de services minuscules</li>
            </ul>
            <a href="https://app.voyey.com/akaz-subscription" class="btn-main"><span>JE M’ABONNE</span><i></i></a>
        </div>
        <div class="why-list products-list section">
            <h2>Nous livrons vos produits en ligne ou en magasin</h2>
            <ul>
                <li>
                    <i><img src="assets/images/Nous01.svg" alt="Vêtements" class="img-fluid"></i>
                    <h4>Vêtements</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous02.svg" alt="Chaussures" class="img-fluid"></i>
                    <h4>Chaussures</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous03.svg" alt="Produits de beauté" class="img-fluid"></i>
                    <h4>Produits de beauté</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous04.svg" alt="High-Tech" class="img-fluid"></i>
                    <h4>High-Tech</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous05.svg" alt="Matériel informatique " class="img-fluid"></i>
                    <h4>Matériel informatique </h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous06.svg" alt="Electronique / Hifi" class="img-fluid"></i>
                    <h4>Electronique / Hifi</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous07.svg" alt="Maroquinerie" class="img-fluid"></i>
                    <h4>Maroquinerie</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous08.svg" alt="Livres" class="img-fluid"></i>
                    <h4>Livres</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous09.svg" alt="Jeux pour enfants" class="img-fluid"></i>
                    <h4>Jeux pour enfants</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous10.svg" alt="Pièces détachées" class="img-fluid"></i>
                    <h4>Pièces détachées</h4>
                </li>
                <li>
                    <i><img src="assets/images/nous11.svg" alt="Outils de bricolage" class="img-fluid"></i>
                    <h4>Outils de bricolage</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous12.svg" alt="Petit électroménager" class="img-fluid"></i>
                    <h4>Petit électroménager</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous13.svg" alt="Articles de sport" class="img-fluid"></i>
                    <h4>Articles de sport</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous14.svg" alt="Linge de maison" class="img-fluid"></i>
                    <h4>Linge de maison</h4>
                </li>
            </ul>
            <p>On peut même acheter et livrer des produits hors-format !</p>
            <p>Un doute sur un produit ? <a href="/contact">Contactez-nous !</a></p>
            <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
        </div>
    </div>
</div>
<div class="commitment-wrapper development-wrapper ptb-10">
    <div class="container">
        <div class="row position-relative">
            <div class="col-sm-6">
                <div class="text-wrap">
                    <h2>Vos colis sont en sécurité avec nous</h2>
                    <p>La sécurité est notre priorité numéro 1 et nous faisons tout pour prévenir les risques de fraude, de casse et d’illégalité. Voilà pourquoi tous les colis que vous nous confiez sont contrôlés et reconditionnés par nos soins et un agent
                        Voyey est présent à chaque bout de la chaîne de livraison pour remettre et réceptionner vos articles. De plus, nous respectons les règles du fret aérien pour sécuriser au maximum nos envois et nous travaillons en étroite collaboration
                        avec les services douaniers.</p>
                    <a href="/confiance-securite" class="links inline-link">En savoir plus sur nos process de sécurité <span></span></a>
                </div>
            </div>
            <div class="col-sm-6 align-self-center">
                <div class="img-wrap">
                    <img src="assets/images/colis-en-securite.png" alt="Une chaîne logistique avec plusieurs points de contrôle pour la sécurité des colis" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="domiens-wrap ptb-10 bg-gry text-center">
    <div class="container">
        <h2>Plus de 100 Domiens nous ont déjà fait confiance !</h2>
        <ul>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-1.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-2.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-3.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-4.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-5.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-6.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-7.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-8.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-9.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-10.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-11.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-12.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
        </ul>
        <p>Faites livrer vos produits outre-mer à des prix imbattables !</p>
        <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
    </div>
</div>