import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-redirection',
  templateUrl: './redirection.component.html',
  styleUrls: ['./redirection.component.scss']
})
export class RedirectionComponent implements OnInit {

  title = 'Voyey | Réexpédition et livraison outre-mer entre 1 à 5 jours';
  jsonLD: SafeHtml;

  schema = {
    "url": "https://voyey.com/reexpedition-livraison-dom",
    "name": "Voyey | Réexpédition et livraison outre-mer entre 1 à 5 jours",
    "description": "Réexpédition et espace de stockage gratuits en métropole. Nous vous livrons en Guadeloupe, Martinique, Guyane et à La Réunion."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([      
      { name: "description", content: "Réexpédition et espace de stockage gratuits en métropole. Nous vous livrons en Guadeloupe, Martinique, Guyane et à La Réunion." },
      { name: "og:url", content: "https://voyey.com/reexpedition-livraison-dom" },
      { name: "og:title", content: "Voyey | Réexpédition et livraison outre-mer entre 1 à 5 jours" },
      { name: "og:description", content: "Réexpédition et espace de stockage gratuits en métropole. Nous vous livrons en Guadeloupe, Martinique, Guyane et à La Réunion." },
      { name: "twitter:title", content: "Voyey | Réexpédition et livraison outre-mer entre 1 à 5 jours" },
      { name: "twitter:description", content: "Réexpédition et espace de stockage gratuits en métropole. Nous vous livrons en Guadeloupe, Martinique, Guyane et à La Réunion." }
    ]);
    this.setCanonicalURL('https://voyey.com/reexpedition-livraison-dom');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }

}