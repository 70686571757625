<div class="department-wrapper travel-main-raw">
    <div class="department-con section">
        <div class="container">
            <div class="row department-inner">
                <div class="col-md-7">
                    <div class="text-wrap">
                        <h1>Gagnez de l’argent en voyageant dans les DOM !</h1>
                        <p>Voyey propose aux voyageurs se rendant dans les DOM de transporter des colis dans leurs bagages en échange d’une rémunération. C’est 100 % légal et sécurisé. Devenez voyageur Voyey dès aujourd’hui !</p>
                        <p></p>
                        <p><em>Valable pour toutes les personnes en partance pour la Guadeloupe, la Martinique, la Guyane et La Réunion depuis la métropole.</em></p>
                        <a href="https://app.voyey.com/publish-trip" class="btn-main"><span>JE COMMENCE</span><i></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="img-wrap">
        <div class="right-sep section">
            <img src="assets/images/Application-voyageurs-Voyey-responsive.png" class="img-fluid" alt="Application voyageurs Voyey sur tablette et mobile">
        </div>
    </div>
</div>
<div class="purchase-boxes ptb-10">
    <div class="container">
        <div class="row text-center">
            <div class="col-sm-12 col-xs-12">
                <h2>Pourquoi c’est vraiment un bon plan ?</h2>
            </div>
            <div class="col-sm-2 col-xs-12">

            </div>
            <div class="col-sm-4 col-xs-12">
                <div class="boxes-wrap">
                    <i><img src="assets/images/argent-facile.svg" alt="icône symbole d’un sac de pièces d’Euros" class="img-fluid"></i>
                    <h4>Parce que vous gagnez <br>de l’argent facilement</h4>
                    <p>Vous nous vendez les kilos inutilisés de votre franchise bagage et nous remplissons votre valise en fonction de l’espace disponible. Vous êtes payé au poids transporté.</p>
                </div>
            </div>
            <div class="col-sm-4 col-xs-12">
                <div class="boxes-wrap">
                    <i><img src="assets/images/argent-rapide.svg" alt="icône pièce Euros rapide" class="img-fluid"></i>
                    <h4>Parce que vous recevez <br>l’argent immédiatement</h4>
                    <p>Notre agent vient récupérer les colis à l’aéroport d’arrivée. Dès que c’est fait, nous vous transférons l’argent pour que vous puissiez en profiter pendant vos vacances.</p>
                </div>
            </div>
            <div class="col-sm-2 col-xs-12">

            </div>
        </div>
    </div>
</div>
<div class="why-list section ptb-10 text-center">
    <h2>En plus, vous n’avez rien à faire !</h2>
    <ul>
        <li>
            <i><img src="assets/images/rien-a-payer.svg" alt="icône feuille de vol avec icône avion jaune" class="img-fluid"></i>
            <h4>Vous n’avez (vraiment) <br>rien à payer</h4>
            <p>Pour devenir voyageur Voyey, il vous suffit de vous inscrire en cliquant ici et de préciser les informations de votre vol. Il n’y a ni frais d’inscription ni coûts cachés.</p>
        </li>
        <li>
            <i><img src="assets/images/pas-de-detour.svg" alt="icône échange de colis devant un aéroport" class="img-fluid"></i>
            <h4>Vous n’avez pas de <br>détour à faire</h4>
            <p>Avec Voyey, le colis vient à vous ! L’un de nos agents vous l’apporte à l’aéroport de départ (il vous aide même à le ranger) et un autre vient le récupérer à destination.</p>
        </li>
        <li>
            <i><img src="assets/images/gestion-douane.svg" alt="icône d’un colis avec un badge de validation" class="img-fluid"></i>
            <h4>On gère les questions <br>douanières</h4>
            <p>Transitaire et représentant en douane enregistré, on s’occupe de la paperasserie : inventaire, paiement des taxes, déclaration d’exportation... Pas d’inquiétude, on gère !</p>
        </li>
        <li>
            <i><img src="assets/images/rien-a-declarer.svg" alt="icône d’une déclaration douanière validée" class="img-fluid"></i>
            <h4>Vous n’avez pas à <br>déclarer le colis</h4>
            <p>Une fois vos bagages récupérés, vous pouvez vous diriger vers la sortie. S’il y a un contrôle, montrez aux douaniers les documents que notre agent vous a donné.</p>
        </li>
    </ul>
    <a href="https://app.voyey.com/publish-trip" class="btn-main"><span>JE COMMENCE</span><i></i></a>
</div>
<div class="why-wrapper ptb-10 bg-gry text-center">
    <div class="container">
        <div class="simple-main subscribe-wrap section">
            <h2>Notre engagement pour votre sécurité</h2>
            <p>Chez Voyey, votre sécurité est notre priorité. C’est pourquoi nous avons mis en place un système de double contrôle systématique. Le colis que nous mettons dans votre valise est, d’abord, inspecté de fond en comble par nos équipes (on peut
                même mobiliser un maître-chien !). Puis, nous le mettons à la disposition des douaniers pour qu’ils le vérifient à leur tour.</p>
            <a href="/confiance-securite" class="btn-main"><span>EN SAVOIR PLUS SUR NOS PROCESS DE SÉCURITÉ</span><i></i></a>
        </div>
    </div>
</div>
<div class="services-wrap traveller-wrap-raw ptb-10 bg-gry text-center">
    <h2>Comment convoyer pour Voyey ?</h2>
    <h4>C’est très simple !</h4>
    <div class="container">
        <div class="row services-main-col s-col1">
            <div class="align-self-center col-sm-6 left-con">
                <div class="text-wrap">
                    <h5 class="sr-no">Étape 1</h5>
                    <h2>Dites quand vous partez</h2>
                    <p>Rendez-vous dans votre espace personnel et cliquez sur le bouton « JE VOYAGE ». Là, vous devrez nous donner tous les détails de votre vol (destination, jour et heure de départ, numéro de vol). Nous vous demanderons également de nous
                        préciser le poids et l’espace disponibles dans vos bagages.</p>
                </div>
            </div>
            <div class="align-self-center col-sm-6  right-con text-right">
                <div class="img-wrap">
                    <img src="assets/images/publier-un-voyage.png" class="img-fluid" alt="illustration voyageur qui parle à un agent Voyey">
                </div>
            </div>
        </div>
        <div class="row services-main-col s-col2">
            <div class="align-self-center col-sm-6 order-2 order-md-1 right-con text-right">
                <div class="img-wrap">
                    <img src="assets/images/selection-voyage.png" class="img-fluid" alt="illustration agent Voyey qui sélectionne un vol vers les DOM">
                </div>
            </div>
            <div class="align-self-center col-sm-6 order-1 order-md-2 left-con">
                <div class="text-wrap">
                    <h5 class="sr-no">Étape 2</h5>
                    <h2>Voyey retient votre vol</h2>
                    <p>On vous prévient entre 3 et 7 jours avant et on vous transmet toutes les informations nécessaires. De votre côté, vous devez juste nous envoyer une photo de vos papiers d’identité, ainsi que votre confirmation de vol.</p>
                </div>
            </div>
        </div>
        <div class="row services-main-col s-col3">
            <div class="align-self-center col-sm-6 left-con">
                <div class="text-wrap">
                    <h5 class="sr-no">Étape 3</h5>
                    <h2>Voyey prépare le colis</h2>
                    <p>Voyey contrôle et prépare les articles que vous devrez transporter. Selon l’espace dont vous disposez, nous les mettrons soit dans un bagage complet, soit de façon à ce qu’ils tiennent dans votre valise.</p>
                </div>
            </div>
            <div class="align-self-center col-sm-6 right-con text-right">
                <div class="img-wrap">
                    <img src="assets/images/preparation-colis.png" class="img-fluid" alt="illustration équipe Voyey qui prépare un voyage vers les DOM">
                </div>
            </div>
        </div>
        <div class="row services-main-col s-col4">
            <div class="align-self-center col-sm-6 order-2 order-md-1 right-con text-right">
                <div class="img-wrap">
                    <img src="assets/images/depart-voyage-DOM.png" class="img-fluid" alt="illustration agent Voyey qui apporte un colis à l’aéroport de départ">
                </div>
            </div>
            <div class="align-self-center col-sm-6 order-1 order-md-2 left-con">
                <div class="text-wrap">
                    <h5 class="sr-no">Étape 4</h5>
                    <h2>Nous vous apportons le colis à l’aéroport</h2>
                    <p>Le jour du départ, un agent Voyey vous retrouve à l’aéroport à une heure convenue à l’avance. Il vous remet le colis en mains propres et vous invite à le vérifier. Une fois que c’est fait, il vous aide à le mettre dans la valise et
                        vous donne les documents requis en cas de contrôle douanier.</p>
                </div>
            </div>
        </div>
        <div class="row services-main-col s-col5">
            <div class="align-self-center col-sm-6 left-con">
                <div class="text-wrap">
                    <h5 class="sr-no">Étape 5</h5>
                    <h2>Livrez le colis et profitez !</h2>
                    <p>À l’arrivée, notre agent local vous attend à la sortie de l’avion (après les douanes) pour réceptionner le colis. Il valide le voyage pour activer le virement et, avec l’argent gagné, vous pouvez profiter encore plus de votre séjour
                        en outremer !</p>
                </div>
            </div>
            <div class="align-self-center col-sm-6 right-con text-right">
                <div class="img-wrap">
                    <img src="assets/images/arrivee-voyage-DOM.png" class="img-fluid" alt="illustration agent Voyey qui récupère un colis à l’aéroport d’arrivée">
                </div>
            </div>
        </div>
        <div class="section text-center">
            <p>Intéressé par le bon plan Voyey?</p>
            <a href="https://app.voyey.com/publish-trip" class="btn-main"><span>JE COMMENCE</span><i></i></a>
        </div>
    </div>
</div>
<div class="slider-wrapper-raw ptb-10">
    <div class="container">
        <div class="traveller-slider-main section">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="testimonialConfig">
                <div ngxSlickItem *ngFor="let testimonial of testimonials">
                    <div class="slide">
                        <div class="text-wrap">
                            <h2>{{testimonial.title}}</h2>
                            <p>{{ testimonial.content }}</p>
                            <span class="client-name">{{testimonial.clientname}}</span>
                        </div>
                        <button class="btn-prev btn-nav" (click)="prev()">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="20.98px" height="12.828px" viewBox="-0.414 -0.414 20.98 12.828" enable-background="new -0.414 -0.414 20.98 12.828"
                                xml:space="preserve">
                            <polyline fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" points="6,11 1,6 6,1 "/>
                            <line fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" stroke-miterlimit="10" x1="1.567" y1="6" x2="19.567" y2="6"/>
                            </svg>
                        </button>
                        <button class="btn-next btn-nav" (click)="next()">
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="20.98px" height="12.828px" viewBox="0 -0.414 20.98 12.828" enable-background="new 0 -0.414 20.98 12.828"
                                xml:space="preserve">
                            <polyline fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" points="14.567,1 
                                19.567,6 14.567,11 "/>
                            <line fill="none" stroke="" stroke-width="2" stroke-linecap="square" stroke-miterlimit="10" x1="19" y1="6" x2="1" y2="6"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
<div class="money-wrapper bg-gry ptb-10 text-center">
    <div class="container">
        <h2>Des questions ? Nous vous répondons !</h2>
        <div class="accordion" id="accordionExample">
            <div class="card">
                <div class="card-header" id="headingOne">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Puis-je vérifier le contenu de mon envoi ?
                        </button>
                    </h5>
                </div>
                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        Absolument ! <br>Même si chaque colis que nous vous confions est soigneusement contrôlé par nos équipes et par les services douaniers, nous vous encourageons toujours à en vérifier le contenu par vous-même.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingTwo">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Suis-je responsable de ce que je transporte pour Voyey?
                        </button>
                    </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div class="card-body">
                        Non. <br> Le convoi en tant que tel est placé sous la responsabilité de Voyey. Pour garantir votre sécurité, nous avons donc développé un système de double contrôle des produits à transporter par nos soins et par les services douaniers
                        eux-mêmes. <br> Par ailleurs, soyez rassuré, vous ne commettez rien d’illégal ! Nous faisons preuve de la plus grande transparence vis-à-vis des autorités. On les prévient à l’avance que vous allez transporter des marchandises
                        pour notre compte ; on leur donne une liste exhaustive du contenu du colis ; enfin, on leur précise vos informations de vol. <br> Vous êtes cependant responsable de vos effets personnels dans votre valise ! <br> On ne laisse vraiment
                        rien au hasard ! Vous pouvez donc partir l’esprit tranquille.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Que dois-je répondre à la question : « Avez-vous fait vous-même vos bagages ?
                        </button>
                    </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <div class="card-body">
                        Vous pouvez légitimement répondre : « Oui ». <br> Acheminer un colis pour le compte de Voyey, c’est un peu comme si vous transportiez des produits commandés sur Internet et que vous les aviez reçus le matin de votre vol. <br> Mais,
                        avec notre système de double contrôle, c’est beaucoup plus sûr ! Le contenu de votre colis a été soigneusement vérifié non seulement par Voyey (on mobilise même un maître-chien en cas de doute), mais également par les services
                        douaniers eux-mêmes. <br> De plus, nous vous disons exactement ce qu’il contient et vous pouvez tout vérifier par vous-même lorsque vous le réceptionnez à l’aéroport.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingFour">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            Est-ce que les compagnies aériennes autorisent un tel système de convoyage ?
                        </button>
                    </h5>
                </div>
                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                    <div class="card-body">
                        Non seulement les compagnies aériennes l'autorisent, mais elles le font elles-mêmes ! Si elles vous demandent de vous enregistrer à l'avance, c’est pour pouvoir vendre l’espace disponible en soute à des sociétés de transports comme Fedex, TNT, etc.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingFive">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            Combien d’argent puis-je espérer gagner avec Voyey ?
                        </button>
                    </h5>
                </div>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                    <div class="card-body">
                        Cela dépendra du poids que vous transporterez. <br> Mais il n’est pas rare que nos voyageurs touchent jusqu’à 160 euros par trajet. Sur un aller-retour, cela peut représenter jusqu’à 75 % du prix de votre billet d'avion. C’est
                        autant à dépenser sur place !
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingsix">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                            Où dois-je récupérer et déposer le colis ?
                        </button>
                    </h5>
                </div>
                <div id="collapsesix" class="collapse" aria-labelledby="headingsix" data-parent="#accordionExample">
                    <div class="card-body">
                        Un agent Voyey vous retrouve à l’aéroport de départ pour vous remettre le colis en mains propres et un autre le récupère à la sortie de l’avion. <br> L’avantage pour vous ? Vous n'avez aucun détour à faire !
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingseven">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false" aria-controls="collapseseven">
                            Sur quelles liaisons aériennes puis-je transporter des colis pour le compte de Voyey ?
                        </button>
                    </h5>
                </div>
                <div id="collapseseven" class="collapse" aria-labelledby="headingseven" data-parent="#accordionExample">
                    <div class="card-body">
                        Sur toutes celles à destination des DOM. <br> Si vous êtes en partance pour la Guadeloupe, la Martinique, la Guyane ou La Réunion, n’hésitez donc pas à vous inscrire en <a href="https://app.voyey.com/signup">cliquant ici</a>. Nous
                        pourrions avoir besoin de votre aide !
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading1">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                            Est-ce que je peux aussi transporter des paquets quand je rentre en métropole ?
                        </button>
                    </h5>
                </div>
                <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordionExample">
                    <div class="card-body">
                        Non. Cela fait partie de nos projets, mais, pour le moment, nous ne faisons pas de convoyage depuis l’outremer jusqu’en métropole. On vous dira quand ça changera !
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading2">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                            Quand serai-je payé ?
                        </button>
                    </h5>
                </div>
                <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#accordionExample">
                    <div class="card-body">
                        Nous activons le transfert dès que nous réceptionnons le colis à l’aéroport. En général, il faut compter cinq jours avant de recevoir l’argent sur son compte.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading3">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                            Quelle est ma responsabilité en cas de vol, de casse ou de perte d'un colis ?
                        </button>
                    </h5>
                </div>
                <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordionExample">
                    <div class="card-body">
                        Vous êtes couvert ! Nous avons en effet souscrit une police d'assurance contre le vol, la casse ou la perte de colis auprès d'Allianz Assurance.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading4">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                            Que se passe-t-il si mon vol est annulé ou reporté ?
                        </button>
                    </h5>
                </div>
                <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordionExample">
                    <div class="card-body">
                        Il y a deux cas de figure. <br> Si cela arrive avant la prise en charge du colis, vous devez nous prévenir dans les plus brefs délais pour que nous convenions d’une nouvelle heure, voire d’une nouvelle date. <br> Si cela se produit
                        après que vous avez réceptionné le colis, appelez-nous pour que notre agent puisse vous retrouver à l’aéroport pour récupérer la marchandise.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading5">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                            Devrai-je payer des droits ou des taxes de douane pour le colis que je transporte ?
                        </button>
                    </h5>
                </div>
                <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordionExample">
                    <div class="card-body">
                        Non, vous n’aurez pas un centime à débourser. <br> Les marchandises que vous acheminez pour le compte de Voyey sont toutes déclarées par nos soins aux services douaniers et nous nous occupons également de payer les droits et taxes
                        éventuels lors du dédouanement sur place. <br> A votre arrivée, nous vous fournissons la preuve de règlement et tous les autres documents nécessaires en cas de contrôle. <br> Bien entendu, vous êtes responsable des autres produits
                        que vous transportez dans votre valise.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="heading6">
                    <h5 class="mb-0">
                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                            Devrai-je m’arrêter à la douane pour faire le dédouanement ?
                        </button>
                    </h5>
                </div>
                <div id="collapse6" class="collapse" aria-labelledby="heading6" data-parent="#accordionExample">
                    <div class="card-body">
                        Non, c’est Voyey qui, en tant que transitaire et représentant en douane enregistré, s’en occupe. <br> Lorsque vous arrivez à destination, nous validons auprès des autorités la déclaration en douane des marchandises que vous transportez.
                        Comme ça, vous n’avez pas à vous en préoccuper et vous pouvez tranquillement prendre le chemin de la sortie sans vous arrêter aux douanes. <br> En cas de visite sur les marchandises ou de contrôle inopiné, pas de panique, vous
                        êtes en règle ! Précisez simplement que vous convoyez de la marchandise pour le compte de Voyey et présentez les documents que vous a remis notre agent en métropole. Les douaniers sont au courant !
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>