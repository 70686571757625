<div class="kit-media-raw main-wrapper section">
    <div class="container">
        <h1>Kit média</h1>
        <h4>Téléchargez les logos officiels, les captures d’écran de produit et<br> les photos de l’équipe de direction de Voyey</h4>
        <div class="boilerplate-raw section">
            <div id="bar">
                <h3>Boilerplate</h3>
                <p>Destiné aux consommateurs des DOM achetant sur Internet, <a href="https://voyey.com">Voyey</a> est un service d’achat détaxé et de réexpédition outre-mer qui leur permet de recevoir, en mains propres et de manière sûre, rapide et économique, n’importe quel produit acheté en métropole. Voyey achète les produits en ligne ou en magasin pour le compte de ses utilisateurs, leur faisant ainsi bénéficier de l’exonération de la TVA métropolitaine. Voyey envoie ensuite les produits outre-mer entre J+1 et J+5 grâce à son réseau de voyageurs aériens. Le dédouanement et la livraison à destination sont assurés par ses agents au niveau local.</p>
            </div>
            <div class="share-opt-raw">
                <ul>
                    <li>
                        <button id="share-button"><img src="assets/images/ic-share.svg" alt="" /></button>
                    </li>
                    <li>
                        <button onclick="copyToClipboard('#p1')"><img src="assets/images/ic-copy.svg" alt="" /></button>
                    </li>
                    <li>
                        <a href="assets/images/boilerplate.txt" download="boilerplate-document"><img src="assets/images/ic-download-black.svg" alt=""></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="left-separator">
        <img src="assets/images/bg-pattern-dots.png" alt="seperator" class="img-fluid">
    </div>
</div>
<div class="why-wrapper voyey-logo-raw ptb-10 bg-gry section">
    <div class="container">
        <div class="voyey-logo-col section">
            <div class="row">
                <div class="col-sm-4 col-xs-12 left-conn text-center align-self-center">
                    <img src="assets/images/white-logo.svg" alt="Voyey">
                </div>
                <div class="col-sm-8 col-xs-12 right-conn">
                    <h3>Le logo de Voyey</h3>
                    <p>Le logo de Voyey est disponible en deux couleurs officielles. Le logo blanc et jaune est idéal pour les arrière-plans sombres tandis que celui en gris foncé et jaune servira pour les fonds clairs. Toutefois, des variantes du logo en blanc et en noir existent également. Ne modifiez en aucune façon le logo.</p>
                    <a class="ic-download" href="https://malaka.sfo2.digitaloceanspaces.com/static/kit-media/Voyey-kit-logo.zip">TÉLÉCHARGER LE KIT LOGO</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="resources-multimedia-raw section">
    <div class="container">
        <div class="resources-multimedia-col section">
            <h3>Ressources multimédia</h3>
            <ul>
                <li>
                    <div class="img-box">
                        <img src="assets/images/malik-manuel.jpg" alt="Malik">
                        <h5>TÉLÉCHARGER</h5>
                    </div>
                    <h4>Direction</h4>
                    <a class="ic-download" href="https://malaka.sfo2.digitaloceanspaces.com/static/kit-media/Voyey-kit-logo.zip">TÉLÉCHARGER LE KIT LOGO</a>
                </li>
                <li>
                    <div class="img-box">
                        <img src="assets/images/service-img.jpg" alt="Manual">
                        <h5>TÉLÉCHARGER</h5>
                    </div>
                    <h4>Notre service</h4>
                    <a class="ic-download" href="https://malaka.sfo2.digitaloceanspaces.com/static/kit-media/Voyey-kit-logo.zip">TÉLÉCHARGER DES CAPTURES D’ÉCRANS</a>
                </li>
            </ul>
        </div>
    </div>
</div>