<ngx-json-ld [json]="schema"></ngx-json-ld>
<div class="department-wrapper purchase-raw">
    <div class="department-con section">
        <div class="container">
            <div class="row department-inner">
                <div class="col-md-7">
                    <div class="text-wrap">
                        <h1>Les ultramarins malins utilisent Voyey pour acheter en métropole sans payer la TVA !</h1>
                        <ul class="con-list">
                            <li>Recevez en main propre vos achats détaxés entre 1 et 5 jours. </li>
                            <li>Transport, assurance et dédouanement inclus.
                            </li>
                        </ul>
                        <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="img-wrap">
        <div class="right-sep section">
            <img src="assets/images/Achats-sans-tva.png" class="img-fluid" alt="Des habitants des DOM qui font du shopping en métropole">
        </div>
    </div>
</div>
<div class="link-wrapper purchase-boxes ptb-10">
    <div class="container">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="boxes-wrap">
                    <i><img src="assets/images/commande-Voyey.svg" class="img-fluid" alt="icône symbole monde avec boutique mobile"></i>
                    <h4>Commande</h4>
                    <p>Vous commandez via l’application Voyey en précisant le produit que vous souhaitez acheter. En ligne ou en magasin partout en métropole</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="boxes-wrap">
                    <i><img src="assets/images/commande-achat-detaxe.svg" class="img-fluid" alt="icône chariot contenant colis jaunes"></i>
                    <h4>Achat détaxé</h4>
                    <p>On achète les produits en tant qu’entreprise, et nous vous faisons profiter de l’exonération de TVA dont nous bénéficions. <a href="#">TVA exclue</a></p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="boxes-wrap">
                    <i><img src="assets/images/Livraison-rapide.svg" class="img-fluid" alt="icône d’un colis en mouvement avec une horloge qui affiche 5 jours"></i>
                    <h4>Transport</h4>
                    <p>Une fois les produits reçus, nous les livrons outre-mer entre 1 et 5 jours. Livraison en Guadeloupe, Martinique, Guyane et La Réunion</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="bg-gry ptb-10 trust-main promise-wrap">
    <h2>Les promesses Voyey</h2>
    <ul class="trust-inner">
        <li>
            <div class="trust-box les01">
                <div class="img-wrap">
                    <img src="assets/images/prix-coutants.svg" class="img-fluid" alt="illustration balance avec des billets en Euros">
                </div>
                <div class="text-wrap">
                    <h4>On ne fait pas de marge</h4>
                    <p>Voyey achète vos produits pour vous et vous les cède à prix coûtant et détaxé. En clair, cela veut dire que non seulement on ne fait pas de marge commerciale sur vos achats, mais qu’en plus, nous assurons toutes les démarches douanières
                        et fiscales pour vous exonérer du paiement de la TVA métropolitaine. </p>
                </div>
            </div>
        </li>
        <li>
            <div class="trust-box">
                <div class="img-wrap">
                    <img src="assets/images/sans-couts-caches.svg" class="img-fluid" alt="illustration ticket de caisse avec loupe Euro barré">
                </div>
                <div class="text-wrap">
                    <h4>Il n’y a pas de coûts cachés</h4>
                    <p>Chez Voyey, on est transparent ! Voilà pourquoi, quand vous commandez un produit via notre application, le prix annoncé est celui que vous paierez au final. Et, grâce à notre calculateur révolutionnaire, cela comprend les taxes d’outre-mer
                        applicables. Pas de mauvaise surprise pour vous à l’arrivée, donc. </p>
                </div>
            </div>
        </li>
        <li>
            <div class="trust-box">
                <div class="img-wrap">
                    <img src="assets/images/Livraison-main-propre.svg" class="img-fluid" alt="illustration colis horloge et camion de livraison">
                </div>
                <div class="text-wrap">
                    <h4>On livre en mains propres</h4>
                    <p>Une fois que vos achats ont traversé les océans, un agent Voyey vous contacte pour organiser avec vous la livraison. Adresse, créneau horaire – nous vous livrons là ou vous le souhaitez et quand cela vous arrange. Fini le stress de
                        rater sa livraison ou d’avoir à faire la queue pour récupérer son colis. </p>
                </div>
            </div>
        </li>
    </ul>
</div>
<div class="parcel-wrapper deliver-wrapper ptb-10 text-center">
    <div class="container">
        <div class="text-wrap section">
            <h2>Faites-vous livrer en mains propres, là où vous le souhaitez !</h2>
            <ul>
                <li>
                    <a class="boxes-wrap">
                        <i><img src="assets/images/lieu-livraison.svg" class="img-fluid" alt="icône feuille de livraison et balise de localisation jaune" /></i>
                        <h5>Au lieu de votre choix</h5>
                    </a>
                </li>
                <li>
                    <a class="boxes-wrap">
                        <i><img src="assets/images/creneau-livraison.svg" class="img-fluid" alt="icône horloge avec roue mécanique jaune" /></i>
                        <h5>Au créneau qui vous arrange</h5>
                    </a>
                </li>
                <li>
                    <a class="boxes-wrap">
                        <i><img src="assets/images/Livraison-en-main-propre.svg" class="img-fluid" alt="icône d'un colis dans une mainjours" /></i>
                        <h5>En main propre</h5>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="left-separator">
        <img src="assets/images/deliver-sep.png" alt="seperator" class="img-fluid">
    </div>
</div>
<div class="why-wrapper ptb-10 bg-gry text-center">
    <div class="container">
        <div class="simple-main subscribe-wrap section">
            <p>Vous êtes un adepte de l’e-commerce ?</p>
            <h2>Optez pour l’abonnement Voyey !</h2>
            <ul>
                <li>sans engagement</li>
                <li>livraison sur-mesure gratuite</li>
                <li>prix au kilo réduit</li>
                <li>frais de services minuscules</li>
            </ul>
            <a href="https://app.voyey.com/akaz-subscription" class="btn-main"><span>JE M’ABONNE</span><i></i></a>
        </div>
        <div class="why-list products-list section">
            <h2>Nous achetons vos produits en ligne ou en magasin</h2>
            <ul>
                <li>
                    <i><img src="assets/images/Nous01.svg" alt="Vêtements" class="img-fluid"></i>
                    <h4>Vêtements</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous02.svg" alt="Chaussures" class="img-fluid"></i>
                    <h4>Chaussures</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous03.svg" alt="Produits de beauté" class="img-fluid"></i>
                    <h4>Produits de beauté</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous04.svg" alt="High-Tech" class="img-fluid"></i>
                    <h4>High-Tech</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous05.svg" alt="Matériel informatique" class="img-fluid"></i>
                    <h4>Matériel informatique </h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous06.svg" alt="Electronique / Hifi" class="img-fluid"></i>
                    <h4>Electronique / Hifi</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous07.svg" alt="Maroquinerie" class="img-fluid"></i>
                    <h4>Maroquinerie</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous08.svg" alt="Livres" class="img-fluid"></i>
                    <h4>Livres</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous09.svg" alt="Jeux pour enfants" class="img-fluid"></i>
                    <h4>Jeux pour enfants</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous10.svg" alt="Pièces détachées" class="img-fluid"></i>
                    <h4>Pièces détachées</h4>
                </li>
                <li>
                    <i><img src="assets/images/nous11.svg" alt="Outils de bricolage" class="img-fluid"></i>
                    <h4>Outils de bricolage</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous12.svg" alt="Petit électroménager" class="img-fluid"></i>
                    <h4>Petit électroménager</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous13.svg" alt="Articles de sport" class="img-fluid"></i>
                    <h4>Articles de sport</h4>
                </li>
                <li>
                    <i><img src="assets/images/Nous14.svg" alt="Linge de maison" class="img-fluid"></i>
                    <h4>Linge de maison</h4>
                </li>
            </ul>
            <p>On peut même acheter et livrer des produits hors-format !</p>
            <p>Un doute sur un produit ? <a href="/contact">Contactez-nous !</a></p>
            <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
        </div>
    </div>
</div>
<div class="commitment-wrapper development-wrapper ptb-10">
    <div class="container">
        <div class="row position-relative">
            <div class="col-sm-6 align-self-center">
                <div class="text-wrap">
                    <h2>Vous contribuez au développement de votre département !</h2>
                    <p>En passant par Voyey pour vos achats en métropole, vous faites plus que des économies ; vous contribuez aussi au développement de votre département. En effet, si nous vous exonérons (en toute légalité) de la TVA métropolitaine, en
                        revanche, nous vous faisons payer les taxes d’outre-mer à la consommation. Tout simplement parce que c’est la loi qui l’impose, mais aussi parce que le fruit de ces taxes est intégralement consacré au développement local ! Et,
                        chez Voyey, on trouve ça gagnant-gagnant. </p>
                </div>
            </div>
            <div class="col-sm-6 align-self-center">
                <div class="img-wrap">
                    <img src="assets/images/Voyey-cercle-vertueux.png" alt="Une île des DOM qui reçoit une pluie d’Euros grâce à Voyey" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="domiens-wrap ptb-10 bg-gry text-center">
    <div class="container">
        <h2>Plus de 100 Domiens nous ont déjà fait confiance !</h2>
        <ul>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-1.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-2.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-3.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-4.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-5.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-6.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-7.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-8.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-9.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-10.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-11.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
            <li>
                <div class="img-wrap">
                    <img src="assets/images/domiens-12.jpg" class="img-fluid" alt="Domiens">
                </div>
            </li>
        </ul>
        <p>Achetez vos produits détaxés en métropole avec des frais de livraison imbattables !</p>
        <a href="https://app.voyey.com" class="btn-main"><span>JE COMMENCE</span><i></i></a>
    </div>
</div>