import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, SafeHtml, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  title = 'Voyey | À propos';
  jsonLD: SafeHtml;

  schema = {
    "url": "https://voyey.com/a-propos/",
    "name": "Voyey | À propos",
    "description": "Voyey est un service d’achat détaxé et de réexpédition outre-mer, destiné aux personnes vivant en Guadeloupe, en Martinique, en Guyane et à La Réunion."
  };
  
  constructor(
    private titleService: Title, private metaService: Meta, @Inject(DOCUMENT) private dom
  ) { }

  // Meta tags
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([      
      { name: "description", content: "Voyey est un service d’achat détaxé et de réexpédition outre-mer, destiné aux personnes vivant en Guadeloupe, en Martinique, en Guyane et à La Réunion." },
      { name: "og:url", content: "https://voyey.com/a-propos/" },
      { name: "og:title", content: "Voyey | À propos" },
      { name: "og:description", content: "Voyey est un service d’achat détaxé et de réexpédition outre-mer, destiné aux personnes vivant en Guadeloupe, en Martinique, en Guyane et à La Réunion." },
      { name: "twitter:title", content: "Voyey | À propos" },
      { name: "twitter:description", content: "Voyey est un service d’achat détaxé et de réexpédition outre-mer, destiné aux personnes vivant en Guadeloupe, en Martinique, en Guyane et à La Réunion." }
    ]);
    this.setCanonicalURL('https://voyey.com/a-propos/');
  }

  setCanonicalURL(url?: string) {
    const link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', url);
  }
}