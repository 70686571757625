<div class="legal-wrapper section">
    <div class="legal-head section">
        <div class="container">
            <div class="legal-tab text-center">
                <ul class="nav" id="myTab" role="tablist">
                    <li>
                        <a class="active" id="home-tab" data-toggle="tab" href="#cgvu" role="tab" aria-controls="home" aria-selected="false">
                            <i><img src="assets/images/ic-cgu.svg" alt="CGU" class="img-fluid"></i>
                            <span>Conditions Générales d’Utilisation (CGU),</span>
                        </a>
                    </li>
                    <li>
                        <a id="profile-tab" data-toggle="tab" href="#rgpd" role="tab" aria-controls="profile" aria-selected="false">
                            <i><img src="assets/images/ic-rgpd.svg" alt="RGPD" class="img-fluid"></i>
                            <span>Politique de confidentialité (RGPD)</span>
                        </a>
                    </li>
                    <li>
                        <a id="contact-tab" data-toggle="tab" href="#mentions-legales" role="tab" aria-controls="contact" aria-selected="true">
                            <i><img src="assets/images/ic-mentions-legales.svg" alt="Mentions légales" class="img-fluid"></i>
                            <span>Mentions légales</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="legal-body section">
        <div class="container">
            <div class="tab-content">
                <div class="tab-pane fade active show" id="cgvu" role="tabpanel" aria-labelledby="home-tab">
                    <div class="info-wrap">
                        <h5>INFORMATION</h5>
                        <p>Here there will be a summary more humanly written which help people understand quick and easy the legal terms and words. Here there will be a summary more humanly written which help people understand quick and easy the legal terms
                            and words. Here there will be a summary more humanly written which help people understand quick and easy the legal terms and words</p>
                    </div>
                    <div class="details-wrap">
                        <div class="details-main section">
                            <div class="details-head section">
                                <h2>Conditions générales d’utilisation du service Voyey.com</h2>
                                <h4>Dernière mise à jour : 7 août 2020</h4>
                                <i class="info-tag-col"></i>
                            </div>
                            <p>Bienvenue sur Voyey.com! Nous sommes heureux que vous soyez ici. Veuillez lire attentivement ces conditions d’utilisation avant d’accéder ou d’utiliser Voyey. Parce qu’il s’agit d’un contrat si important entre nous et nos utilisateurs,
                                nous avons essayé de le rendre aussi clair que possible. Pour votre commodité, nous avons présenté ces conditions dans un bref résumé non contraignant suivi des conditions juridiques complètes.</p>
                            <h4>Préambule</h4>
                            <p>Bienvenue sur Voyey.com.<br/> En utilisant le Site, vous acceptez les présentes Conditions Générales d’Utilisation, ainsi que celles accessibles par des liens hypertextes dès lors que celles-ci sont applicables. Les conditions
                                exposées dans ces liens hypertextes font partie intégrante des présentes Conditions Générales d’Utilisation. Ces Conditions Générales d’Utilisation sont conclues avec la Société.<br/> Avant de naviguer sur le site, vous
                                devez impérativement lire et accepter l’ensemble des stipulations des présentes Conditions Générale d’utilisation du site. Si vous n’acceptez pas nos Conditions Générales d’Utilisation, vous ne pourrez pas utiliser le site,
                                services, applications ou outils de Voyey.com. Les Conditions Générales d’Utilisation s’appliquent à tous les utilisateurs du Site.
                            </p>
                            <div class="border-box">
                                <p><b>Il est précisé que les Conditions Générales d’Utilisation sont conformes aux textes suivants:</b></p>
                                <ul class="con-list">
                                    <li> Convention de Varsovie du 12 octobre 1929,</li>
                                    <li>Convention de Montréal du 28 mai 1999,</li>
                                    <li>Règlement (CE) n° 889/2002 du Parlement européen et du Conseil du 13 mai 2002.</li>
                                </ul>
                            </div>
                            <p>De plus, les présentes Conditions Générales d’Utilisation sont complétées par la Politique de confidentialité et de protection des données personnelles également accessible par des liens hypertextes. La Politique de confidentialité
                                et de protection des données personnelles est conforme à la loi n° 78-17 du 06 janvier 1978 relative à l’informatique, aux fichiers et aux libertés (dite « loi informatique et libertés ») et au Règlement (UE) 2016/679 du
                                Parlement européen et du Conseil du 27 avril 2016, relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la Directive
                                95/46/CE (règlement général sur la protection des données), aussi communément appelé « RGPD » ou « le Règlement RGPD ».</p>
                            <div class="description-box" id="definitions">
                                <h4>1. Définitions</h4>
                                <p>« L’Utilisateur » ou « Les Utilisateurs » s’entendent exclusivement de personnes physiques ou morales et voyageant ou souhaitant acheminer ou délivrer des colis dans le sens Métropole vers l’un des départements d’outre-mer
                                    suivants : Guadeloupe, Guyane, Martinique, La Réunion. Dans l’attente de la modification ultérieure des présentes Conditions, les Utilisateurs domiciliés fiscalement dans le département d’outre-mer de Mayotte ne peuvent
                                    pas utiliser le Site.<br/> « Voyageur » <br/></p>
                                <p>« Compte(s) Voyey.com » désigne(nt) le(s) compte(s) d’utilisateurs du Site.<br/> « Conditions » ou « Conditions Générales d’Utilisation » désignent les présentes conditions générales d’utilisation du Site.<br/> « Politique
                                    de Confidentialité » désigne les dispositions relatives à la collecte, au traitement, à la sauvegarde et à l’effacement des données à caractère personnel et à la libre circulation de ces données, qui complètent les
                                    Conditions.
                                    <br/> « Voyey.com » ou la « Société » désignent la société Voyey.com.<br/> « Site » désigne le Site internet de la société Voyey.com dont l’adresse URL est la suivante : www.voyey.com.<br/> « Les DOM » désignent la
                                    Guadeloupe, la Guyane, la Martinique, La Réunion, en ce non compris Mayotte.<br/> « L’Hexagone » ou « La Métropole » désignent le territoire national continental européen de la France, y compris la Corse.</p>
                            </div>
                            <div class="description-box" id="objet">
                                <h4>2. Objet</h4>
                                <p>« L’Utilisateur » ou « Les Utilisateurs » s’entendent exclusivement de personnes physiques ou morales et voyageant ou souhaitant acheminer ou délivrer des colis dans le sens Métropole vers l’un des départements d’outre-mer
                                    suivants : Guadeloupe, Guyane, Martinique, La Réunion. Dans l’attente de la modification ultérieure des présentes Conditions, les Utilisateurs domiciliés fiscalement dans le département d’outre-mer de Mayotte ne peuvent
                                    pas utiliser le Site.<br/> « Voyageur » <br/></p>
                                <p>« Compte(s) Voyey.com » désigne(nt) le(s) compte(s) d’utilisateurs du Site.<br/> « Conditions » ou « Conditions Générales d’Utilisation » désignent les présentes conditions générales d’utilisation du Site.<br/> « Politique
                                    de Confidentialité » désigne les dispositions relatives à la collecte, au traitement, à la sauvegarde et à l’effacement des données à caractère personnel et à la libre circulation de ces données, qui complètent les
                                    Conditions.
                                    <br/> « Voyey.com » ou la « Société » désignent la société Voyey.com.<br/> « Site » désigne le Site internet de la société Voyey.com dont l’adresse URL est la suivante : www.voyey.com.<br/> « Les DOM » désignent la
                                    Guadeloupe, la Guyane, la Martinique, La Réunion, en ce non compris Mayotte.<br/> « L’Hexagone » ou « La Métropole » désignent le territoire national continental européen de la France, y compris la Corse.</p>
                            </div>
                            <div class="bottom-info-col">

                            </div>
                        </div>
                    </div>
                    <div class="onpage-rightbar">
                        <span>SUR CETTE PAGE</span>
                        <h5>Préambule</h5>
                        <ul class="nav">
                            <li><a class="nav-link" href="#definitions">Définitions</a></li>
                            <li><a class="nav-link" href="#objet">Objet</a></li>
                            <li><a class="nav-link" href="#">Utilisation de Voyey.com</a></li>
                            <li><a class="nav-link" href="#">Violation des conditions d’utilisation de Voyey.com</a></li>
                            <li><a class="nav-link" href="#">Contenu et propriété intellectuelle</a></li>
                            <li><a class="nav-link" href="#">Responsabilité</a></li>
                            <li><a class="nav-link" href="#">Articles prohibés</a></li>
                            <li><a class="nav-link" href="#">Limitation de la responsabilité</a></li>
                            <li><a class="nav-link" href="#">Accès au site et atteinte a l’intégrité du site</a></li>
                            <li><a class="nav-link" href="#">Respect de la vie privée</a></li>
                            <li><a class="nav-link" href="#">15. Mentions légales</a></li>
                            <li><a class="nav-link" href="#">17. Indemnités</a></li>
                            <li><a class="nav-link" href="#">18. Notifications</a></li>
                            <li><a class="nav-link" href="#">19. Résolution des litiges et droit applicable</a></li>
                            <li><a class="nav-link" href="#">20. Generalites</a></li>
                            <li><a class="nav-link" href="#">21. Durée et résiliation</a></li>
                            <li><a class="nav-link" href="#">22. Charte de bonne conduite</a></li>
                            <li><a class="nav-link" href="#">23. Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="tab-pane fade" id="rgpd" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="info-wrap">
                        <h5>INFORMATION</h5>
                        <p>Here there will be a summary more humanly written which help people understand quick and easy the legal terms and words. Here there will be a summary more humanly written which help people understand quick and easy the legal terms
                            and words. Here there will be a summary more humanly written which help people understand quick and easy the legal terms and words</p>
                    </div>
                    <div class="details-wrap">
                        <div class="details-main section">
                            <div class="details-head section">
                                <h2>Politique de confidentialité et de protection des données personnelles de VOYEY.COM</h2>
                                <h4>Dernière mise à jour : 7 août 2020</h4>
                                <i class="info-tag-col"></i>
                            </div>
                            <div class="description-box" id="protection">
                                <h4>1. Protection de vos données personnelles</h4>
                                <p>Dans le cadre de l’utilisation de ses services, et notamment des services accessibles sur son site internet à l’adresse https://voyey.com, VOYEY.COM, en sa qualité de responsable de traitement au sens du droit européen,
                                    est susceptible de collecter et traiter des données à caractère personnel vous concernant.</p>
                                <p>En sa qualité de société commerciale de droit français, la Société VOYEY.COM est attachée au respect des règles de protection de la vie privée de ses clients et utilisateurs, des prospects et des visiteurs de son site internet.
                                    L’ensemble des traitements de données personnelles mis en œuvre dans le cadre des services accessibles respecte la réglementation interne et européenne applicable en matière de protection des données personnelles, en
                                    particulier mais non exclusivement les dispositions de la loi n° 78-17 du 06 janvier 1978 relative à l’informatique, aux fichiers et aux libertés (dite « loi informatique et libertés ») et le Règlement (UE) 2016/679
                                    du Parlement européen et du Conseil du 27 avril 2016, relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant
                                    la Directive 95/46/CE (dit « Règlement général sur la protection des données », « RGPD » ou « le Règlement RGPD »).</p>
                                <p>VOYEY.COM déclare et reconnaît expressément être responsable du traitement de vos données personnelles dans le cadre de l’offre de ses services tels que décrits aux Conditions générales d’utilisation https://voyey.com/protection-donnees-rgpd.</p>
                                <p>Afin de veiller à la bonne application de des règles de droit interne et de droit européen susrappelées, VOYEY.COM a désigné un(e) délégué(e) à la protection des données qui est le relais privilégié de la Commission Nationale
                                    de l’Informatique et des Libertés (CNIL).</p>
                            </div>
                        </div>
                    </div>
                    <div class="onpage-rightbar">
                        <span>SUR CETTE PAGE</span>
                        <h5>Préambule</h5>
                        <ul class="nav">
                            <li><a class="nav-link" href="#protection">1. Protection de vos données personnelles</a></li>
                            <li><a class="nav-link" href="#">2. Quels sont les engagements de voyey.com en matière de protection de vos données personnelles ?</a></li>
                            <li><a class="nav-link" href="#">3. À quoi servent les données personnelles collectées par voyey.vom ?</a></li>
                            <li><a class="nav-link" href="#">4. Combien de temps vos données personnelles sont-elles conservées par voyey.com ?</a></li>
                            <li><a class="nav-link" href="#">5. Qui est susceptible d’accéder à vos données personnelles ?</a></li>
                            <li><a class="nav-link" href="#">6. Comment exercer vos droits ?</a></li>
                            <li><a class="nav-link" href="#">7. Politique en matière de cookies/traceurs</a></li>
                            <li><a class="nav-link" href="#">8. Sécurité informatique et sécurisation des transactions</a></li>
                            <li><a class="nav-link" href="#">9. Cas particulier des données personnelles pour les enfants mineurs</a></li>
                            <li><a class="nav-link" href="#">10. Liens url pour l’accès à des sites internet tiers</a></li>
                            <li><a class="nav-link" href="#">11. Contact voyey.com</a></li>
                            <li><a class="nav-link" href="#">12. Modifications</a></li>
                            <li><a class="nav-link" href="#">13. Droit applicable</a></li>
                        </ul>
                    </div>
                </div>
                <div class="tab-pane fade" id="mentions-legales" role="tabpanel" aria-labelledby="contact-tab">
                    <div class="details-wrap">
                        <div class="details-main section">
                            <div class="details-head section">
                                <h2>Mentions légales</h2>
                                <h4>Dernière mise à jour : 7 août 2020</h4>
                            </div>
                            <div class="description-box">
                                <h4>Le Site est un Site développé par la société :</h4>
                                <p><a href="https://voyey.com">VOYEY.COM</a><br/> Société par Actions Simplifiée au capital de 1.000 EUR Siège social : 5, avenue Ingres 75016 Paris Immatriculée au R.C.S. de Paris sous le numéro 851 568 337 représentée par
                                    Manuel DISA en qualité de Président.</p>
                            </div>
                            <div class="description-box">
                                <p><b>Adresse électronique : <a href="mailto:sakay@voyey.com" target="_blank">sakay@voyey.com</a></b></p>
                            </div>
                            <div class="description-box">
                                <p><b>Le Site est hébergé par :</b></p>
                                <p>Digital Ocean <br/>Siège social : 101 Avenue of the Americas. 10th Floor. New-York, NY1C013</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>