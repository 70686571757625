import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { TrustComponent } from './trust/trust.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { RedirectionComponent } from './redirection/redirection.component';
import { TravellerComponent } from './traveller/traveller.component';
import { AboutComponent } from './about/about.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { KitMediaComponent } from './kit-media/kit-media.component';
import { TarifsComponent } from './tarifs/tarifs.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NotFoundComponent } from './not-found/not-found.component';
const routes: Routes = [
  { path: '',
    //redirectTo: '/',
    pathMatch: 'full',
    component: HomeComponent,
  },
 // { path: 'home', component: HomeComponent },
  { path: 'fonctionnement',pathMatch: 'full', component: HowItWorksComponent },
  { path: 'confiance-securite',pathMatch: 'full', component: TrustComponent},
  { path: 'achat-detaxe-dom',pathMatch: 'full', component: PurchaseComponent},
  { path: 'reexpedition-livraison-dom',pathMatch: 'full', component: RedirectionComponent},
  { path: 'voyageurs-dom',pathMatch: 'full', component: TravellerComponent},
  { path: 'a-propos',pathMatch: 'full', component: AboutComponent},
  { path: 'juridique',pathMatch: 'full', component: MentionsLegalesComponent},
  { path: 'kit-media',pathMatch: 'full', component: KitMediaComponent},
  { path: 'tarifs',pathMatch: 'full', component: TarifsComponent},
  { path: 'contact',pathMatch: 'full', component: ContactUsComponent},
  
  {path: 'not-found' , component: NotFoundComponent},
  {path: '**' , redirectTo: '/not-found'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
